import React, {useContext} from 'react'
import {toast} from 'react-toastify'
import {
  useTutorsPartialUpdate,
  useUsersPartialUpdate,
  useUsersPasswordChange,
} from '../../api/fetchers'
import ChangePasswordForm from '../../components/changePasswordForm/ChangePasswordForm'
import EditProfileForm from '../../components/editProfileForm/EditProfileForm'
import EditTutorProfileForm from '../../components/editTutorProfileForm/EditTutorProfileForm'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import SubscriptionsForm from '../../components/subscriptionsForm/SubscriptionsForm'
import {WrapperContext} from '../../context/wrapper/wrapperContext'
import axiosCustom from '../../helpers/axiosCustom'
import handleError from '../../helpers/handleError'
import {useRoute} from '../../hooks/router.hooks'
import {
  ChangePasswordValues,
  EditProfileValues,
  EditTutorPhotoValues,
  EditTutorProfileValues,
  SubscriptionsValues,
} from '../../types/formValues'

interface Props {
  isTutor?: boolean
}

const EditProfile = ({isTutor}: Props) => {
  const {user, setUser, token} = useContext(WrapperContext)
  const {routePath} = useRoute()

  const {
    mutate: updateUser,
    loading: updateUserLoading,
  } = useUsersPartialUpdate({
    id: +user.id!,
  })
  const {
    mutate: updateTutor,
    loading: updateTutorLoading,
  } = useTutorsPartialUpdate({
    id: isTutor && user.tutor_data ? `${user.tutor_data.id}` : '',
  })
  const {
    mutate: changePassword,
    loading: changePasswordLoading,
  } = useUsersPasswordChange({})

  const onEditSubmit = (values: EditProfileValues | SubscriptionsValues) => {
    if (updateUserLoading) return

    updateUser(values)
      .then((res) => {
        setUser({...user, ...res})
        toast('Данные обновлены')
      })
      .catch(() => {
        toast.error('Ошибка сервера')
      })
  }

  const onTutorEditSubmit = (
    values: EditTutorProfileValues | EditTutorPhotoValues,
  ) => {
    if (updateTutorLoading) return

    updateTutor(values)
      .then((res) => {
        const tutor_data = {...user.tutor_data, ...res}
        setUser({...user, tutor_data})
        toast('Данные обновлены')
      })
      .catch(() => {
        toast.error('Ошибка сервера')
      })
  }

  const onPhotoUpload = (file: any) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token.access}`,
      accept: 'application/json',
      cache: 'no-cache',
    }
    const dataForm = new FormData()
    dataForm.append('photo', file)

    axiosCustom
      .post('/tutor_photo/', dataForm, {
        headers,
      })
      .then(({data}) => {
        onTutorEditSubmit({photo_img: data.id})
      })
      .catch(() => {
        toast.error('Ошибка сервера')
      })
  }

  const onPasswordChangeSubmit = (values: ChangePasswordValues) => {
    if (changePasswordLoading) return

    changePassword(values)
      .then(() => {
        toast('Пароль изменён')
      })
      .catch((error) => {
        handleError(error)
      })
  }

  const onReset = () => {
    routePath('/profile')
  }

  const hasTutorPhoto = user.tutor_data?.photo_img?.photo

  return (
    <ProfileWrapper>
      <ProfileTabs isTutor={isTutor} />
      <div className="edit-profile">
        <h5 className="edit-profile__title">Редактировать профиль</h5>
        <p className="edit-profile__form-title">
          {isTutor
            ? `${user.tutor_data?.first_name} ${user.tutor_data?.last_name}`
            : 'Родители'}
        </p>
        {isTutor && (
          <div className="edit-profile__img-handler">
            {hasTutorPhoto && (
              <img
                src={user.tutor_data?.photo_img?.photo}
                alt=""
                className="edit-profile__img"
              />
            )}
            <label>
              <input
                type="file"
                onChange={(event) => {
                  const file = event.currentTarget.files![0]
                  if (file.size > 10000000) {
                    toast.error('Файл слишком большой (больше 10 МБ)')
                    return
                  }
                  onPhotoUpload(file)
                }}
                className="form__file-input"
              />
              <p className="form__file-label">
                {hasTutorPhoto ? 'Загрузить другое фото' : 'Загрузить фото'}
              </p>
            </label>
          </div>
        )}
        {isTutor ? (
          <EditTutorProfileForm
            onSubmit={onTutorEditSubmit}
            onReset={onReset}
            loading={updateTutorLoading}
          />
        ) : (
          <EditProfileForm
            onSubmit={onEditSubmit}
            onReset={onReset}
            loading={updateUserLoading}
          />
        )}
        <p className="edit-profile__form-title">Сменить пароль</p>
        <ChangePasswordForm
          onSubmit={onPasswordChangeSubmit}
          onReset={onReset}
          loading={changePasswordLoading}
        />
        <p className="edit-profile__form-title">Управление уведомлениями</p>
        <SubscriptionsForm
          onSubmit={onEditSubmit}
          onReset={onReset}
          loading={updateUserLoading}
        />
      </div>
    </ProfileWrapper>
  )
}

export default EditProfile
