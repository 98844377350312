import React from 'react'
import mailIcon from '../../images/footer/mail-icon.svg'
import phoneIcon from '../../images/footer/phone-icon.svg'
import polyLogoFull from '../../images/footer/poly-logo-full.svg'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-contacts">
          <a
            className="footer-contacts__poly-link"
            href="https://polytech.one/ru"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className="footer-contacts__poly-logo"
              src={polyLogoFull}
              alt="poly-logo"
            />
          </a>
          <div className="footer-contacts__info">
            <p className="footer-contacts__text">По всем вопросам</p>
            <a
              href="mailto:ydetei@polytech.one"
              className="footer-contacts__link">
              <div className="footer-contacts__icon">
                <img src={mailIcon} alt="main-icon" />
              </div>
              ydetei@polytech.one
            </a>
            <a href="tel:+79160081232" className="footer-contacts__link">
              <div className="footer-contacts__icon">
                <img src={phoneIcon} alt="phone-icon" />
              </div>
                +7 916 008-12-32
            </a>
            <div className="footer-contacts__social">
              <a
                href="https://vk.com/ydetei"
                target="_blank"
                className="footer-contacts__social-item">
                <div className="footer-contacts__icon footer-contacts__icon_social">
                  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.9948 18.2264C37.1889 17.6163 36.9948 17.1726 36.1351 17.1726H33.2789C32.5579 17.1726 32.2252 17.5609 32.0311 17.9768C32.0311 17.9768 30.5614 21.5263 28.5093 23.8279C27.8438 24.4934 27.5388 24.7153 27.1783 24.7153C26.9842 24.7153 26.7346 24.4934 26.7346 23.8833V18.1986C26.7346 17.4777 26.5127 17.1449 25.9027 17.1449H21.4104C20.9667 17.1449 20.6894 17.4777 20.6894 17.8104C20.6894 18.5037 21.7154 18.6701 21.8263 20.6112V24.8262C21.8263 25.7413 21.66 25.9077 21.2995 25.9077C20.3289 25.9077 17.9718 22.3305 16.5576 18.2541C16.2803 17.4499 16.003 17.1449 15.282 17.1449H12.398C11.5661 17.1449 11.4275 17.5331 11.4275 17.9491C11.4275 18.6978 12.398 22.4691 15.9475 27.4605C18.3046 30.8714 21.66 32.7016 24.6825 32.7016C26.5127 32.7016 26.7346 32.2856 26.7346 31.5924V29.0134C26.7346 28.1815 26.901 28.0429 27.4833 28.0429C27.8993 28.0429 28.648 28.2647 30.3395 29.9008C32.2806 31.8419 32.6134 32.7293 33.6949 32.7293H36.5511C37.383 32.7293 37.7712 32.3133 37.5494 31.5092C37.2998 30.705 36.357 29.5403 35.1368 28.1538C34.4713 27.3774 33.473 26.5177 33.168 26.1018C32.7521 25.5472 32.863 25.3253 33.168 24.8262C33.1403 24.8262 36.6343 19.8902 36.9948 18.2264Z" fill="#ffffff"/>
                    <rect x="1.51929" y="1.46143" width="46" height="46" rx="23" stroke="#ffffff" stroke-width="2"/>
                  </svg>
                </div>
              </a>
              <a
                href="https://t.me/ydetei_polytech"
                target="_blank"
                className="footer-contacts__social-item">
                <div className="footer-contacts__icon footer-contacts__icon_social">
                  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.93523 25.4837C11.7542 23.9432 14.901 22.6574 17.8412 21.3651C22.8995 19.2483 27.9779 17.1683 33.1075 15.2318C34.1055 14.9018 35.8988 14.5792 36.0746 16.0465C35.9783 18.1236 35.5822 20.1885 35.3105 22.2534C34.621 26.7943 33.824 31.3196 33.0468 35.8456C32.779 37.3532 30.8754 38.1336 29.6574 37.1688C26.7303 35.2072 23.7807 33.2647 20.8909 31.2576C19.9443 30.3033 20.8221 28.9329 21.6675 28.2515C24.0784 25.8943 26.6352 23.8916 28.92 21.4126C29.5364 19.936 27.7153 21.1804 27.1146 21.5618C23.8141 23.8183 20.5944 26.2125 17.1146 28.1957C15.3371 29.1664 13.2655 28.3368 11.4888 27.7952C9.89586 27.1408 7.56153 26.4816 8.93507 25.4839L8.93523 25.4837Z" fill="#ffffff"/>
                    <rect x="1.51929" y="1.46143" width="46" height="46" rx="23" stroke="#ffffff" stroke-width="2"/>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-legal-info">
          <a
            href="/agreement.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-legal-info__link">
            Соглашение с пользователем
          </a>
          <a
            href="https://polymus.ru/pages/documents"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-legal-info__link">
            Официальные документы
          </a>
          <p className="footer-legal-info__text">
            © Все права защищены, Политехнический музей, 2024
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
