const getLessonState = (value: string) => {
  switch (value) {
    case 'assigned':
      return 'Вы записаны'
    case 'not_assigned':
      return 'Записаться'
    case 'passed':
      return 'Занятие прошло'
    case 'need_purchase':
      return 'Купить блок'
    case 'not_available':
      return 'Недоступно'
    default:
      return ''
  }
}

export default getLessonState
