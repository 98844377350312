import {createContext, Dispatch} from 'react'
import {AuthEduTokenCreateResponse} from '../../api/fetchers'
import {Action, IStateReducer} from './wrapperReducer'

interface IState extends IStateReducer {
  ready: boolean
  error: boolean
  setToken: Function
  setUser: Function
  handleSubscribePopap: Function
  dispatch: Dispatch<Action>
}

export const initialState = {
  token: {
    access: '',
    refresh: '',
  },
  user: {},
  showSubscribeModal: false,
  ready: false,
  error: false,
  dispatch: () => {},
  setToken: () => {},
  handleSubscribePopap: () => {},
  setUser: () => {},
}

if (localStorage.getItem('userData')) {
  const {
    token,
  }: {
    token: AuthEduTokenCreateResponse
  } = JSON.parse(localStorage.getItem('userData')!)
  initialState.token = token
}

export const WrapperContext = createContext<IState>(initialState)
