import {Field, Form, Formik} from 'formik'
import React, {useContext} from 'react'
import {SubscriptionsFields} from '../../contentData/formFieldsData'
import {WrapperContext} from '../../context/wrapper/wrapperContext'
import checkIcon from '../../images/check.svg'
import {ReactComponent as Loader} from '../../images/loader.svg'
import {SubscriptionsValues} from '../../types/formValues'

interface Props {
  onSubmit: (values: SubscriptionsValues) => void
  onReset: () => void
  loading: boolean
}

const SubscriptionsForm = ({onSubmit, onReset, loading}: Props) => {
  const {user} = useContext(WrapperContext)

  return (
    <Formik
      initialValues={{
        notify_edu_announcement: user.notify_edu_announcement || false,
        nofity_edu_lesson: user.nofity_edu_lesson || false,
      }}
      onSubmit={onSubmit}>
      <Form className="form">
        {SubscriptionsFields.map((field) => (
          <label key={field.name} className="form__input-wrapper">
            <Field
              className="form__checkbox"
              type="checkbox"
              name={field.name}
            />
            <div className="form__checkbox-custom">
              <img
                src={checkIcon}
                alt="check"
                className="form__checkbox-icon"
              />
            </div>
            <p className="form__checkbox-text">{field.label}</p>
          </label>
        ))}
        <div className="edit-profile__buttons">
          <button
            type="submit"
            className="edit-profile__btn edit-profile__btn--submit">
            {loading ? <Loader /> : 'Сохранить'}
          </button>
          <button
            onClick={onReset}
            type="button"
            className="edit-profile__btn edit-profile__btn--reset">
            Отмена
          </button>
        </div>
      </Form>
    </Formik>
  )
}

export default SubscriptionsForm
