import React, {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {useTutorsSchedule} from '../../api/fetchers'
import ForbiddenMessage from '../../components/forbiddenMessage/ForbiddenMessage'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import formatDate from '../../helpers/formatDate'

const TutorSchedule = () => {
  const [isForbidden, setIsForbidden] = useState(false)
  const {data: scheduleData, error, loading} = useTutorsSchedule({})

  useEffect(() => {
    if (error && error.status === 403) {
      setIsForbidden(true)
    }
  }, [error])

  if (loading) {
    return null
  }

  if (isForbidden) {
    return <ForbiddenMessage />
  }

  return (
    <ProfileWrapper>
      <ProfileTabs isTutor />
      {scheduleData && scheduleData.length > 0 ? (
        <div className="tutor-schedule">
          {scheduleData.map((day, index) => (
            <div key={`day_${index}`} className="tutor-schedule-day">
              <div className="tutor-schedule-day__date">
                {formatDate(day.day, 'day', true)}
              </div>
              <div className="tutor-schedule-day__slots">
                {day.slots.map((day_slot) => (
                  <NavLink
                    to={`/profile/tutor-lesson/${day_slot.id}`}
                    key={day_slot.id}
                    className="tutor-schedule-day__slot">
                    <p className="tutor-schedule-day__slot-time">
                      {formatDate(day_slot.start_at, 'time', true)}
                    </p>
                    <p className="tutor-schedule-day__slot-title">
                      {day_slot.title}
                    </p>
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <h4 className="tutor-schedule__placeholder">
          Нет запланированных занятий
        </h4>
      )}
    </ProfileWrapper>
  )
}

export default TutorSchedule
