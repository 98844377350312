import {Field, Form, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {ResetValues} from '../../types/formValues'
import LinkButton from '../linkButton/LinkButton'

interface Props {
  onSubmit: (values: ResetValues) => void
  loading: boolean
}

const LoginForm = ({onSubmit, loading}: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Введите e-mail')
      .email('Введите валидный e-mail'),
  })

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({errors, touched}) => (
        <Form className="form">
          <div className="form__input-wrapper">
            <span className="form__asterisk">*</span>
            <Field
              className={`form__input ${
                errors.email && touched.email ? 'form__input--error' : ''
              }`}
              id="email"
              name="email"
              type="email"
              placeholder="E-mail"
            />
            {errors.email && touched.email && (
              <span className="form__error">{errors.email}</span>
            )}
          </div>
          <LinkButton isSubmit isBtn content="Восстановить" loading={loading} />
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
