const questionsData = [
  {
    id: 1,
    question:
      'Когда проходят занятия Университета детей?',
    answer:
      'Два раза в год: в осеннем и весеннем семестрах. Занятия направлений «Движение», «Импульс», «Вектор» проходят по воскресеньям в 11:00, 13:00, 15:00. Продолжительность занятий: до 45 минут для направления «Движение», до 60 минут для направлений «Импульс» и «Вектор».'
  },
  {
    id: 2,
    question: 'Как после покупки абонемента записаться на занятия?',
    answer:
      'Вы покупаете абонемент на фиксированный временной слот и посещаете занятие в определённое время. В вашем абонементе указаны все даты, время, адреса проведения занятий, на которые вы зарегистрированы. Вам остаётся только прийти на занятия, не забыв свой электронный билет со штрих-кодом! Если вы уже посещали занятия Университета детей в прошлых семестрах — в этом семестре вам не нужно записываться через личный кабинет. ',
  },
  {
    id: 3,
    question: 'Где будут проходить занятия?',
    answer:
      'Адреса площадок указаны в ваших билетах. Также более подробная информация будет отправлена вам накануне занятий на электронную почту, которую вы указали при покупке абонемента. Если за два дня до проведения занятия вам на почту не придёт письмо с билетом или рассылкой о локации, напишите нам об этом по адресу: <a href="mailto:ydetei@polytech.one">ydetei@polytech.one</a>.',
  },
  {
    id: 4,
    question: 'Будет ли организован трансфер к местам занятий?',
    answer:
      'Нет, к местам проведения занятий нужно приехать самостоятельно.',
  },
  {
    id: 5,
    question: 'Могут ли родители участвовать в занятиях?',
    answer:
      'Нам важно предоставить ребёнку возможность думать и действовать самостоятельно и учиться работать в команде со сверстниками, поэтому присутствие родителей во время занятий не требуется.',
  },
  {
    id: 6,
    question: 'Можно ли посетить оба курса направления “Движение” в течение одного семестра?',
    answer:
      'Да, вы можете приобрести абонементы на курсы “Исследователи воздушной среды” и “Исследователи недр Земли” и посетить занятия обеих программ. Будьте внимательны при покупке абонементов, особенно при выборе дат и времени начала занятий. Все занятия этих курсов пройдут на одной площадке, поэтому вы можете купить соседние по времени слоты, например, в 11:00 на один курс и в 13:00 на другой.'
  },
  {
    id: 7,
    question: 'Я хочу записать ребёнка в более старшее/младшее направление. Так можно?',
    answer:
      'Программа каждого направления составлена с учётом когнитивных особенностей и интересов детей того или иного возраста. Программа более старшего направления может оказаться сложной для ребёнка, а более младшего — слишком простой. Мы рекомендуем выбирать направление, соответствующее возрасту ребёнка.',
  },
  {
    id: 8,
    question: 'Можно ли записаться на одно или два занятия?',
    answer:
      'Вы можете приобрести абонемент только на весь семестр — 5 занятий. Но периодически Университет детей проводит открытые лекции, к которым могут присоединиться все желающие. Следите за нашими анонсами и новостями в социальных сетях. ',
  },
  {
    id: 9,
    question: 'Можно ли вернуть абонемент?',
    answer:
      'Вернуть абонемент можно в соответствии с договором оферты. ',
  },
  {
    id: 10,
    question: 'Хочу волонтёрить в Университете детей. Как я могу записаться?',
    answer:
      'Если вам уже есть 14 лет, вы можете стать волонтёром Политехнического музея и помогать на занятиях Университета детей. Подробную информацию вы можете найти по  <a href="https://polymus.ru/visitors/volunteers">ссылке.</a>',
  },
]

export default questionsData
