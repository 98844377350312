import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss'
import App from './App';
import {WrapperState} from './context/wrapper/WrapperState'

ReactDOM.render(
  <WrapperState>
    <App />
  </WrapperState>,
  document.getElementById('root')
);
