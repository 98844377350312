import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import animalIcon from '../../../images/screen-four/3.svg'
import animationIcon from '../../../images/screen-four/2.svg'
import finIcon from '../../../images/screen-four/4.svg'
import genIcon from '../../../images/screen-four/5.svg'
import spaceIcon from '../../../images/screen-four/6.svg'
import weatherIcon from '../../../images/screen-four/1.svg'

const ScreenFour = () => {
  return (
    <ScrollableAnchor id={'discussion'}>
      <section className="screen-four">
        <div className="container container--four">
          <h2 className="screen-four-title">
            Что обсудим в новом семестре?
          </h2>
          <div className="screen-four-info">
            <div className="screen-four-info__col">
              <div className="screen-four-info-item">
                <div className="screen-four-info-item__img-wrap">
                  <img
                    className="screen-four-info-item__img"
                    src={animalIcon}
                    alt="person icon"
                  />
                </div>
                <p className="screen-four-info-item__text">
                  Что такое отрицательное число и откуда берутся числа со знаком минус?
                </p>
              </div>
              <div className="screen-four-info-item">
                <div className="screen-four-info-item__img-wrap">
                  <img
                    className="screen-four-info-item__img"
                    src={finIcon}
                    alt="phone icon"
                  />
                </div>
                <p className="screen-four-info-item__text">
                  Как организм человека реагирует на экстремальные условия и как он к ним адаптируется?
                </p>
              </div>
              <div className="screen-four-info-item">
                <div className="screen-four-info-item__img-wrap">
                  <img
                    className="screen-four-info-item__img"
                    src={spaceIcon}
                    alt="computer icon"
                  />
                </div>
                <p className="screen-four-info-item__text">
                  Что будет, если попытаться зажечь свечку в космосе?
                </p>
              </div>
            </div>
            <div className="screen-four-info__col">
              <div className="screen-four-info-item">
                <div className="screen-four-info-item__img-wrap">
                  <img
                    className="screen-four-info-item__img"
                    src={weatherIcon}
                    alt="robot icon"
                  />
                </div>
                <p className="screen-four-info-item__text">
                  Из чего состоят и какими свойствами обладают природные и искусственные камни?
                </p>
              </div>
              <div className="screen-four-info-item">
                <div className="screen-four-info-item__img-wrap">
                  <img
                    className="screen-four-info-item__img"
                    src={animationIcon}
                    alt="robot icon"
                  />
                </div>
                <p className="screen-four-info-item__text">
                  Что происходит, когда мы мылим руки, и зачем мылу нужна вода?
                </p>
              </div>
              <div className="screen-four-info-item">
                <div className="screen-four-info-item__img-wrap">
                  <img
                    className="screen-four-info-item__img"
                    src={genIcon}
                    alt="robot icon"
                  />
                </div>
                <p className="screen-four-info-item__text">
                  Как люди придумывают новые вещи и делают их удобными?
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  )
}

export default ScreenFour
