import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import ageIcon from '../../../images/screen-six/age-icon.svg'
import chatIcon from '../../../images/screen-six/chat-icon.svg'
import globeIcon from '../../../images/screen-six/globe-icon.svg'
import icon from '../../../images/screen-six/icon.svg'

const ScreenSix = () => {
  return (
    <ScrollableAnchor id={'whatsnew'}>
      <section className="screen-six">
        <div className="container container--six">
          <h2 className="screen-six-title">
            Что нового
            <br />в Университете детей?
          </h2>
          <div className="screen-six-info">
            <div className="screen-six-info-item">
              <img src={ageIcon} alt="courses icon" />
              <p className="screen-six-info-item__text">
                Направление “Движение” расширено и разделено на два параллельных курса: “Исследователи воздушной среды” и “Исследователи недр Земли”.
              </p>
            </div>
            <div className="screen-six-info-item">
              <img src={icon} alt="globe icon" />
              <p className="screen-six-info-item__text">
                Новая структура старших направлений: теперь направление “Импульс” ждёт на своих занятиях студентов 7-9 лет, а направление “Вектор” – студентов 10-12 лет.
              </p>
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  )
}

export default ScreenSix
