import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {AssignedUser, useTutorsSlot} from '../../api/fetchers'
import ForbiddenMessage from '../../components/forbiddenMessage/ForbiddenMessage'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import formatDate from '../../helpers/formatDate'
import {useRoute} from '../../hooks/router.hooks'

const TutorLesson = () => {
  const [isForbidden, setIsForbidden] = useState(false)
  const [isNotFound, setNotFound] = useState(false)

  const {lessonId} = useParams()
  const {routePath} = useRoute()

  const {data: lessonData, error} = useTutorsSlot({slot_pk: `${lessonId}`})

  useEffect(() => {
    if (error && error.status === 403) {
      setIsForbidden(true)
    }

    if (error && error.status === 404) {
      setNotFound(true)
    }
  }, [error])

  const renderPupil = (pupil: AssignedUser) => {
    if (pupil.first_name && pupil.last_name) {
      return (
        <p key={pupil.id} className="tutor-lesson__pupil-name">
          {pupil.last_name} {pupil.first_name} {pupil.patronymic || ''}
        </p>
      )
    }
    return null
  }

  if (isNotFound) {
    routePath('/not-found')
  }

  if (isForbidden) {
    return <ForbiddenMessage />
  }

  if (lessonData) {
    return (
      <ProfileWrapper>
        <ProfileTabs isTutor />
        <div className="tutor-lesson">
          <div className="tutor-lesson__content">
            <div className="tutor-lesson__desc">
              {lessonData.lesson_data?.track_logo && (
                <img
                  src={lessonData.lesson_data?.track_logo}
                  alt=""
                  className="tutor-lesson__logo"
                />
              )}
              <p className="tutor-lesson__block-title">
                {lessonData.lesson_data?.block_title}
              </p>
              <p className="tutor-lesson__date">
                {formatDate(lessonData.start_at, 'full', true)}
              </p>
            </div>
            <h2 className="tutor-lesson__title">
              {lessonData.lesson_data?.title}
            </h2>
          </div>
          {lessonData.assignments && lessonData.assignments.length > 0 && (
            <div className="tutor-lesson__pupils">
              <h4 className="tutor-lesson__pupils-title">
                Записаны на занятие:
              </h4>
              <p className="tutor-lesson__pupils-amount">
                (общее количество студентов: {lessonData.assignments.length})
              </p>
              {lessonData.assignments.map((pupil) => renderPupil(pupil))}
            </div>
          )}
        </div>
      </ProfileWrapper>
    )
  }

  return null
}

export default TutorLesson
