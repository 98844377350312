import {toast} from 'react-toastify'

const handleError = (error: any) => {
  const errors = Object.values(error.data).flat()
  if (errors.length > 0) {
    return errors.forEach((error) => toast.error(error))
  }
  return toast.error('Ошибка сервера')
}

export default handleError
