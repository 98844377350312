import {Field, Form, Formik} from 'formik'
import React, {useContext, useState} from 'react'
import {toast} from 'react-toastify'
import {WrapperContext} from '../../context/wrapper/wrapperContext'
import axiosCustom from '../../helpers/axiosCustom'
import {QuestionValues} from '../../types/formValues'
import LinkButton from '../linkButton/LinkButton'
import Modal from '../modal/Modal'

interface Props {
  onClose: () => void
  lesson: number
}

const QuestionModal = ({onClose, lesson}: Props) => {
  const [fileName, setFileName] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const {token} = useContext(WrapperContext)

  const onSubmit = (values: QuestionValues) => {
    if (loading) return

    setLoading(true)
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token.access}`,
      accept: 'application/json',
      cache: 'no-cache',
    }
    const dataForm = new FormData()
    dataForm.append('attachment', values.attachment)
    dataForm.append('lesson', `${lesson}`)
    dataForm.append('content', values.content)

    axiosCustom
      .post('/edu_questions/', dataForm, {
        headers,
      })
      .then(() => {
        setLoading(false)
        setSuccess(true)
      })
      .catch(() => {
        setLoading(false)
        toast.error('Ошибка сервера')
      })
  }

  const renderForm = () => (
    <div className="question-modal-form">
      <h4 className="question-modal-title">Задать вопрос учёному</h4>
      <Formik
        initialValues={{
          attachment: '',
          content: '',
        }}
        onSubmit={onSubmit}>
        {({setFieldValue, values}) => (
          <Form className="form">
            <div className="form__input-wrapper">
              <span className="form__asterisk">*</span>
              <Field
                id="content"
                name="content"
                component="textarea"
                placeholder="Текст вопроса"
                className="form__input form__input--textarea"
              />
            </div>
            <div className="form__file-wrapper">
              {!values.attachment && (
                <label>
                  <input
                    id="attachment"
                    name="attachment"
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files![0]
                      if (file.size > 10000000) {
                        toast.error('Файл слишком большой (больше 10 МБ)')
                        return
                      }
                      setFieldValue('attachment', file)
                      setFileName(file.name)
                    }}
                    className="form__file-input"
                  />
                  <p className="form__file-label">Прикрепить файл</p>
                  <p className="form__file-label-warn">
                    Максимальный размер файла - 10 МБ.
                  </p>
                </label>
              )}
              {values.attachment && (
                <p className="form__file-name">{fileName}</p>
              )}
              {values.attachment && (
                <button
                  type="button"
                  className="form__file-remove"
                  onClick={() => {
                    setFieldValue('attachment', '')
                    setFileName('')
                  }}>
                  Удалить файл
                </button>
              )}
            </div>
            <LinkButton
              disabled={!values.content}
              isSubmit
              isBtn
              content="Сохранить"
              loading={loading}
            />
          </Form>
        )}
      </Formik>
    </div>
  )

  const renderSuccess = () => (
    <div className="question-modal-success">
      <h4 className="question-modal-title">Спасибо!</h4>
      <p className="question-modal-success__text">
        Преподаватель получит ваш вопрос.
      </p>
      <LinkButton onClick={onClose} isBtn content="Закрыть" />
    </div>
  )

  return (
    <Modal onClose={onClose}>{success ? renderSuccess() : renderForm()}</Modal>
  )
}
export default QuestionModal
