import React from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import LinkButton from '../../components/linkButton/LinkButton'

interface Props {
  isNotFound?: boolean
}

const Error = ({isNotFound}: Props) => {
  return (
    <div className="error">
      <Header />
      <div className="error__content">
        <h1 className="error__title">{isNotFound ? '404' : '500'}</h1>
        <p className="error__text">
          {isNotFound
            ? 'К сожалению, по вашему запросу ничего не найдено.'
            : 'Произошла непредвиденная ошибка. Попробуйте зайти позже.'}
        </p>
        {isNotFound && <LinkButton content="На главную" href="/" />}
      </div>
      <Footer />
    </div>
  )
}

export default Error
