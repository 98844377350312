import {Field, Form, Formik} from 'formik'
import React, {useContext} from 'react'
import {EditTutorProfileFields} from '../../contentData/formFieldsData'
import {WrapperContext} from '../../context/wrapper/wrapperContext'
import {ReactComponent as Loader} from '../../images/loader.svg'
import {EditTutorProfileValues} from '../../types/formValues'

interface Props {
  onSubmit: (values: EditTutorProfileValues) => void
  onReset: () => void
  loading: boolean
}

const EditTutorProfileForm = ({onSubmit, onReset, loading}: Props) => {
  const {user} = useContext(WrapperContext)

  return (
    <Formik
      initialValues={{
        status_in: user.tutor_data?.status_in || '',
        status_out: user.tutor_data?.status_out || '',
        phone: user.tutor_data?.phone || '',
        facebook: user.tutor_data?.facebook || '',
        telegram: user.tutor_data?.telegram || '',
      }}
      onSubmit={onSubmit}>
      <Form className="form">
        {EditTutorProfileFields.map((field) => (
          <div key={field.name} className="form__input-wrapper">
            <Field
              className="form__input"
              id={field.name}
              name={field.name}
              placeholder={field.placeholder}
              type={field.type ? 'type' : 'text'}
            />
          </div>
        ))}
        <div className="edit-profile__buttons">
          <button
            type="submit"
            className="edit-profile__btn edit-profile__btn--submit">
            {loading ? <Loader /> : 'Сохранить'}
          </button>
          <button
            onClick={onReset}
            type="button"
            className="edit-profile__btn edit-profile__btn--reset">
            Отмена
          </button>
        </div>
      </Form>
    </Formik>
  )
}

export default EditTutorProfileForm
