import React, {MouseEvent, useState} from 'react'
import {isMobileOnly} from 'react-device-detect'
import ScrollableAnchor from 'react-scrollable-anchor'
import childImg from '../../../images/screen-five/child.png'
import navArrow from '../../../images/screen-five/nav-arrow.svg'
import getSliderData from './sliderData'

const SLIDER_ITEM_WIDTH = 377
const SLIDER_ITEM_MARGIN = 30
const BUBBLE_TOP_MARGIN = isMobileOnly ? 10 : 30
const BUBBLE_WIDTH = 400
const INITIAL_SLIDES_COUNT = 3

const ScreenFive = () => {
  const [leftPosition, setLeftPosition] = useState(0)
  const [slideIndex, setSlideIndex] = useState(INITIAL_SLIDES_COUNT)
  const [bubbleContent, setBubbleContent] = useState('')
  const [showBubble, setShowBubble] = useState(false)
  const [bubbleYPosition, setBubbleYPosition] = useState(0)
  const [bubbleXPosition, setBubbleXPosition] = useState(0)

  const slideRight = () => {
    if (slideIndex === getSliderData().length) {
      return
    }

    setLeftPosition(leftPosition - SLIDER_ITEM_WIDTH - SLIDER_ITEM_MARGIN)
    setSlideIndex(slideIndex + 1)
  }

  const slideLeft = () => {
    if (leftPosition === 0) {
      return
    }

    setLeftPosition(leftPosition + SLIDER_ITEM_WIDTH + SLIDER_ITEM_MARGIN)
    setSlideIndex(slideIndex - 1)
  }

  const onTextHover = (e: MouseEvent, text: string) => {
    setBubbleContent(text)
    setShowBubble(true)
    setBubbleYPosition(window.scrollY + e.clientY + BUBBLE_TOP_MARGIN)
    setBubbleXPosition(e.clientX - BUBBLE_WIDTH / 2)
  }

  const onTextBlur = () => {
    setShowBubble(false)
    setTimeout(() => {
      setBubbleYPosition(0)
    }, 400)
  }

  return (
    <section className="container container--five">
      <div
        style={{
          top: bubbleYPosition,
          left: isMobileOnly ? '' : bubbleXPosition,
          opacity: showBubble ? 1 : 0,
        }}
        className="screen-five-bubble">
        {bubbleContent}
      </div>
      <h4 className="screen-five-slider-title">В прошлом семестре:</h4>
      <div className="screen-five-slider">
        <div
          style={{left: leftPosition}}
          className="screen-five-slider__content">
          {getSliderData(onTextHover, onTextBlur).map((person, index) => (
            <div key={`item_${index}`} className="screen-five-slider-item">
              <img
                className="screen-five-slider-item__img"
                src={person.img}
                alt="person"
              />
              {person.text}
            </div>
          ))}
        </div>
      </div>
      {/* <div className="screen-five-slider-controls">
        <button
          onClick={slideLeft}
          className="screen-five-slider-controls__button">
          <img src={navArrow} alt="arrow left" />
        </button>
        <button
          onClick={slideRight}
          className="screen-five-slider-controls__button">
          <img src={navArrow} alt="arrow right" />
        </button>
      </div> */}
      <ScrollableAnchor id={'teachers'}>
        <div className="screen-five-info">
          <img src={childImg} alt="child" />
          <div className="screen-five-info__content">
            <h2 className="screen-five-info__title">
              Какой опыт получает ребёнок?
            </h2>
            <p className="screen-five-info__desc">
              Ребенок познакомится с современными научными концепциями в живом
              диалоге с учёными, опробует научный метод познания мира,
              разберётся в своих интересах и возможностях. <br />А ещё —
              научится принимать решения, станет самостоятельнее и увереннее в
              себе.
            </p>
          </div>
        </div>
      </ScrollableAnchor>
    </section>
  )
}

export default ScreenFive
