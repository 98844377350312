import {Field, Form, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {ChangePasswordFields} from '../../contentData/formFieldsData'
import {ReactComponent as Loader} from '../../images/loader.svg'
import {ChangePasswordValues} from '../../types/formValues'

interface Props {
  onSubmit: (values: ChangePasswordValues) => void
  onReset: () => void
  loading: boolean
}

const ChangePasswordForm = ({onSubmit, onReset, loading}: Props) => {
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Введите старый пароль'),
    new_password1: Yup.string()
      .required('Введите новый пароль')
      .min(8, 'Минимум 8 символов')
      .matches(/(?!^\d+$)^.+$/, 'Пароль не должен состоять только из цифр'),
    new_password2: Yup.string()
      .required('Повторите новый пароль')
      .oneOf([Yup.ref('new_password1')], 'Пароли не сопадают'),
  })

  return (
    <Formik
      initialValues={{
        old_password: '',
        new_password1: '',
        new_password2: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({errors, touched}) => (
        <Form className="form">
          {ChangePasswordFields.map((field) => (
            <div key={field.name} className="form__input-wrapper">
              <span className="form__asterisk">*</span>
              <Field
                className={`form__input ${
                  errors[field.name as keyof ChangePasswordValues] &&
                  touched[field.name as keyof ChangePasswordValues]
                    ? 'form__input--error'
                    : ''
                }`}
                id={field.name}
                name={field.name}
                type="password"
                placeholder={field.placeholder}
              />
              {errors[field.name as keyof ChangePasswordValues] &&
                touched[field.name as keyof ChangePasswordValues] && (
                  <span className="form__error">
                    {errors[field.name as keyof ChangePasswordValues]}
                  </span>
                )}
            </div>
          ))}
          <div className="edit-profile__buttons">
            <button
              type="submit"
              className="edit-profile__btn edit-profile__btn--submit">
              {loading ? <Loader /> : 'Сохранить'}
            </button>
            <button
              onClick={onReset}
              type="button"
              className="edit-profile__btn edit-profile__btn--reset">
              Отмена
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
