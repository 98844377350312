import React from 'react'
import ReactGA from 'react-ga'
import {BrowserRouter} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {YMInitializer} from 'react-yandex-metrika'
import {useRoutes} from './routes'

const App = () => {
  const routes = useRoutes()
  ReactGA.initialize('UA-71403418-5')
  ReactGA.pageview(window.location.pathname + window.location.search)

  return (
    <YMInitializer
      accounts={[66055276]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      }}>
      <BrowserRouter>{routes}</BrowserRouter>
      <ToastContainer
        className="notification"
        hideProgressBar={true}
        autoClose={4000}
        closeButton={false}
        position="bottom-right"
      />
    </YMInitializer>
  )
}

export default App
