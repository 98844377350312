import React from 'react'
import { isTablet } from 'react-device-detect'
import dotsBgTablet from '../../../images/bg-images/dots-bg-tablet.png'
import dotsBg from '../../../images/bg-images/dots-bg.png'
import scienceImg from '../../../images/bg-images/science.png'

const ScreenTwo = () => {
  return (
    <section className="screen-two">
      <div className="container container--two">
        <div className="screen-two-info">
          <h2 className="screen-two-info__title">
            Наука как социальная практика
          </h2>
          <div className="screen-two-info__desc">
            <h4 className="screen-two-info__desc-title">
              Наука — часть тебя. <br />
              Ты — часть науки
            </h4>
            <p className="screen-two-info__desc-text">
              Повседневность всё время подкидывает новые вопросы. Иногда они
              кажутся простыми, но ответы могут удивлять. Нередко великие
              открытия рождаются в поиске ответа на такие вопросы. И совершить
              их под силу любому человеку.
            </p>
          </div>
        </div>
        <img src={scienceImg} alt="science" className="screen-two-img" />
      </div>
      <img
        src={isTablet ? dotsBgTablet : dotsBg}
        alt="dots bg"
        className="screen-two-dots-img"
      />
    </section>
  )
}

export default ScreenTwo
