import React, {useState} from 'react'
import ReactGA from 'react-ga'
import {withId} from 'react-yandex-metrika'
import { isBlock } from 'typescript'
import KassaModal from '../kassaModal/KassaModal'
import LinkButton from '../linkButton/LinkButton'
import {
  ProgramBlockInterface,
  ProgramInterface,
  SubProgramInterface
} from '../screens/screenSeven/programsData'

interface Props {
  program: ProgramInterface
}

const ProgramBlock = ({program}: Props) => {
  const [isKassaModal, setKassaModal] = useState(false)
  const [currentKassaLink] = useState('')

  const onBuyClick = (link: () => void) => {
    //setCurrentKassaLink(link)
    //setKassaModal(true)
    link()
    withId(66055276)('reachGoal', 'got it')
    ReactGA.event({
      category: 'kupit',
      action: 'click',
    })
  }

  const programFile = (data: ProgramBlockInterface) => {
    if (data.fileLink) {
      return (
        <div className="screen-seven-links">
          <a
            className="screen-seven-link"
            href={data.fileLink}
            target="_blank"
            rel="noopener noreferrer">
            Программа направления «{data.name}»
          </a>
        </div>
      )
    }
  }


  const ticketLinks = (name: string, data:  ProgramBlockInterface | SubProgramInterface) => {
    return (
        <LinkButton
          content={data.subProgramName}
          href={data.semesterTicketLink}
        />
      )
  }

  const programInfo = (data: ProgramBlockInterface | SubProgramInterface, name: string) => (
    <div className="program-block__info">
      <p>{data.timetable}</p>
      {ticketLinks(name, data)}
    </div>
  )

  const renderProgramInfo = (block: ProgramBlockInterface) => {
    if (!block.subPrograms){
      return programInfo(block, block.name)
    } else {
      return block.subPrograms.map((subProgram: SubProgramInterface) =>
        programInfo(subProgram, block.name)
      );
    }
  }

  const renderBlockDesc = (block: ProgramBlockInterface) => (
    <div className="program-block__desc">
      <p>{block.description}</p>
      <p>{block.timetable}</p>

      {programFile(block)}
      {renderProgramInfo(block)}
    </div>
  )

  return (
    <div className="program-block">
      {/* <div onClick={() => setKassaModal(true)} className="program-block__logo">*/}
      <div className="program-block__logo">
        <img src={program.img} alt="program-logo" />
        <p className="program-block__desc">{program.description}</p>
        <p className="program-block__age">{program.age}</p>
        <a
            className="screen-seven-link"
            href={program.fileLink}
            target="_blank"
            rel="noopener noreferrer">
            Программа
          </a>
      </div>
      <div className="program-block__content">
        {renderBlockDesc(program.block)}
      </div>
      {isKassaModal && (
        <KassaModal
          src={currentKassaLink}
          onClose={() => setKassaModal(false)}
        />
      )}
    </div>

  )
}

export default ProgramBlock
