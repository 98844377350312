import React from 'react'
import {NavLink} from 'react-router-dom'

interface Props {
  isTutor?: boolean
}

interface Tab {
  name: string
  title: string
  url: string
}

const TABS: Tab[] = [
  {name: 'profile', title: 'Профиль', url: '/profile'},
  {name: 'schedule', title: 'Расписание', url: '/profile/schedule'},
  {name: 'announcements', title: 'Объявления', url: '/profile/announcements'},
  {name: 'edit', title: 'Редактировать профиль', url: '/profile/edit'},
]

const TUTOR_TABS: Tab[] = [
  {name: 'profile', title: 'Профиль', url: '/profile'},
  {name: 'schedule', title: 'Расписание', url: '/profile/tutor-schedule'},
  {name: 'edit', title: 'Редактировать профиль', url: '/profile/tutor-edit'},
]

const ProfileTabs = ({isTutor}: Props) => {
  const renderTab = (tab: Tab) => {
    return (
      <NavLink
        exact
        key={tab.name}
        to={tab.url}
        className={`profile-tabs__${tab.name === 'edit' ? 'edit-btn' : 'tab'}`}>
        {tab.title}
      </NavLink>
    )
  }

  const currentTabs = isTutor ? TUTOR_TABS : TABS

  return (
    <div className="profile-tabs">
      {currentTabs.map((tab) => renderTab(tab))}
    </div>
  )
}

export default ProfileTabs
