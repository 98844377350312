import {Field, Form, Formik} from 'formik'
import React, {useContext, useState} from 'react'
import {toast} from 'react-toastify'
import Sendsay from 'sendsay-api'
import * as Yup from 'yup'
import {WrapperContext} from '../../context/wrapper/wrapperContext'
import LinkButton from '../linkButton/LinkButton'
import Modal from '../modal/Modal'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Введите E-mail').email('Неверно введен E-mail'),
})
const sendsay = new Sendsay({
  apiKey: process.env.REACT_APP_SENDSAY_KEY,
})

const ThanksHTML = (
  <>
    <h3 className="modal-subscribe__success">Успешно!</h3>
    <p className="modal-subscribe__description">
      Вы подписались на уведомления о&nbsp;старте продаж нового абонемента.
    </p>
  </>
)

const SubscribePopap = () => {
  const {handleSubscribePopap, showSubscribeModal} = useContext(WrapperContext)
  const [showThanks, setShowThanks] = useState(false)

  const onClose = () => {
    setShowThanks(false)
    handleSubscribePopap(false)
  }

  return (
    <>
      {showSubscribeModal && (
        <Modal onClose={onClose} customClass="modal-subscribe">
          {showThanks ? (
            ThanksHTML
          ) : (
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={validationSchema}
              onSubmit={({email}, {setSubmitting, resetForm}) => {
                sendsay
                  .request({
                    action: 'member.set',
                    email,
                    obj: {'-group': {pl90719: 1}},
                  })
                  .then(function (res: any) {
                    resetForm()
                    setSubmitting(false)
                    setShowThanks(true)
                  })
                  .catch((err: any) => {
                    setSubmitting(false)
                    if (err.explain === 'error/email/wrongroot') {
                      toast.error('Неверный формат E-mail')
                      return false
                    }
                    toast.error('Ошибка сервера')
                  })
              }}>
              {({errors, touched, isSubmitting}) => (
                <Form className="form">
                  <div className="form__input-wrapper">
                    <span className="form__asterisk">*</span>
                    <Field
                      className={`form__input ${
                        errors.email && touched ? 'form__input--error' : ''
                      }`}
                      name="email"
                      type="email"
                      placeholder="E-mail"
                    />
                    {errors.email && touched && (
                      <span className="form__error">{errors.email}</span>
                    )}
                  </div>

                  <LinkButton
                    isSubmit
                    isBtn
                    disabled={isSubmitting}
                    content="Узнать первым"
                    loading={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          )}
        </Modal>
      )}
    </>
  )
}

export default SubscribePopap
