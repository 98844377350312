import React, {useContext, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {toast} from 'react-toastify'
import {
  AuthEduTokenCreateResponse,
  useAuthEduTokenCreate,
  EduUser,
  useUsersEduPasswordReset,
} from '../../api/fetchers'
import Footer from '../../components/footer/Footer'
import LoginForm from '../../components/loginForm/LoginForm'
import ResetPasswordForm from '../../components/resetPasswordForm/ResetPasswordForm'
import {WrapperContext} from '../../context/wrapper/wrapperContext'
import userProfile from '../../helpers/getCurrentProfile'
import {useRoute} from '../../hooks/router.hooks'
import logo from '../../images/header/logo.png'
import {LoginValues, ResetValues} from '../../types/formValues'

const Login = () => {
  const [resetForm, setResetForm] = useState(false)

  const {mutate: createToken, loading: loginLoading} = useAuthEduTokenCreate({})
  const {
    mutate: resetPassword,
    loading: resetPasswordLoading,
  } = useUsersEduPasswordReset({})

  const {setUser, setToken} = useContext(WrapperContext)
  const {routePath} = useRoute()

  const onLoginSubmit = (values: LoginValues) => {
    if (loginLoading) return

    let profile: {
      token: AuthEduTokenCreateResponse | ''
    } = {
      token: '',
    }

    createToken(values)
      .then((token) => {
        profile.token = token
        setToken(token)
        return userProfile(token.access)
      })
      .then(({data}: {data: EduUser}) => {
        setUser(data)
        localStorage.setItem('userData', JSON.stringify(profile))
        routePath('/profile')
        toast('Добро пожаловать!')
      })
      .catch(({data: {detail}}) => {
        toast.error(detail)
      })
  }

  const onResetPasswordSubmit = (values: ResetValues) => {
    if (resetPasswordLoading) return

    resetPassword(values)
      .then(() => {
        toast(
          'Письмо со ссылкой для восстановления пароля отправлено вам на почту',
        )
      })
      .catch(() => {
        toast.error('Ошибка сервера')
      })
  }

  return (
    <>
      <div className="auth">
        <NavLink to="/">
          <img src={logo} alt="logo" className="auth__logo" />
        </NavLink>
        <h3 className="auth__title">
          {resetForm ? 'Восстановление пароля' : 'Авторизация'}
        </h3>
        {resetForm ? (
          <ResetPasswordForm
            onSubmit={onResetPasswordSubmit}
            loading={resetPasswordLoading}
          />
        ) : (
          <LoginForm
            onSubmit={onLoginSubmit}
            onResetBtnClick={() => setResetForm(true)}
            loading={loginLoading}
          />
        )}
      </div>
      <Footer />
    </>
  )
}

export default Login
