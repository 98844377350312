export const ChangePasswordFields = [
  {
    name: 'old_password',
    placeholder: 'Старый пароль',
  },
  {
    name: 'new_password1',
    placeholder: 'Новый пароль',
  },
  {
    name: 'new_password2',
    placeholder: 'Повторите пароль',
  },
]

export const EditProfileFields = [
  {
    name: 'last_name',
    placeholder: 'Фамилия',
  },
  {
    name: 'first_name',
    placeholder: 'Имя',
  },
  {
    name: 'patronymic',
    placeholder: 'Отчество',
  },
  {
    name: 'email',
    placeholder: 'E-mail',
    type: 'email',
  },
  {
    name: 'phone',
    placeholder: 'Телефон',
    type: 'tel',
  },
]

export const SubscriptionsFields = [
  {
    name: 'notify_edu_announcement',
    label: 'Объявления',
  },
  {
    name: 'nofity_edu_lesson',
    label: 'Оповещения о начале занятий',
  },
]

export const EditTutorProfileFields = [
  {
    name: 'status_in',
    placeholder: 'Статус в Политехе',
  },
  {
    name: 'status_out',
    placeholder: 'Статус вне Политеха',
  },
  {
    name: 'phone',
    placeholder: 'Телефон',
    type: 'tel',
  },
  {
    name: 'facebook',
    placeholder: 'Facebook',
  },
  {
    name: 'telegram',
    placeholder: 'Telegram',
  },
]

export const LoginFields = [
  {
    name: 'username',
    placeholder: 'Логин',
    type: 'email',
  },
  {
    name: 'password',
    placeholder: 'Пароль',
    type: 'password',
  },
]

export const ResetPasswordFields = [
  {
    name: 'new_password1',
    placeholder: 'Новый пароль',
  },
  {
    name: 'new_password2',
    placeholder: 'Повторите пароль',
  },
]
