import clsx from 'clsx'
import React, {SyntheticEvent, useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {
  BlockNested,
  LessonNested,
  ScheduledLessonTimeslot,
  TrackAccess,
  useTrackAccessList,
} from '../../api/fetchers'
import KassaModal from '../../components/kassaModal/KassaModal'
import LessonRegisterModal from '../../components/lessonRegisterModal/LessonRegisterModal'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import formatDate from '../../helpers/formatDate'
import getLessonState from '../../helpers/getLessonState'
import getLocalTime from '../../helpers/getLocalTime'
import isMoscowTimeZone from '../../helpers/isMoscowTimeZone'
import exhibitionIcon from '../../images/exhibition_icon.png'
import exhibitionLogo from '../../images/exhibition_logo.png'
import {ReactComponent as Arrow} from '../../images/screen-five/nav-arrow.svg'

const Schedule = () => {
  const [currentProgram, setCurrentProgram] = useState<TrackAccess>()
  const [isKassaModal, setKassaModal] = useState(false)
  const [currentKassaLink, setCurrentKassaLink] = useState('')
  const [isRegisterModal, setRegisterModal] = useState(false)
  const [currentBlock, setCurrentBlock] = useState<number>()
  const [currentLesson, setCurrentLesson] = useState<number>()

  const {data: scheduleData, refetch: updateSchedule} = useTrackAccessList({})

  useEffect(() => {
    if (scheduleData && scheduleData.results) {
      setCurrentProgram(scheduleData.results[0])
    }
  }, [scheduleData])

  const onBuyClick = (event: SyntheticEvent, link: string) => {
    event.preventDefault()
    setCurrentKassaLink(link)
    setKassaModal(true)
  }

  const onRegisterBtnClick = (
    event: SyntheticEvent,
    block: number,
    lesson: number,
  ) => {
    event.preventDefault()
    setCurrentBlock(block)
    setCurrentLesson(lesson)
    setRegisterModal(true)
  }

  const renderLessonTime = (timeslots: ScheduledLessonTimeslot[]) =>
    timeslots.map((time) => (
      <div key={`timeslot_${time.id}`} className="schedule-lesson__time">
        <p className="schedule-lesson__time-text">
          {formatDate(time.start_at, 'full')}{' '}
          {!isMoscowTimeZone(time.start_at) && 'МСК'}
        </p>
        {!isMoscowTimeZone(time.start_at) && (
          <p className="schedule-lesson__time-local">
            {getLocalTime(time.start_at)}
          </p>
        )}
      </div>
    ))

  const renderLessonState = (type: string, content: string) => (
    <div className={`schedule-lesson__state schedule-lesson__state--${type}`}>
      {content}
    </div>
  )

  const renderLessonBtn = (data: LessonNested, content: string) => (
    <button
      onClick={
        data.state === 'need_purchase'
          ? (event) => onBuyClick(event, data.purchase_widget_url!)
          : (event) => onRegisterBtnClick(event, currentProgram!.id!, data.id)
      }
      type="button"
      className={`schedule-lesson__button schedule-lesson__button--${data.state}`}>
      {content}
      <Arrow />
    </button>
  )

  const renderBonusLesson = (
    data: LessonNested,
    type: 'exhibition' | 'bonus',
  ) => (
    <NavLink
      to={`/profile/lesson/${currentProgram!.id!}/${data.id}`}
      onClick={(event) => {
        if (data.state === 'need_purchase') {
          event.preventDefault()
        }
      }}
      key={data.title}
      className={`schedule-lesson schedule-lesson--exhibition schedule-lesson--${data.state}`}>
      <img src={exhibitionLogo} alt="" className="schedule-lesson__logo" />
      <img src={exhibitionIcon} alt="" className="schedule-lesson__icon" />
      <p className="schedule-lesson__title">{data.title}</p>
      {data.timeslots &&
        data.timeslots.length > 0 &&
        renderLessonTime(data.timeslots)}
      {type === 'exhibition' && (
        <p className="schedule-lesson__exhibition-text">
          Бесплатно после прохождения двух блоков
        </p>
      )}
      {type === 'bonus' &&
        data.state === 'not_assigned' &&
        renderLessonBtn(data, getLessonState(data.state))}
      {type === 'bonus' &&
        data.state !== 'not_assigned' &&
        renderLessonState(data.state, getLessonState(data.state))}
    </NavLink>
  )

  const renderLesson = (
    data: LessonNested,
    logo: any,
    icon: any,
    blockTitle: string,
    isOutside: boolean,
  ) => {
    const isNotClickable =
      data.state === 'not_available' || data.state === 'need_purchase'
    const isButton =
      data.state === 'not_assigned' || data.state === 'need_purchase'
    return (
      <NavLink
        to={`/profile/lesson/${currentProgram!.id!}/${data.id}`}
        onClick={(event) => {
          if (isNotClickable) {
            event.preventDefault()
          }
        }}
        key={data.title}
        className={`schedule-lesson schedule-lesson--${data.state}`}>
        {logo && <img src={logo} alt="" className="schedule-lesson__logo" />}
        {icon && <img src={icon} alt="" className="schedule-lesson__icon" />}
        <p className="schedule-lesson__block-title">{blockTitle}</p>
        <h5 className="schedule-lesson__title">{data.title}</h5>
        {isOutside && (
          <p className="schedule-lesson__address">{data.address}</p>
        )}
        {data.timeslots &&
          data.timeslots.length > 0 &&
          renderLessonTime(data.timeslots)}
        {isButton
          ? renderLessonBtn(data, getLessonState(data.state))
          : renderLessonState(data.state, getLessonState(data.state))}
      </NavLink>
    )
  }

  const renderBlock = (data: BlockNested) => (
    <div key={data.title} className="schedule-block">
      <p className="schedule-block__title">{data.title}</p>
      {data.notice && (
        <div className="schedule-block__notice">
          <p className="schedule-block__notice-title">Внимание!</p>
          <p className="schedule-block__notice-text">{data.notice}</p>
        </div>
      )}
      <div className="schedule-block__lessons">
        {data.lessons.map((lesson) =>
          data.kind === 'exhibition' || data.kind === 'bonus'
            ? renderBonusLesson(lesson, data.kind)
            : renderLesson(
                lesson,
                data.logo,
                data.icon,
                data.title,
                data.kind === 'outside',
              ),
        )}
      </div>
    </div>
  )
  return (
    <ProfileWrapper>
      <ProfileTabs />
      {currentProgram ? (
        <div className="schedule">
          <div className="schedule__handlers">
            {scheduleData!.results.map((item) => (
              <button
                onClick={() => setCurrentProgram(item)}
                key={`handler_${item.id}`}
                type="button"
                className={clsx(
                  'schedule__handlers-btn',
                  {
                    'schedule__handlers-btn--active':
                      currentProgram!.id === item.id,
                  },
                  {'schedule__handlers-btn--expired': !item.track.is_active},
                )}>
                {item.track.title}
              </button>
            ))}
          </div>
          {currentProgram.blocks &&
            currentProgram.blocks.map((block) => renderBlock(block))}
          {isKassaModal && (
            <KassaModal
              src={currentKassaLink}
              onClose={() => setKassaModal(false)}
            />
          )}
        </div>
      ) : (
        <h4 className="schedule__placeholder">Абонементов нет</h4>
      )}

      {isRegisterModal && currentBlock && currentLesson && (
        <LessonRegisterModal
          block={currentBlock}
          lesson={currentLesson}
          onRegister={updateSchedule}
          onClose={() => setRegisterModal(false)}
        />
      )}
    </ProfileWrapper>
  )
}

export default Schedule
