import {Field, Form, Formik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {
  useTrackAccessRegisterCreate,
  useTrackAccessRegisterRead,
} from '../../api/fetchers'
import formatDate from '../../helpers/formatDate'
import getLocalTime from '../../helpers/getLocalTime'
import isMoscowTimeZone from '../../helpers/isMoscowTimeZone'
import {LessonRegisterValues} from '../../types/formValues'
import LinkButton from '../linkButton/LinkButton'
import Modal from '../modal/Modal'

interface Props {
  onClose: () => void
  onRegister: () => void
  block: number
  lesson: number
}

const LessonRegisterModal = ({onClose, onRegister, block, lesson}: Props) => {
  const [disabled, setDisabled] = useState(true)
  const [success, setSuccess] = useState(false)
  const [lessonTitle, setLessonTitle] = useState('')

  const {data} = useTrackAccessRegisterRead({
    id: block + '',
    lesson_pk: lesson + '',
  })
  const {
    mutate: register,
    loading: registerLoading,
  } = useTrackAccessRegisterCreate({
    id: block + '',
    lesson_pk: lesson + '',
  })

  const validate = (values: LessonRegisterValues) => {
    if (values.scheduled_lesson) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const onSubmit = (values: LessonRegisterValues) => {
    if (registerLoading) return

    register({scheduled_lesson: +values.scheduled_lesson})
      .then((res) => {
        onRegister()
        setLessonTitle(res.scheduled_lesson.lesson_title!)
        setSuccess(true)
      })
      .catch(() => {
        toast.error('Ошибка сервера')
      })
  }

  const renderFormContent = () => (
    <div className="lesson-register-modal-form">
      <h4 className="lesson-register-modal-title">Запись на занятие</h4>
      <p className="lesson-register-modal-form__subtitle">
        Выберите удобные дату и время
      </p>
      <Formik
        initialValues={{
          scheduled_lesson: '',
        }}
        validate={validate}
        onSubmit={onSubmit}>
        {({values}) => (
          <Form className="lesson-register-form">
            {data!.map((item) => (
              <label key={item.id} className="lesson-register-form__check">
                <Field
                  className="lesson-register-form__check-input"
                  type="radio"
                  name="scheduled_lesson"
                  checked={values.scheduled_lesson === item.id + ''}
                  value={item.id}
                  disabled={item.available_amount === 0}
                />
                <div className="lesson-register-form__check-custom"></div>
                <div className="lesson-register-form__check-text">
                  {item.timeslots.map((time) => (
                    <p
                      key={`timeslot_${time.id}`}
                      className="lesson-register-form__check-time">
                      {formatDate(time.start_at, 'full')}{' '}
                      {!isMoscowTimeZone(time.start_at) && 'МСК'}
                      {!isMoscowTimeZone(time.start_at) && (
                        <span className="lesson-register-form__check-time-local">
                          {getLocalTime(time.start_at)}
                        </span>
                      )}
                    </p>
                  ))}
                  <p className="lesson-register-form__check-amount">
                    Осталось {item.available_amount} свободных мест
                  </p>
                </div>
              </label>
            ))}
            <LinkButton
              disabled={disabled}
              isSubmit
              isBtn
              content="Записаться"
              loading={registerLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  )

  const renderSuccessMessage = () => (
    <div className="lesson-register-modal-success">
      <p className="lesson-register-modal-title">Успешно!</p>
      <p className="lesson-register-modal-success__text">
        Вы записаны на занятие {lessonTitle}.
      </p>
      <LinkButton onClick={onClose} isBtn content="Закрыть" />
    </div>
  )

  if (data) {
    return (
      <Modal onClose={onClose}>
        {success ? renderSuccessMessage() : renderFormContent()}
      </Modal>
    )
  }

  return null
}

export default LessonRegisterModal
