import React from 'react'
import hse from '../../../images/screen-nine/HSE.png'
import misis from '../../../images/screen-nine/MISIS.png'
import bd from '../../../images/screen-nine/BD_LOGO.png'
import mnoucmgu from '../../../images/screen-nine/mnoucmgu.png'
import mc from '../../../images/screen-nine/mc.png'
import bb from '../../../images/screen-nine/bb.png'
import iki_ran from '../../../images/screen-nine/iki_ran.jpg'


const ScreenNine = () => {
  return (
    <section className="container container--nine">
      <h2 className="screen-nine__title">Партнёры</h2>
      <div className="screen-nine__wrap">
        <a
          className="screen-nine__logo"        
          // href="https://www.hse.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={hse} alt="hse-logo" />
        </a>
        <a
          className="screen-nine__logo"        
          // href="https://misis.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={misis} alt="misis-logo" />
        </a>
        <a
          className="screen-nine__logo"        
          // href="https://inpsycho.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={bd} alt="bd-logo" />
        </a>
        <a
          className="screen-nine__logo"        
          // href="https://inpsycho.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={mnoucmgu} alt="mnoucmgu-logo" />
        </a>
        <a
          className="screen-nine__logo"        
          // href="https://inpsycho.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={mc} alt="mc-logo" />
        </a>
        <a
          className="screen-nine__logo"        
          // href="https://inpsycho.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={bb} alt="bb-logo" />
        </a>
        <a
          className="screen-nine__logo"        
          // href="https://inpsycho.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={iki_ran} alt="iki-logo" />
        </a>
      </div>
    </section>
  )
}

export default ScreenNine
