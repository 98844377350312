import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {
  useLessonVotesCreate,
  useTrackAccessCancel,
  useTrackAccessGetLesson,
} from '../../api/fetchers'
import LessonRegisterModal from '../../components/lessonRegisterModal/LessonRegisterModal'
import LessonTutors from '../../components/lessonTutors/LessonTutors'
import LessonUnregisterModal from '../../components/lessonUnregisterModal/LessonUnregisterModal'
import LinkButton from '../../components/linkButton/LinkButton'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import QuestionModal from '../../components/questionModal/QuestionModal'
import VoteModal from '../../components/voteModal/VoteModal'
import formatDate from '../../helpers/formatDate'
import getLessonState from '../../helpers/getLessonState'
import getLocalTime from '../../helpers/getLocalTime'
import handleError from '../../helpers/handleError'
import isMoscowTimeZone from '../../helpers/isMoscowTimeZone'
import {useRoute} from '../../hooks/router.hooks'
import {ReactComponent as DownloadIcon} from '../../images/download.svg'

const Lesson = () => {
  const {lessonId, blockId} = useParams()
  const {routePath} = useRoute()

  const [isRegisterModal, setRegisterModal] = useState(false)
  const [isUnregisterModal, setUnregisterModal] = useState(false)
  const [isQuestionModal, setQuestionModal] = useState(false)
  const [isVoteModal, setVoteModal] = useState(false)
  const [isNotFound, setNotFound] = useState(false)

  const {
    data: lessonData,
    refetch: updateLesson,
    error,
  } = useTrackAccessGetLesson({
    id: blockId,
    lesson_pk: lessonId,
  })
  const {
    mutate: unregister,
    loading: unregisterLoading,
  } = useTrackAccessCancel({id: blockId})
  const {mutate: sendVote} = useLessonVotesCreate({})

  useEffect(() => {
    if (error && error.status === 404) {
      setNotFound(true)
    }
  }, [error])

  const handleVoteSend = (voteId: number) => {
    const values = {
      track_access: blockId,
      criterion: voteId,
      lesson: lessonId,
    }

    sendVote(values)
      .then(() => {
        updateLesson()
        toast.success('Ваш голос принят!')
      })
      .catch((error) => {
        handleError(error)
      })
  }

  const handleLessonUnregister = () => {
    if (unregisterLoading) return

    unregister(`${lessonData!.assignment!.id}`)
      .then(() => {
        updateLesson()
        setUnregisterModal(false)
        toast.success('Регистрация на занятие отменена')
      })
      .catch(() => {
        toast.error('Ошибка сервера')
      })
  }

  const renderLessonState = (type: string, content: string) => (
    <div className={`lesson__state lesson__state--${type}`}>{content}</div>
  )

  const renderFileLink = (
    link: string,
    title: string,
    type: 'record' | 'attachment',
  ) => (
    <a
      key={title}
      className="lesson__file-link"
      href={link}
      target="_blank"
      rel="noopener noreferrer">
      {type === 'attachment' && <DownloadIcon />}
      <span className="lesson__file-link-title">{title}</span>
    </a>
  )

  if (isNotFound) {
    routePath('/not-found')
  }

  if (lessonData) {
    const {
      image,
      state,
      records,
      tutors,
      track_logo,
      block_title,
      title,
      description,
      attachments,
      assignment,
      address,
      total_votes,
      user_vote,
    } = lessonData

    return (
      <ProfileWrapper>
        <ProfileTabs />
        <div className="lesson">
          <div className="lesson__aside">
            {image && <img src={image} alt="" className="lesson__image" />}
            {state === 'assigned' && (
              <button
                type="button"
                className="lesson__unregister-btn"
                onClick={() => setUnregisterModal(true)}>
                Изменить запись на занятие
              </button>
            )}
            {state === 'not_assigned' && (
              <LinkButton
                onClick={() => setRegisterModal(true)}
                isBtn
                content="Записаться"
              />
            )}
            {assignment?.scheduled_lesson?.ordered_timeslots?.map((time) => (
              <>
                <p key={time.id} className="lesson__time">
                  {formatDate(time.start_at, 'full')}{' '}
                  {!isMoscowTimeZone(time.start_at) && 'МСК'}
                </p>

                {!isMoscowTimeZone(time.start_at) && (
                  <p key={`local_${time.id}`} className="lesson__time-local">
                    {getLocalTime(time.start_at)}
                  </p>
                )}
              </>
            ))}
            {state === 'passed' && total_votes && total_votes.length > 0 && (
              <div className="lesson__votes">
                <div className="lesson__votes-items">
                  {total_votes.map((vote_item) => (
                    <button
                      onClick={() => handleVoteSend(vote_item.id!)}
                      type="button"
                      className="lesson__votes-item">
                      <div>
                        {vote_item.image && (
                          <img
                            alt=""
                            className="lesson__votes-icon"
                            src={vote_item.image}
                          />
                        )}
                        {!!vote_item.total && (
                          <span className="lesson__votes-total">
                            {vote_item.total}
                          </span>
                        )}
                      </div>
                      {vote_item.title}
                    </button>
                  ))}
                </div>
                {user_vote && Object.keys(user_vote).length > 0 && (
                  <LinkButton
                    onClick={() => setVoteModal(true)}
                    isBtn
                    content="Оставить отзыв"
                  />
                )}
              </div>
            )}
            {assignment && assignment.scheduled_lesson.conference_url && (
              <a
                className="lesson__conf-link"
                href={assignment.scheduled_lesson.conference_url}
                target="_blank"
                rel="noopener noreferrer">
                Ссылка на конференцию
              </a>
            )}
            {assignment && assignment.scheduled_lesson.conference_id && (
              <p className="lesson__conf-info">
                Идентификатор конференции:{' '}
                {assignment.scheduled_lesson.conference_id}
              </p>
            )}
            {assignment &&
              assignment.scheduled_lesson.conference_access_code && (
                <p className="lesson__conf-info">
                  Код доступа:{' '}
                  {assignment.scheduled_lesson.conference_access_code}
                </p>
              )}
            {records &&
              records.length > 0 &&
              records.map((file) =>
                renderFileLink(file.upload!, file.title, 'record'),
              )}
            {state === 'assigned' && (
              <>
                <LinkButton
                  onClick={() => setQuestionModal(true)}
                  isBtn
                  content="Задать вопрос"
                />
                <p className="lesson__question-text">
                  Вы можете задать преподавателю вопрос по теме, и он ответит на
                  него на занятии.
                </p>
              </>
            )}
            {tutors && tutors.length > 0 && <LessonTutors data={tutors} />}
          </div>
          <div className="lesson__content">
            {track_logo && (
              <img src={track_logo} alt="" className="lesson__logo" />
            )}
            <p className="lesson__block-title">{block_title}</p>
            <h3 className="lesson__title">{title}</h3>
            {state === 'assigned' ||
              (state === 'passed' &&
                renderLessonState(state, getLessonState(state)))}
            {address && (
              <p className="lesson__address">
                <span className="lesson__address-title">Место проведения:</span>
                <br />
                {address}
              </p>
            )}
            <div
              className="lesson__description"
              dangerouslySetInnerHTML={{__html: description}}></div>
            {attachments &&
              attachments.length > 0 &&
              attachments.map((file) =>
                renderFileLink(file.upload!, file.title, 'attachment'),
              )}
          </div>
          {isRegisterModal && (
            <LessonRegisterModal
              block={blockId}
              lesson={lessonId}
              onRegister={updateLesson}
              onClose={() => setRegisterModal(false)}
            />
          )}
          {isQuestionModal && (
            <QuestionModal
              lesson={lessonId}
              onClose={() => setQuestionModal(false)}
            />
          )}
          {isVoteModal && user_vote && (
            <VoteModal
              onClose={() => setVoteModal(false)}
              voteId={user_vote.id!}
            />
          )}
          {isUnregisterModal && (
            <LessonUnregisterModal
              onConfirm={handleLessonUnregister}
              onClose={() => setUnregisterModal(false)}
              loading={unregisterLoading}
            />
          )}
        </div>
      </ProfileWrapper>
    )
  }

  return null
}

export default Lesson
