import React, {ReactNode} from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'

interface Props {
  children: ReactNode
}

const ProfileWrapper = ({children}: Props) => {
  return (
    <div className="profile">
      <Header />
      <div className="profile__content">
        <h3 className="profile__title">Личный кабинет</h3>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default ProfileWrapper
