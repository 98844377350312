import React, {useState} from 'react'
import {isMobileOnly, isTablet} from 'react-device-detect'
import {TutorNested} from '../../api/fetchers'
import navArrow from '../../images/screen-five/nav-arrow.svg'

interface Props {
  data: TutorNested[]
}

const TUTOR_WIDTH = isTablet ? 225 : 365

const LessonTutors = ({data}: Props) => {
  const [leftPosition, setLeftPosition] = useState(0)
  const [currentTutor, setCurrentTutor] = useState(1)

  const getTutorWidth = () => {
    let tutorWidth
    if (isMobileOnly) {
      tutorWidth = document.querySelector('.lesson-tutors__content')
        ?.clientWidth
    } else {
      tutorWidth = TUTOR_WIDTH
    }

    return tutorWidth
  }

  const slideLeft = () => {
    if (currentTutor === 1) {
      return
    }

    setLeftPosition(leftPosition + getTutorWidth()!)
    setCurrentTutor(currentTutor - 1)
  }

  const slideRight = () => {
    if (currentTutor === data.length) {
      return
    }

    setLeftPosition(leftPosition - getTutorWidth()!)
    setCurrentTutor(currentTutor + 1)
  }

  const renderTutor = (tutor: TutorNested) => (
    <div key={`tutor_${tutor.id}`} className="lesson-tutors__tutor">
      <div className="lesson-tutors__tutor-header">
        <img
          src={tutor.photo_img?.photo}
          alt=""
          className="lesson-tutors__tutor-img"
        />
        <p className="lesson-tutors__tutor-name">
          {tutor.first_name} {tutor.last_name}
        </p>
      </div>
      <p className="lesson-tutors__tutor-desc">{tutor.description}</p>
    </div>
  )

  return (
    <div className="lesson-tutors">
      <h4 className="lesson-tutors__title">
        {data.length === 1 ? 'Преподаватель' : 'Преподаватели'}
      </h4>
      <div style={{left: leftPosition}} className="lesson-tutors__content">
        {data.map((tutor) => renderTutor(tutor))}
      </div>
      {data.length > 1 && (
        <div className="lesson-tutors__controls">
          <button onClick={slideLeft} className="lesson-tutors__controls-btn">
            <img src={navArrow} alt="" />
          </button>
          <button onClick={slideRight} className="lesson-tutors__controls-btn">
            <img src={navArrow} alt="" />
          </button>
        </div>
      )}
    </div>
  )
}

export default LessonTutors
