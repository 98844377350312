import {AuthTokenRefreshCreateResponse, EduUser} from '../../api/fetchers'
import {SET_TOKEN, SET_USER, HANDLER_SUBSCRIBE_MODAL} from '../types'

export interface IStateReducer {
  user: EduUser
  token: AuthTokenRefreshCreateResponse
  showSubscribeModal: boolean
}

export type Action =
  | {type: typeof SET_USER; payload: EduUser}
  | {type: typeof SET_TOKEN; payload: AuthTokenRefreshCreateResponse}
  | {
      type: typeof HANDLER_SUBSCRIBE_MODAL
      payload: boolean
    }

export const wrapperReducer = (
  state: IStateReducer,
  action: Action,
): IStateReducer => {
  switch (action.type) {
    case SET_USER:
      return {...state, user: action.payload}
    case SET_TOKEN:
      return {...state, token: action.payload}
    case HANDLER_SUBSCRIBE_MODAL:
      return {...state, showSubscribeModal: action.payload}
    default:
      return state
  }
}
