import React from 'react'
import Header from '../header/Header'

const ForbiddenMessage = () => (
  <>
    <Header />
    <h4 className="forbidden-message">
      Недостаточно прав
      <br />
      для просмотра данной страницы
    </h4>
  </>
)

export default ForbiddenMessage
