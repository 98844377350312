import {useCallback} from 'react'
import {useHistory} from 'react-router-dom'

export const useRoute = () => {
  const history = useHistory()

  const routePath = useCallback(
    (url: string) => {
      history.push(url)
    },
    [history],
  )

  return {routePath}
}
