import React from 'react'
import LinkButton from '../linkButton/LinkButton'
import Modal from '../modal/Modal'

interface Props {
  onClose: () => void
  onConfirm: () => void
  loading: boolean
}

const LessonUnregisterModal = ({onClose, onConfirm, loading}: Props) => {
  return (
    <Modal onClose={onClose}>
      <div className="lesson-unregister-modal">
        <h4 className="lesson-unregister-modal__title">
          Вы уверены, что хотите изменить запись на занятие?
        </h4>
        <LinkButton
          onClick={onConfirm}
          isBtn
          content="Изменить"
          loading={loading}
        />
      </div>
    </Modal>
  )
}

export default LessonUnregisterModal
