import React from 'react'
import Modal from '../modal/Modal'

interface Props {
  onClose: () => void
  src: string
}

const KassaModal = ({onClose, src}: Props) => {
  return (
    <div className="kassa-modal">
      <Modal onClose={onClose}>
        <iframe className="kassa-modal__frame" src={src} title="kassa" />
      </Modal>
    </div>
  )
}

export default KassaModal
