import React from 'react'
import {configureAnchors} from 'react-scrollable-anchor'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import {
  ScreenEight,
  ScreenFive,
  ScreenFour,
  ScreenNine,
  ScreenOne,
  ScreenSeven,
  ScreenSix,
  ScreenThree,
  ScreenTwo,
} from '../../components/screens/screens'
import SubscribePopap from '../../components/subscribePopap'

configureAnchors({scrollDuration: 1000})

const Main = () => {
  return (
    <main className="main">
      <SubscribePopap />
      <Header />
      <ScreenOne />
      <ScreenTwo />
      <ScreenThree />
      <ScreenFour />
      <ScreenFive />
      <ScreenSix />
      <ScreenSeven />
      <ScreenEight />
      <ScreenNine />
      <Footer />
    </main>
  )
}

export default Main
