import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import LinkButton from '../../linkButton/LinkButton'
import MovementProgramBlock from '../../movementProgramBlock/MovementProgramBlock'
import ProgramBlock from '../../programBlock/ProgramBlock'
import { programsData } from './programsData'



const ScreenSeven = () => {

  return (
    <ScrollableAnchor id={'seasontickets'}>
      <section className="container container--seven">
        <h2 className="screen-seven-title">Программы</h2>
        {/* <div className="screen-seven-wrap">
          <div className="screen-seven-summer-title">
            <img src="/summer.png" alt="" />
            <h4 className="screen-seven-midtitle">Лето в Университете детей</h4>
          </div>
          <p className="screen-seven-subtitle">
            Летом мы онлайн объединим студентов Университета детей из самых разных мест, чтобы узнать, как устроен океан, провести собственные генеалогические исследования, изучить, откуда берутся молнии и почему полезны игры.
          <br /><br />
            Время проведения: 17-27 июля
          <br />
            Возраст: 7-10 лет
          </p>
          <p className="screen-seven-boldtitle">4 занятия:</p>
          <ul className="screen-seven-list">
            <li>Как устроен океан?</li>
            <li>Что можно найти в бабушкином сундуке?</li>
            <li>Как появляются молнии?</li>
            <li>Полезно ли играть в игры?</li>
          </ul>
        </div> */}
        <br />
        <br />
        {/* <ul className="screen-seven-list">
          <li>
            <p>Занятия с 17 по 20 июля в 11:00</p>        
            <LinkButton
              content="КУПИТЬ АБОНЕМЕНТ"
              href='https://tickets.polymus.ru/event/212BE7B37DB28295F8C8C896DA6B9EC34C99FF79'
            />
          </li>
          <li>
            <p>Занятия с 17 по 20 июля в 18:00</p>        
            <LinkButton
              content="КУПИТЬ АБОНЕМЕНТ"
              href='https://tickets.polymus.ru/event/B7042ECC81EA552AC7FCB60C0CC40382C275B00A'
            />
          </li>
          <li>
            <p>Занятия с 24 по 27 июля в 11:00</p>        
            <LinkButton
              content="КУПИТЬ АБОНЕМЕНТ"
              href='https://tickets.polymus.ru/event/F86890DCA3A442A7A0570E8D081BB4387D765B7D'
            />
          </li>
          <li>
            <p>Занятия с 24 по 27 июля в 18:00</p>        
            <LinkButton
              content="КУПИТЬ АБОНЕМЕНТ"
              href='https://tickets.polymus.ru/event/045FFBE5B37464C29F2F5619532BD400836C1753'
            />
          </li>
        </ul> */}

        <p className="screen-seven-subtitle">
          В Университете детей есть три направления для детей и подростков разного возраста: “Движение” для студентов 5-6 лет, “Импульс” для студентов 7-9 лет, “Вектор” для студентов 10-12 лет. В рамках направления “Движение” студенты могут посетить два разных курса: “Исследователи воздушной среды” и “Исследователи недр Земли”. 
        </p>

          {/* В Университете детей четыре направления: «Движение» (5–6 лет), «Энергия» (7–8 лет), «Импульс»
          (9–10), «Векторы» (11–14 лет).
          <br />
          Программа каждого направления составлена с учётом когнитивных
          особенностей и интересов детей того или иного возраста. */}
        {/* </p> */}
        {/* <div className="screen-seven-links">
          <a
            className="screen-seven-link"
            href="/programma_2023.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Программа семестра
          </a>
        </div> */}

        {/* <MovementProgramBlock /> */}
        
        <div className="screen-seven-programs">
          <div className="screen-seven-programs__wrap">
            {programsData.map((program) => (
              <ProgramBlock key={program.id} program={program} />
            ))}
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  )
}

export default ScreenSeven
