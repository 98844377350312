import React, {useContext} from 'react'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import {WrapperContext} from '../../context/wrapper/wrapperContext'

interface userData {
  value: string
  content: string | undefined | null
}

const TutorProfile = () => {
  const {user} = useContext(WrapperContext)

  const renderProfileData = () => {
    const profileData = [
      {
        value: 'ФИО',
        content:
          `${user.tutor_data?.first_name} ${user.tutor_data?.last_name}` || '-',
      },
      {
        value: 'Информация',
        content: user.tutor_data?.description,
      },
      {
        value: 'Статус в Политехе',
        content: user.tutor_data?.status_in,
      },
      {
        value: 'Статус вне Политеха',
        content: user.tutor_data?.status_out,
      },
    ]

    const contactsData = [
      {
        value: 'Телефон',
        content: user.tutor_data?.phone,
      },
      {
        value: 'Facebook',
        content: user.tutor_data?.facebook,
      },
      {
        value: 'Telegram',
        content: user.tutor_data?.telegram,
      },
    ]

    const renderInfo = (data: userData[]) =>
      data.map((item) => (
        <div key={item.value} className="profile-data__user-text">
          <p className="profile-data__user-text-value">{item.value}</p>
          <p className="profile-data__user-text-content">
            {item.content && item.content.trim() ? item.content : '-'}
          </p>
        </div>
      ))

    return (
      <div className="profile-data">
        {user.tutor_data?.photo_img?.photo && (
          <img
            src={user.tutor_data?.photo_img?.photo}
            alt=""
            className="profile-data__photo"
          />
        )}
        <div className="profile-data__content">
          <div className="profile-data__user-info">
            {renderInfo(profileData)}
            <p className="profile-data__user-text-value">Контакты</p>
            <div className="profile-data__contacts">
              {renderInfo(contactsData)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ProfileWrapper>
      <ProfileTabs isTutor />
      {renderProfileData()}
    </ProfileWrapper>
  )
}

export default TutorProfile
