import React from 'react'
import {isMobileOnly} from 'react-device-detect'
import berryBg from '../../../images/bg-images/berry-bg.png'
import LinkButton from '../../linkButton/LinkButton'

const ScreenOne = () => {
  return (
    <section className="container container--one screen-one">
      <h2 className="screen-one-title">
        Здесь дети вместе с учёными ищут ответы на самые каверзные вопросы
      </h2>
      <p className='screen-one-info__desc-text'>Абонементы на осенний семестр 2024 года уже можно купить!</p>
      {/* <LinkButton content="Программа семестра" href="/programma_2023.pdf" isNewTab /> */}
      <div className="screen-one-links">
        <a href="#education" className="screen-one-links__link">
          <span className="screen-one-links__link-text">Как устроено</span>
          <span className="screen-one-links__link-text">обучение?</span>
        </a>
        <a href="#discussion" className="screen-one-links__link">
          <span className="screen-one-links__link-text">
            {isMobileOnly ? 'Что обсуждают дети' : 'Что обсуждают'}
          </span>
          <span className="screen-one-links__link-text">
            {isMobileOnly ? 'и учёные?' : 'дети и учёные?'}
          </span>
        </a>
        <a href="#whatsnew" className="screen-one-links__link">
          <span className="screen-one-links__link-text">Что нового в</span>
          <span className="screen-one-links__link-text">
            Университете детей?
          </span>
        </a>
        <a href="#seasontickets" className="screen-one-links__link">
          <span className="screen-one-links__link-text">Программы</span>
          <span className="screen-one-links__link-text">и абонементы</span>
        </a>
        <a href="#faq" className="screen-one-links__link">
          <span className="screen-one-links__link-text">FAQ</span>
        </a>
      </div>
      <div className="screen-one-info">
        <img src={berryBg} alt="berry bg" className="screen-one-info__img" />
        <h2 className="screen-one-info__title">Что такое Университет детей?</h2>
        <p className="screen-one-info__desc">
          <span className="screen-one-info__desc-text">
            Университет детей — просветительский проект Политехнического музея
            для детей и подростков 5–12 лет.
          </span>
          <span className="screen-one-info__desc-text">
            У нас ребенок познакомится с разными науками и определит, какая из
            них ему ближе. А также взглянет на мир глазами исследователя и
            освоит научный метод познания.
          </span>
          <span className="screen-one-info__desc-text">
            Вместе с лучшими учёными мы обсуждаем темы, которые интересны детям,
            и создаём среду для самостоятельного исследования. Здесь дети учатся
            не только задавать вопросы, но и искать на них ответы.
          </span>
        </p>
      </div>
    </section>
  )
}

export default ScreenOne
