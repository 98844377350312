import {Field, Form, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {LoginFields} from '../../contentData/formFieldsData'
import {LoginValues} from '../../types/formValues'
import LinkButton from '../linkButton/LinkButton'

interface Props {
  onSubmit: (values: LoginValues) => void
  onResetBtnClick: () => void
  loading: boolean
}

const LoginForm = ({onSubmit, onResetBtnClick, loading}: Props) => {
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Введите логин')
      .email('Введите валидный e-mail'),
    password: Yup.string().required('Введите пароль'),
  })

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({errors, touched}) => (
        <Form className="form">
          {LoginFields.map((field) => (
            <div key={field.name} className="form__input-wrapper">
              <span className="form__asterisk">*</span>
              <Field
                className={`form__input ${
                  errors[field.name as keyof LoginValues] &&
                  touched[field.name as keyof LoginValues]
                    ? 'form__input--error'
                    : ''
                }`}
                id={field.name}
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
              />
              {errors[field.name as keyof LoginValues] &&
                touched[field.name as keyof LoginValues] && (
                  <span className="form__error">
                    {errors[field.name as keyof LoginValues]}
                  </span>
                )}
            </div>
          ))}
          <button
            onClick={onResetBtnClick}
            type="button"
            className="form__reset-btn">
            Восстановление пароля
          </button>
          <LinkButton isSubmit isBtn content="Войти" loading={loading} />
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
