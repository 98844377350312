import React from 'react'
import borisenko from '../../../images/screen-five/grigor.jpg'
import gosteva from '../../../images/screen-five/akcel.png'
import kumankov from '../../../images/screen-five/ninen.jpg'
import matveeva from '../../../images/screen-five/panish.jpg'
import lapin from '../../../images/screen-five/lark.jpg'
import buzunov from '../../../images/screen-five/buzunov.png'
import kalashnikova from '../../../images/screen-five/kalashnikova.png'
import borisenkog from '../../../images/screen-five/borisenkog.png'
import zhuravlev from '../../../images/screen-five/zhuravlev.jpg'
import serezhkin from '../../../images/screen-five/serezhkin.jpg'
import korosteleva from '../../../images/screen-five/korosteleva.jpg'
import dudova from '../../../images/screen-five/dudova.jpg'
import svarnik from '../../../images/screen-five/svarnik.jpg'
import romanenko from '../../../images/screen-five/romanenko.jpeg'
import hisamutdinova from '../../../images/screen-five/hisamutdinova.jpg'
import demidova from '../../../images/screen-five/demidova.jpg'
import nikishova from '../../../images/screen-five/nikishova.jpg'
import romanenkoNew from '../../../images/screen-five/romanenkoNew.jpg'
import tumasova from '../../../images/screen-five/tumasova.jpg'
import zimina from '../../../images/screen-five/zimina.jpeg'
import antipin from '../../../images/screen-five/antipin.jpg'



const BORISENKO_TEXT =
  'Ольга Григорьева — кандидат биологических наук, младший научный сотрудник Института регенеративной медицины медицинского научно-образовательного центра МГУ им. М.В. Ломоносова'
const BUZUNOV_TEXT =
  'Дмитрий Бузунов — физик, сотрудник института проблем химической физики (ИПХФ РАН).'
const KALASHNIKOVA_TEXT =
  'Ольга Калашникова — старший научный сотрудник Государственного биологического музея им. К.А. Тимирязева.'
const BORISENKOG_TEXT =
  'Геннадий Борисенко — младший научный сотрудник лаборатории биогидрохимии Института океанологии им. П.П. Ширшова Российской академии наук.'
const MATVEEVA_TEXT =
  'Василий Панюшкин — сотрудник научно-исследовательского отдела Политехнического музея'
const GOSTEVA_TEXT =
  'Алекс Аксельрод — основатель fintech-сервиса Aximetria'
const LAPIN_TEXT =
  'Екатерина Ларкина — историк, куратор генеалогических проектов «Проекта Жизнь»'
const KUMANKOV_TEXT =
  'Иван Ниненко — исследователь в Центре биоэлектрических интерфейсов НИУ ВШЭ и Центре нейробиологии и нейрореабилитации Сколковского института науки и технологий'
const ZHURAVLEV_TEXT = 
  'Андрей Журавлев - Палеонтолог, доктор биологических наук, профессор кафедры биологической эволюции биологического факультета МГУ им. М.В. Ломоносова, научный редактор «National Geographic Россия».'
const SEREZHKIN_TEXT = 
  'Илья Сережкин - Микробиолог, сотрудник Лаборатории физиологии и биохимии микробов Биологического факультета МГУ.'
const KOROSTELEVA_TEXT = 
  'Валерия Коростелева - руководитель Московского отделения движения «РазДельный Сбор», сооснователь, экотренер и главный эксперт по экологическому просвещению проекта "Собиратор".'
const DUDOVA_TEXT = 
  'Ксения Дудова — кандидат биологических наук, младший научный сотрудник кафедры экологии и географии растений Биологического факультета МГУ им. М.В. Ломоносова.'
const SVARNIK_TEXT = 
  'Ольга Сварник — кандидат психологических наук, ведущий научный сотрудник лаборатории психофизиологии им. В. Б. Швыркова Института психологии РАН.'
const ROMANENKO_TEXT = 
  'Мария Романенко — историк, экскурсовод Политехнического музея'
const HISAMUTDINOVA_TEXT = 
  'Айсылу Хисамутдинова - кандидат геолого-минералогических наук, старший научный сотрудник Геологического института РАН'
const DEMIDOVA_TEXT = 
  'Татьяна Демидова - оператор Многофункционального инновационного телевизионного технического центра НИУ ВШЭ'
const NIKISHOVA_TEXT = 
  'Екатерина Никишова - гидробиолог, выпускник биофака МГУ им. М.В. Ломоносова, популяризатор науки'
  const ZIMINA_TEXT = 
  'Анна Зимина - инженер Научно-образовательного центра биомедицинской инженерии НИТУ МИСИС'
const ANTIPIN_TEXT = 
  'Сергей Антипин - кандидат физико-математических наук, учёный секретарь Государственного астрономического института имени П.К. Штернберга МГУ имени М.В. Ломоносова'
const TUMASOVA_TEXT = 
  'Алина Тумасова - химик-технолог, педагог дополнительного образования, педагог детского технопарка «Менделеев Центр» при РХТУ им Д.И. Менделеева'

  const getSliderData = (onHover: any = null, onBlur: any = null) => {
  const sliderData = [
    {
      img: tumasova,
      text: (
        <p className="screen-five-slider-item__text">
          С химиком  {' '}
          <span
            onMouseEnter={(e) => onHover(e, TUMASOVA_TEXT)}
            onMouseLeave={onBlur}
            className="screen-five-slider-item__text-hover">
            Алиной Тумасовой
          </span>{' '}
          изучили, как устроен цвет с точки зрения химии, и сделали разноцветные краски в лаборатории.  
        </p>
         
      ),
    },
    {
      img: antipin,
      text: (
        <p className="screen-five-slider-item__text">
          С астрономом {' '}
          <span
            onMouseEnter={(e) => onHover(e, ANTIPIN_TEXT)}
            onMouseLeave={onBlur}
            className="screen-five-slider-item__text-hover">
            Сергеем Антипиным
          </span>{' '}
            выяснили, откуда на небе появляются звёзды и могут ли люди создать искусственную звезду.
        </p>
          
      ),
    },
    {
      img: zimina,
      text: (
        <p className="screen-five-slider-item__text">
          С инженером {' '}
          <span
            onMouseEnter={(e) => onHover(e, ZIMINA_TEXT)}
            onMouseLeave={onBlur}
            className="screen-five-slider-item__text-hover">
            Анной Зиминой
          </span>{' '}
            узнали, как с помощью технологий люди способны обрести силы, превосходящие возможности своих организмов.
        </p>
      ),
    },
    {
      img: romanenkoNew,
      text: (
        <p className="screen-five-slider-item__text">
          С экскурсоводом Политехнического музея {' '}
          <span
            onMouseEnter={(e) => onHover(e, ROMANENKO_TEXT)}
            onMouseLeave={onBlur}
            className="screen-five-slider-item__text-hover">
            Марией Романенко
          </span>{' '}
            исследовали, из чего состоят облака и что общего у тираннозавра и курицы. 
        </p>
      ),
    }
  ]

  return sliderData
}

export default getSliderData
