import React from 'react'
import {NavLink} from 'react-router-dom'
import logo from '../../images/header/logo.png'
import polyLogo from '../../images/header/logo.svg'
import userLogo from '../../images/user.svg'

const Header = () => {
  return (
    <header className="header">
      <NavLink to="/">
        <img className="header__logo" src={logo} alt="logo" />
      </NavLink>
      <a href="mailto:ydetei@polytech.one" className="header__phone">
        ydetei@polytech.one
        <p className="header__schedule">
          пн - пт c 11:00 до 17:00. 
        </p>
      </a>
      <NavLink to="/profile" className="header__login-link">
        <img src={userLogo} alt="login" />
      </NavLink>
      <a
        href="https://polytech.one/ru"
        target="_blank"
        rel="noopener noreferrer"
        className="header__poly-link">
        <img src={polyLogo} alt="polytech logo" />
      </a>
    </header>
  )
}

export default Header
