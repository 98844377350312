import energyLogo from '../../../images/screen-seven/energy.png'
import impulsLogo from '../../../images/screen-seven/impuls.png'
import vektorLogo from '../../../images/screen-seven/vektor.png'
import moveLogo from '../../../images/movement-program-block/main.png'

export interface SubProgramInterface {
  timetable?: string
  yearTicketLink?: string
  semesterTicketLink?: string
  time?: string
  option?: string
  subProgramName?: string
}

export interface ProgramBlockInterface {
  title: string
  description?: string
  timetable?: string
  subProgramName?: string
  price?: String
  link: () => void
  subPrograms?: SubProgramInterface[]
  yearTicketLink?: string
  semesterTicketLink?: string
  time?: string
  option?: string
  name: string
  fileLink?: string
}

export interface ProgramInterface {
  id: number
  img: string
  age: string
  description?: string
  block: ProgramBlockInterface
  fileLink?: string
}

export const programsData = [
  {
    id: 1,
    img: moveLogo,
    description: 'курс “Исследователи воздушной среды”',
    age: '5-6 лет',
    fileLink: '/dvizhenie2024.pdf',
    block: {
      // description: 'Занятия самых младших студентов Университета детей отличаются от всех остальных направлений своим форматом. Участники выбирают одну из тем и исследуют её в течение пяти занятий. В весеннем семестре 2023 года мы впервые отправимся изучать космос и повторим исследования динозавров и моря.',
      subPrograms: [
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/2182AA216EE96C87AB0F0EFDAD75B354C5D64313',
          // subProgramName: "Купить абонемент “Исследователи динозавров”, 11:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 11:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/95D65531681A2273EC55C9E14E37F9DC454FE12E',
          // subProgramName: "Купить абонемент “Исследователи космоса”, 13:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 13:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/7D33351520C43B38656C99AEE462979481CCB35E',
          // subProgramName: "Купить абонемент “Исследователи погоды”, 15:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 15:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/A974DBD4141902342544B52988A4EE7626629002',
          subProgramName: "Купить абонемент на 11:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/B534CC803C69BE14A50FDA611697AEF105742AC3',
          subProgramName: "Купить абонемент на 13:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/157265ACF20E8642426335F544034E10EB899040',
          subProgramName: "Купить абонемент на 15:00, 3 ноября - 1 декабря",
        },
      ],
      name: 'Движение',
      title: 'Как это устроено?',
      price: '2600 руб.',
      link: () => {
        // @ts-ignore
        //window['YandexTicketsDealer'].push(['getDealer', function(dealer) { dealer.open({ id: '234214', type: 'event' }) }])
      },
    },
  },
  {
    id: 2,
    img: moveLogo,
    description: 'курс “Исследователи недр Земли”',
    age: '5-6 лет',
    fileLink: '/dvizhenie_nedra_zemli2024.pdf',
    block: {
      // description: 'Студенты направления «Энергия» в весеннем семестре 2023 года познакомятся с пятью науками: архитектурой, экономикой, зоологией, ботаникой и физической антропологией. Вместе с учёными участники попробуют себя в этих науках и найдут ответы на многие вопросы, например, как животные общаются друг с другом или зачем икота и мурашки у людей.',
      subPrograms: [
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/283092B2C8B03C8550FE8B3509C1429724834F62',
          // subProgramName: "Купить абонемент “Исследователи динозавров”, 11:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 11:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/F8775E03CA18F603ABB5F63863BF1C450BE6D1FA',
          // subProgramName: "Купить абонемент “Исследователи космоса”, 13:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 13:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/0713D62690446559C987647F57258A4438CE94F0',
          // subProgramName: "Купить абонемент “Исследователи погоды”, 15:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 15:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/27596F7476D7E70125A917BA918632F37E6F5C4B',
          subProgramName: "Купить абонемент на 11:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/F44426B9CE85B739E81CF89CC03D485CF0967D17',
          subProgramName: "Купить абонемент на 13:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/FFE3D9EA91782244BF0FF6BB30AD9B22CC50C053',
          subProgramName: "Купить абонемент на 15:00, 3 ноября - 1 декабря",
        }
      ],
      title: 'Как это устроено?',
      name: 'Энергия',
      // fileLink: '/programma_UD-vesna-2023-4_5-5.pdf',
      price: '2600 руб.',
      link: () => {
        // @ts-ignore
        //window['YandexTicketsDealer'].push(['getDealer', function(dealer) { dealer.open({ id: '234214', type: 'event' }) }])
      },
    },
  },
  // {
  //   id: 2,
  //   img: energyLogo,
  //   age: '7-8 лет',
  //   fileLink: '/energiya2024.pdf',
  //   block: {
  //     // description: 'Студенты направления «Энергия» в весеннем семестре 2023 года познакомятся с пятью науками: архитектурой, экономикой, зоологией, ботаникой и физической антропологией. Вместе с учёными участники попробуют себя в этих науках и найдут ответы на многие вопросы, например, как животные общаются друг с другом или зачем икота и мурашки у людей.',
  //     subPrograms: [
  //       {
  //         semesterTicketLink: 'https://tickets.polymus.ru/event/398DE44B0C30B441C8987910E1E4BA0924D933E5',
  //         // subProgramName: "Купить абонемент на 11:00, 4 февраля - 3 марта ",
  //         subProgramName: "Продажи закрыты",
  //       },
  //       {
  //         semesterTicketLink: 'https://tickets.polymus.ru/event/5CF73B5CBF4A06519A3793642194E75C059507D7',
  //         // subProgramName: "Купить абонемент на 13:00, 4 февраля - 3 марта ",
  //         subProgramName: "Продажи закрыты",
  //       },
  //       {
  //         semesterTicketLink: 'https://tickets.polymus.ru/event/8AADC26E5EAD68A925BE5E3B4D3C5F8BD841D160',
  //         // subProgramName: "Купить абонемент на 15:00, 4 февраля - 3 марта",
  //         subProgramName: "Продажи закрыты",
  //       },
  //       {
  //         semesterTicketLink: 'https://tickets.polymus.ru/event/C721A1A2C6AB59141BD643B50881F851A6B02E08',
  //         subProgramName: "Продажи закрыты",
  //       },
  //       {
  //         semesterTicketLink: 'https://tickets.polymus.ru/event/2FB075A9E7BC0B47F5E19F096BC6FBD006417392',
  //         subProgramName: "Продажи закрыты",
  //       },
  //       {
  //         semesterTicketLink: 'https://tickets.polymus.ru/event/E40B1C5117C695A0A045717A8F974273C378C4D8',
  //         subProgramName: "Продажи закрыты",
  //       }
  //     ],
  //     title: 'Как это устроено?',
  //     name: 'Энергия',
  //     // fileLink: '/programma_UD-vesna-2023-4_5-5.pdf',
  //     price: '2600 руб.',
  //     link: () => {
  //       // @ts-ignore
  //       //window['YandexTicketsDealer'].push(['getDealer', function(dealer) { dealer.open({ id: '234214', type: 'event' }) }])
  //     },
  //   },
  // },
  {
    id: 3,
    img: impulsLogo,
    age: '7-9 лет',
    fileLink: '/impuls2024.pdf',
    block: {
      // description: 'В весеннем семестре 2023 года студенты направления «Импульс» будут решать новые задачи и узнают ответы на вопросы: станем ли мы киборгами? будет ли завтра дождь? что будет, если смешать горячую и холодную воду? Посвятим семестр физике, химии, метеорологии, микробиологии, юриспруденции и материаловедению.',
      subPrograms: [
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/F2B55401E1DBE59DCF8870EDAF066C2ABF3AF072',
          // subProgramName: "Купить абонемент на 11:00, 4 февраля - 3 марта ",
          subProgramName: "Купить абонемент на 11:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/CB0402422885F189A7E951DE5C172BC9F6574AFE',
          // subProgramName: "Купить абонемент на 13:00, 4 февраля - 3 марта ",
          subProgramName: "Купить абонемент на 13:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/87540898876F8550F9A432760EECD4EE52640F56',
          // subProgramName: "Купить абонемент на 15:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 15:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/511D68CFCBCF895BE4B29560B769F1826D85249F',
          subProgramName: "Купить абонемент на 11:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/5B77C7793ADBF547FCFB981ED1F6989219A0C428',
          subProgramName: "Купить абонемент на 13:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/D6EE372376EA905F61F68C0D8B439A876013F1F5',
          subProgramName: "Купить абонемент на 15:00, 3 ноября - 1 декабря",
        }
      ],
      title: 'В каком мире мы будем жить завтра?',
      price: '2600 руб.',
      // fileLink: '/programma_UD-vesna-2023-4_6-6.pdf',
      name: 'Импульс',
      link: () => {
        // @ts-ignore
        //window['YandexTicketsDealer'].push(['getDealer', function(dealer) { dealer.open({ id: '234215', type: 'event' }) }])
      },
    },
  },
  {
    id: 4,
    img: vektorLogo,
    age: '10–12 лет',
    fileLink: '/vector2024.pdf',
    block: {
      // description: 'В весеннем семестре старших студентов Университета детей ждут исследования на самые актуальные темы урбанистики, культурологии, философии, искусственного интеллекта и нейрофизиологии. Обсудим, всегда ли прогресс — это движение вперёд, какие зоны мозга активирует музыка и почему нам хочется смеяться.',
      subPrograms: [
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/BC850FEF0DB0CC662CD30235C80430B7D7AE2E6E',
          // subProgramName: "Купить абонемент на 11:00, 4 февраля - 3 марта ",
          subProgramName: "Купить абонемент на 11:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/AD5EBED800F9022DBB6966BDA18B14FAF4069CAB',
          // subProgramName: "Купить абонемент на 13:00, 4 февраля - 3 марта ",
          subProgramName: "Купить абонемент на 13:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/9E6F6EA405FD622E71A5FB06A24D495D58E33A66',
          // subProgramName: "Купить абонемент на 15:00, 4 февраля - 3 марта",
          subProgramName: "Купить абонемент на 15:00, 15 сентября - 13 октября",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/4ABD428ED3DE5A6F8F715E2719382587A9888091',
          subProgramName: "Купить абонемент на 11:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/5F29657E182632F98B7571F8677D47B201941507',
          subProgramName: "Купить абонемент на 13:00, 3 ноября - 1 декабря",
        },
        {
          semesterTicketLink: 'http://tickets.polymus.ru/event/C1C7A2B05E6F06342335E78950821BAC845EB21A',
          subProgramName: "Купить абонемент на 15:00, 3 ноября - 1 декабря",
        }
      ],
      name: 'Вектор',
      // fileLink: '/programma_UD-vesna-2023-4_7-end.pdf',
      title: 'Проектный блок',
      price: '2600 руб.',
      link: () => {
        // @ts-ignore
        //window['YandexTicketsDealer'].push(['getDealer', function(dealer) { dealer.open({ id: '234216', type: 'event' }) }])
      },
    },
  },
]
