import {Field, Form, Formik} from 'formik'
import React from 'react'
import {toast} from 'react-toastify'
import {useLessonVotesPartialUpdate} from '../../api/fetchers'
import handleError from '../../helpers/handleError'
import {VoteValues} from '../../types/formValues'
import LinkButton from '../linkButton/LinkButton'
import Modal from '../modal/Modal'

interface Props {
  onClose: () => void
  voteId: number
}

const VoteModal = ({onClose, voteId}: Props) => {
  const {mutate: sendVoteComment} = useLessonVotesPartialUpdate({
    id: `${voteId}`,
  })

  const onSubmit = (values: VoteValues) => {
    sendVoteComment(values)
      .then(() => {
        toast.success('Ваш отзыв принят!')
        onClose()
      })
      .catch((error) => {
        handleError(error)
      })
  }

  const renderForm = () => (
    <div className="vote-modal-form">
      <h4 className="vote-modal-title">Оставить отзыв</h4>
      <Formik
        initialValues={{
          comment: '',
        }}
        onSubmit={onSubmit}>
        {({values}) => (
          <Form className="form">
            <div className="form__input-wrapper">
              <span className="form__asterisk">*</span>
              <Field
                id="comment"
                name="comment"
                component="textarea"
                placeholder="Текст отзыва"
                className="form__input form__input--textarea"
              />
            </div>
            <LinkButton
              disabled={!values.comment}
              isSubmit
              isBtn
              content="Отправить"
            />
          </Form>
        )}
      </Formik>
    </div>
  )

  return <Modal onClose={onClose}>{renderForm()}</Modal>
}
export default VoteModal
