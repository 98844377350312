import {Field, Form, Formik} from 'formik'
import queryString from 'query-string'
import React, {useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {useUsersEduPasswordResetConfirm} from '../../api/fetchers'
import Footer from '../../components/footer/Footer'
import LinkButton from '../../components/linkButton/LinkButton'
import {ResetPasswordFields} from '../../contentData/formFieldsData'
import handleError from '../../helpers/handleError'
import logo from '../../images/header/logo.png'
import {ResetPasswordValues} from '../../types/formValues'

const PasswordChange = () => {
  const [isSuccess, setSuccess] = useState(false)

  const {uid, token} = queryString.parse(useLocation().search)

  const {mutate: changePassword, loading} = useUsersEduPasswordResetConfirm({})

  const onSubmit = (values: ResetPasswordValues) => {
    if (loading) return

    changePassword({
      new_password1: values.new_password1,
      new_password2: values.new_password2,
      uid: `${uid}`,
      token: `${token}`,
    })
      .then(() => {
        setSuccess(true)
      })
      .catch((error) => {
        handleError(error)
      })
  }

  const validationSchema = Yup.object().shape({
    new_password1: Yup.string()
      .min(8, 'Минимум 8 символов')
      .matches(/(?!^\d+$)^.+$/, 'Пароль не должен состоять только из цифр')
      .required('Введите пароль'),
    new_password2: Yup.string()
      .required('Повторите пароль')
      .oneOf([Yup.ref('new_password1')], 'Пароли не сопадают'),
  })

  const renderForm = () => (
    <Formik
      initialValues={{
        new_password1: '',
        new_password2: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({errors, touched}) => (
        <Form className="form">
          {ResetPasswordFields.map((field) => (
            <div key={field.name} className="form__input-wrapper">
              <span className="form__asterisk">*</span>
              <Field
                className={`form__input ${
                  errors[field.name as keyof ResetPasswordValues] &&
                  touched[field.name as keyof ResetPasswordValues]
                    ? 'form__input--error'
                    : ''
                }`}
                id={field.name}
                name={field.name}
                type="password"
                placeholder={field.placeholder}
              />
              {errors[field.name as keyof ResetPasswordValues] &&
                touched[field.name as keyof ResetPasswordValues] && (
                  <span className="form__error">
                    {errors[field.name as keyof ResetPasswordValues]}
                  </span>
                )}
            </div>
          ))}
          <LinkButton isSubmit isBtn content="Сохранить" loading={loading} />
        </Form>
      )}
    </Formik>
  )

  const renderSuccessMessage = () => (
    <div className="auth__success">
      <p className="auth__success-text">Ваш пароль успешно изменён.</p>
      <LinkButton href="/login" content="Войти в личный кабинет" />
    </div>
  )

  return (
    <>
      <div className="auth">
        <NavLink to="/">
          <img className="auth__logo" src={logo} alt="logo" />
        </NavLink>
        <h3 className="auth__title">Восстановление пароля</h3>
        {isSuccess ? renderSuccessMessage() : renderForm()}
      </div>
      <Footer />
    </>
  )
}

export default PasswordChange
