import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

const ScreenThree = () => {
  return (
    <ScrollableAnchor id={'education'}>
      <section className="container container--three">
        <h2 className="screen-three-title">
          Как устроено <br />
          обучение?
        </h2>
        <div className="screen-three-info">
          <div className="screen-three-info__item">
            <h2 className="screen-three-info__num">1</h2>
            <p className="screen-three-info__text">
              Дети получают представление о разных науках и понимают взаимосвязь
              между ними.
            </p>
          </div>
          <div className="screen-three-info__item">
            <h2 className="screen-three-info__num">2</h2>
            <p className="screen-three-info__text">
              Каждое занятие — исследование. <br />
              Дети ставят вопросы, выдвигают гипотезы, анализируют полученные
              результаты.
            </p>
          </div>
          <div className="screen-three-info__item">
            <h2 className="screen-three-info__num">3</h2>
            <p className="screen-three-info__text">
			  Всё по-настоящему — обсуждения, эксперименты, создание проектов.
            </p>
          </div>
          <div className="screen-three-info__item">
            <h2 className="screen-three-info__num">4</h2>
            <p className="screen-three-info__text">
              Занятия проводятся на разных площадках города. 
            </p>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  )
}

export default ScreenThree
