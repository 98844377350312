import React, {useContext} from 'react'
import {Redirect, Route, RouteProps, Switch} from 'react-router-dom'
import ForbiddenMessage from './components/forbiddenMessage/ForbiddenMessage'
import {WrapperContext} from './context/wrapper/wrapperContext'
import Announcements from './pages/announcement/Announcements'
import EditProfile from './pages/editProfile/EditProfile'
import Error from './pages/error/Error'
import Lesson from './pages/lesson/Lesson'
import Login from './pages/login/Login'
import Main from './pages/main/Main'
import PasswordChange from './pages/passwordChange/PasswordChange'
import Profile from './pages/profile/Profile'
import Schedule from './pages/schedule/Schedule'
import TutorLesson from './pages/tutorLesson/TutorLesson'
import TutorProfile from './pages/tutorProfile/TutorProfile'
import TutorSchedule from './pages/tutorSchedule/TutorSchedule'

export const useRoutes = () => {
  const {token, user, error} = useContext(WrapperContext)

  const PrivateRoute: React.FC<RouteProps> = ({children, ...rest}) => {
    const auth = !!token.access

    return (
      <Route
        {...rest}
        render={() => {
          return auth ? children : <Redirect to="/login" />
        }}
      />
    )
  }

  if (error) {
    return (
      <Switch>
        <Route path="/error" exact>
          <Error />
        </Route>
        <Redirect to="/error" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/" exact>
        <Main />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/reset-confirm" exact>
        <PasswordChange />
      </Route>
      <PrivateRoute path="/profile/announcements" exact>
        <Announcements />
      </PrivateRoute>
      <PrivateRoute path="/profile/edit" exact>
        {!user.tutor_data ? <EditProfile /> : <ForbiddenMessage />}
      </PrivateRoute>
      <PrivateRoute path="/profile/schedule" exact>
        {!user.tutor_data ? <Schedule /> : <ForbiddenMessage />}
      </PrivateRoute>
      <PrivateRoute path="/profile/lesson/:blockId/:lessonId" exact>
        <Lesson />
      </PrivateRoute>
      <PrivateRoute path="/profile/tutor-schedule" exact>
        <TutorSchedule />
      </PrivateRoute>
      <PrivateRoute path="/profile/tutor-edit" exact>
        {user.tutor_data ? <EditProfile isTutor /> : <ForbiddenMessage />}
      </PrivateRoute>
      <PrivateRoute path="/profile/tutor-lesson/:lessonId" exact>
        <TutorLesson />
      </PrivateRoute>
      <PrivateRoute exact path="/profile">
        {user.tutor_data ? <TutorProfile /> : <Profile />}
      </PrivateRoute>
      <Route path="*" exact>
        <Error isNotFound />
      </Route>
    </Switch>
  )
}
