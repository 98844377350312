import React, {useState} from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import arrowDown from '../../../images/arrow-down.svg'
import questionsData from './questionsData'
import starBg from '../../../images/bg-images/star-bg.png'

const ScreenEight = () => {
  const [activeQuestions, setActiveQuestions] = useState<number[]>([])

  const handleQuestionToggle = (id: number) => {
    if (activeQuestions.includes(id)) {
      setActiveQuestions(activeQuestions.filter((item) => item !== id))
    } else {
      setActiveQuestions([...activeQuestions, id])
    }
  }

  return (
    <ScrollableAnchor id={'faq'}>
      <section className="screen-eight">
        <div className="screen-eight-content">
          <h2 className="screen-eight-title">FAQ</h2>
          {questionsData.map((item) => (
            <div
              onClick={() => handleQuestionToggle(item.id)}
              key={item.id}
              className={`screen-eight-question ${
                activeQuestions.includes(item.id)
                  ? 'screen-eight-question--active'
                  : ''
              }`}>
              <div
                onClick={() => handleQuestionToggle(item.id)}
                className="screen-eight-question__header">
                <p className="screen-eight-question__title">{item.question}</p>
                <img
                  className="screen-eight-question__toggle"
                  src={arrowDown}
                  alt="arrow-down"
                />
              </div>
              <p className='screen-eight-question__answer'
                dangerouslySetInnerHTML={{__html: item.answer}}
              />
            </div>
          ))}
        </div>
        <img src={starBg} alt="star bg" className="screen-eight-star-bg" />
      </section>
    </ScrollableAnchor>
  )
}

export default ScreenEight
