import React, {useContext} from 'react'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import {WrapperContext} from '../../context/wrapper/wrapperContext'

const Profile = () => {
  const {user} = useContext(WrapperContext)

  const renderProfileData = () => {
    const profileData = [
      {
        value: 'ФИО',
        content:
          `${user.last_name} ${user.first_name} ${user.patronymic || ''}` ||
          '-',
      },
      {
        value: 'E-mail',
        content: user.email,
      },
      {
        value: 'Телефон',
        content: user.phone,
      },
    ]

    return (
      <div className="profile-data">
        <p className="profile-data__title">Родители</p>
        <div className="profile-data__content">
          <div className="profile-data__count">1</div>
          <div className="profile-data__user-info">
            {profileData.map((item) => (
              <div key={item.value} className="profile-data__user-text">
                <p className="profile-data__user-text-value">{item.value}</p>
                <p className="profile-data__user-text-content">
                  {item.content && item.content.trim() ? item.content : '-'}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <ProfileWrapper>
      <ProfileTabs />
      <a
        className="profile__program-link"
        href="/program.pdf"
        target="_blank"
        rel="noopener noreferrer">
        Полное расписание (.pdf)
      </a>
      {renderProfileData()}
    </ProfileWrapper>
  )
}

export default Profile
