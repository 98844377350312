import {Field, Form, Formik} from 'formik'
import React, {useContext} from 'react'
import {EditProfileFields} from '../../contentData/formFieldsData'
import {WrapperContext} from '../../context/wrapper/wrapperContext'
import {ReactComponent as Loader} from '../../images/loader.svg'
import {EditProfileValues} from '../../types/formValues'

interface Props {
  onSubmit: (values: EditProfileValues) => void
  onReset: () => void
  loading: boolean
}

const EditProfileForm = ({onSubmit, onReset, loading}: Props) => {
  const {user} = useContext(WrapperContext)

  return (
    <Formik
      initialValues={{
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        patronymic: user.patronymic || '',
        phone: user.phone || '',
        email: user.email || '',
      }}
      onSubmit={onSubmit}>
      <Form className="form">
        {EditProfileFields.map((field) => (
          <div key={field.name} className="form__input-wrapper">
            <Field
              className="form__input"
              id={field.name}
              name={field.name}
              placeholder={field.placeholder}
              type={field.type ? 'type' : 'text'}
            />
          </div>
        ))}
        <div className="edit-profile__buttons">
          <button
            type="submit"
            className="edit-profile__btn edit-profile__btn--submit">
            {loading ? <Loader /> : 'Сохранить'}
          </button>
          <button
            onClick={onReset}
            type="button"
            className="edit-profile__btn edit-profile__btn--reset">
            Отмена
          </button>
        </div>
      </Form>
    </Formik>
  )
}

export default EditProfileForm
