import React from 'react'
import {ReactComponent as Loader} from '../../images/loader.svg'

interface Props {
  content?: string
  time?: string
  subProgramName?: string
  option?: string
  href?: string
  isNewTab?: boolean
  isBtn?: boolean
  disabled?: boolean
  isSubmit?: boolean
  loading?: boolean
  onClick?: () => void
}

const LinkButton = ({
  content,
  time,
  option,
  subProgramName,
  href,
  isNewTab = false,
  isBtn,
  disabled = false,
  isSubmit = false,
  loading = false,
  onClick,
}: Props) => {
  return (
    <>
      {isBtn ? (
        <button
          disabled={disabled}
          type={isSubmit ? 'submit' : 'button'}
          className="link-button"
          onClick={onClick}>
          {loading ? (
            <Loader />
          ) : (
            <span className="link-button__content">{content}</span>
          )}
        </button>
      ) : (
        <a
          className="link-button"
          href={href}
          target={isNewTab ? '_blank' : '_self'}>
          <span className="link-button__content">{content}{time}</span>
          <span className="link-button__content">{subProgramName}</span>
          <p className="link-button__option">{option}</p>
        </a>
      )}
    </>
  )
}

export default LinkButton
