import jwtDecode from 'jwt-decode'
import React, {useEffect, useReducer, useState} from 'react'
import {RestfulProvider} from 'restful-react'
import {
  AuthTokenRefreshCreateResponse,
  EduUser,
  useAuthTokenRefreshCreate,
  useUsersEduProfile,
} from '../../api/fetchers'
import {HANDLER_SUBSCRIBE_MODAL, SET_TOKEN, SET_USER} from '../types'
import {initialState, WrapperContext} from './wrapperContext'
import {wrapperReducer} from './wrapperReducer'

export const WrapperState: React.FC = ({children}) => {
  const [state, dispatch] = useReducer(wrapperReducer, initialState)
  const [error, setError] = useState(false)
  const [ready, setReady] = useState(false)

  const {refetch: getUser, data: user} = useUsersEduProfile({
    base: process.env.REACT_APP_API_URL,
    lazy: true,
    requestOptions: {
      headers: {
        Authorization: `Bearer ${state.token.access}`,
      },
    },
  })

  const {mutate: refreshToken} = useAuthTokenRefreshCreate({
    base: process.env.REACT_APP_API_URL || '',
  })

  const setUser = (user: EduUser) => dispatch({type: SET_USER, payload: user})

  const setToken = (token: AuthTokenRefreshCreateResponse) =>
    dispatch({type: SET_TOKEN, payload: token})

  const handleSubscribePopap = (boolean: boolean) =>
    dispatch({type: HANDLER_SUBSCRIBE_MODAL, payload: boolean})

  const options = {
    base: process.env.REACT_APP_API_URL || '',
    requestOptions: {},
    onResponse: ({status}: any) => {
      if (status === 500) {
        setError(true)
      }
    },
  }

  if (state.token.access) {
    options.requestOptions = {
      headers: {Authorization: `Bearer ${state.token.access}`},
    }
  }

  if (user && Object.keys(state.user).length === 0) {
    setUser(user)
  }
  useEffect(() => {
    if (state.token.refresh) {
      const decodedToken: {exp: number} = jwtDecode(state.token.access!)
      const decodedTokenRefresh: {exp: number} = jwtDecode(state.token.refresh!)

      if (decodedTokenRefresh.exp * 1000 > Date.now()) {
        if (decodedToken.exp * 1000 < Date.now()) {
          refreshToken({refresh: state.token.refresh!})
            .then((token) => {
              localStorage.setItem(
                'userData',
                JSON.stringify({
                  token,
                }),
              )
              options.requestOptions = {
                headers: {Authorization: `Bearer ${token.access}`},
              }
              setToken(token)
            })
            .then(() => {
              getUser()
              if (!user) window.location.reload()
              setReady(true)
            })
            .catch(() => {
              setToken({
                access: '',
                refresh: '',
              })
              localStorage.removeItem('userData')
              setReady(true)
            })
        } else {
          getUser()
          setReady(true)
        }
      } else {
        setToken({
          access: '',
          refresh: '',
        })
        localStorage.removeItem('userData')
        setReady(true)
      }
    } else {
      setReady(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!ready) {
    return null
  }

  return (
    <WrapperContext.Provider
      value={{
        ...initialState,
        setUser,
        user: state.user,
        token: state.token,
        showSubscribeModal: state.showSubscribeModal,
        setToken,
        handleSubscribePopap,
        dispatch,
        ready,
        error,
      }}>
      <RestfulProvider {...options}>{children}</RestfulProvider>
    </WrapperContext.Provider>
  )
}
