/* Generated by restful-react */

import React from 'react'
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps,
} from 'restful-react'

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

const encodingFn = encodeURIComponent

const encodingTagFactory = (encodingFn: typeof encodeURIComponent) => (
  strings: TemplateStringsArray,
  ...params: (string | number | boolean)[]
) =>
  strings.reduce(
    (accumulatedPath, pathPart, idx) =>
      `${accumulatedPath}${pathPart}${
        idx < params.length ? encodingFn(params[idx]) : ''
      }`,
    '',
  )

const encode = encodingTagFactory(encodingFn)

export interface AnonymousFormSubscription {
  id?: number
  name?: string | null
  last_name?: string | null
  email: string
  phone?: string | null
  city?: string | null
  birth_date?: string | null
  subscribes: ('ALL' | 'EVENTS' | 'EDUCATION' | 'KIDS')[]
}

export interface EduTokenObtain {
  username: string
  password: string
}

export interface LDAPTokenObtain {
  username: string
  password: string
}

export interface CommonTokenObtain {
  username: string
  password: string
}

export interface TokenRefresh {
  refresh: string
}

export interface TokenVerify {
  token: string
}

export interface City {
  url?: string
  name: string
  name_ascii?: string
  slug?: string
  geoname_id?: number | null
  alternate_names?: string | null
  display_name: string
  latitude?: string | null
  longitude?: string | null
  population?: number | null
  feature_code?: string | null
  timezone?: string | null
  subregion?: string | null
  country: string
}

export interface ContactCategory {
  id?: number
  url?: string
  title: string
  title_en?: string | null
  description?: string | null
}

export interface ContactPhoto {
  id?: number
  photo?: string
}

export interface ContactCategoryNested {
  id: number
  title?: string
}

/**
 * Contact phones
 */
export interface PhoneData {
  phone: string | null
  assistant_phone: string | null
  work_phone: string | null
}

export interface ContactsList {
  id?: number
  last_name?: string | null
  first_name?: string | null
  photo?: ContactPhoto
  organization?: string | null
  position?: string | null
  categories: ContactCategoryNested[]
  /**
   * Contact emails
   */
  emails_display?: string[]
  phones_data?: PhoneData
}

export interface ContactEmailNested {
  id?: number
  email: string
  sendsay_member?: boolean
  contact?: number
}

export interface ContactPost {
  id?: number
  categories: number[]
  emails: ContactEmailNested[]
  first_name: string
  last_name: string
  photo?: number | null
  /**
   * Дата в формате MMDD
   */
  birth_day?: string | null
  patronymic?: string | null
  organization?: string | null
  position?: string | null
  relation?: string | null
  birth_year?: number | null
  assistant?: string | null
  assistant_phone?: string | null
  phone?: string | null
  work_phone?: string | null
  skype?: string | null
  site_url?: string | null
  address?: string | null
  metro?: string | null
  important_notice?: string | null
  whose_contact_fallback?: string | null
  other_info?: string | null
  license_plate_numbers?: string | null
  is_active?: boolean
  restricted_access?: boolean
}

export interface Contact {
  id?: number
  last_name?: string | null
  first_name?: string | null
  patronymic?: string | null
  categories: ContactCategoryNested[]
  organization?: string | null
  position?: string | null
  relation?: string | null
  /**
   * Дата в формате MMDD
   */
  birth_day?: string | null
  birth_year?: number | null
  assistant?: string | null
  skype?: string | null
  site_url?: string | null
  address?: string | null
  metro?: string | null
  is_active?: boolean
  important_notice?: string | null
  photo?: ContactPhoto
  whose_contact_fallback?: string | null
  other_info?: string | null
  license_plate_numbers?: string | null
  /**
   * True if current user has access to all attributes.
   */
  has_full_access?: boolean
  /**
   * Contact emails
   */
  emails_display?: string[]
  phones_data?: PhoneData
  restricted_access?: boolean
}

export interface ContactUpdate {
  id?: number
  categories: number[]
  emails: ContactEmailNested[]
  first_name: string
  last_name: string
  photo?: number | null
  /**
   * Дата в формате MMDD
   */
  birth_day?: string | null
  patronymic?: string | null
  organization?: string | null
  position?: string | null
  relation?: string | null
  birth_year?: number | null
  assistant?: string | null
  assistant_phone?: string | null
  phone?: string | null
  work_phone?: string | null
  skype?: string | null
  site_url?: string | null
  address?: string | null
  metro?: string | null
  important_notice?: string | null
  whose_contact_fallback?: string | null
  other_info?: string | null
  license_plate_numbers?: string | null
  is_active?: boolean
  restricted_access?: boolean
}

export interface ContactsGroupList {
  id?: number
  title: string
  description?: string | null
  created_at?: string
  /**
   * Amount of group members
   */
  size?: number
}

export interface SegmentQueryAttrs {
  categories: number[]
  include_users: number[]
  exclude_users: number[]
  organizations: string[]
}

export interface ContactsGroupCreate {
  title: string
  description?: string | null
  query_attrs: SegmentQueryAttrs
}

export interface ContactsGroup {
  id?: number
  url?: string
  title: string
  description?: string | null
  created_at?: string
  query_attrs: SegmentQueryAttrs
  /**
   * Categories names
   */
  categories_names?: string[]
}

export interface ContactCompact {
  id?: number
  first_name?: string | null
  last_name?: string | null
  organization?: string | null
  /**
   * Contact emails
   */
  emails_display?: string[]
  photo?: ContactPhoto
}

export interface ContactsGroupUpdate {
  title: string
  description?: string | null
  query_attrs: SegmentQueryAttrs
  id: number
}

export interface Country {
  url?: string
  name: string
  name_ascii?: string
  slug?: string
  geoname_id?: number | null
  alternate_names?: string | null
  code2?: string | null
  code3?: string | null
  continent: 'OC' | 'EU' | 'AF' | 'NA' | 'AN' | 'SA' | 'AS'
  tld?: string
  phone?: string | null
}

export interface Department {
  id: number
  title: string
}

export interface Disability {
  url?: string
  title: string
  title_en: string
  description?: string | null
}

export interface Announcement {
  id?: number
  topic: string
  message: string
  publish_date: string
}

export interface EmailTemplateList {
  url?: string
  id?: number
  title: string
  protected?: boolean
}

export interface EmailTemplate {
  url?: string
  protected?: boolean
  id?: number
  title: string
  body: string
  json_content?: {[key: string]: any}
}

export interface EmailingResult {
  id?: number
  url?: string
  created_at?: string
  finished_at?: string | null
  testing?: boolean
  errors?: {[key: string]: any}
  /**
   * Status in human-readable format
   */
  status?: string
  sendsay_status?: number | null
}

export interface EmailingSubscriber {
  email: string
}

export interface Emailing {
  id?: number
  group: number
  author: number
  created_at?: string
  test_emails: string[]
  subject: string
  template: number
}

export interface EmailingResultCompact {
  id?: number
  url?: string
  testing?: boolean
}

export interface EmailingRetrieve {
  url?: string
  results: EmailingResultCompact[]
  created_at?: string
  test_emails: string[]
  subject: string
  template: string
  author?: string | null
  group?: string | null
}

export interface EventCategory {
  id: number
  title: string
}

export interface LocationCompact {
  id: number
  title: string
}

export interface EventList {
  id: number
  title: string
  start_day: string
  start_time?: string | null
  end_day?: string | null
  end_time?: string | null
  category: EventCategory
  location: LocationCompact
}

export interface TicketCategoryNested {
  ticket_type: number
  price?: number
}

export interface EventTimeslotCreate {
  start_at: string
}

export interface EventWeekdayTimeslotCreate {
  start_time: string
  weekday: number
}

export interface EventPost {
  id?: number
  category: number
  location: number
  preferential_categories: number | null[]
  ticket_categories: TicketCategoryNested[]
  timeslots: EventTimeslotCreate[]
  weekday_timeslots: EventWeekdayTimeslotCreate[]
  title: string
  occurrence?: 'regular' | 'irregular'
  description?: string | null
  is_government_order?: boolean
  min_age_limit: number
  email: string
  phone: string
  start_day: string
  start_time?: string | null
  end_day?: string | null
  end_time?: string | null
  created_at?: string
  capacity?: number | null
  partners_capacity?: number | null
  is_free?: boolean
}

export interface EventRegister {
  ticket_category: number
  event_day: string
  event_time: string
  email: string
  first_name: string
  last_name: string
}

export interface EventTimeslot {
  id: number
  start_at: string
}

export interface EventWeekdayTimeslot {
  id: number
  start_time: string
  weekday: number
}

export interface TicketType {
  id: number
  title: string
}

export interface TicketCategory {
  id: number
  ticket_type: TicketType
  price?: number
}

export interface PreferentialCategory {
  id: number
  title: string
}

export interface EventDetails {
  id: number
  category: EventCategory
  location: LocationCompact
  timeslots?: EventTimeslot[]
  weekday_timeslots?: EventWeekdayTimeslot[]
  ticket_categories?: TicketCategory[]
  preferential_categories?: PreferentialCategory[]
  title: string
  occurrence?: 'regular' | 'irregular'
  description?: string | null
  is_government_order?: boolean
  min_age_limit: number
  email: string
  phone: string
  start_day: string
  start_time?: string | null
  end_day?: string | null
  end_time?: string | null
  created_at?: string
  is_active?: boolean
  capacity?: number | null
  partners_capacity?: number | null
  is_free?: boolean
}

export interface EventTimeslotNested {
  id?: number
  start_at: string
}

export interface EventWeekdayTimeslotNested {
  id?: number
  start_time: string
  weekday: number
}

export interface EventUpdate {
  id?: number
  category: number
  location: number
  preferential_categories: number | null[]
  ticket_categories: TicketCategoryNested[]
  timeslots: EventTimeslotNested[]
  weekday_timeslots: EventWeekdayTimeslotNested[]
  title: string
  occurrence?: 'regular' | 'irregular'
  description?: string | null
  is_government_order?: boolean
  min_age_limit: number
  email: string
  phone: string
  start_day: string
  start_time?: string | null
  end_day?: string | null
  end_time?: string | null
  created_at?: string
  capacity?: number | null
  partners_capacity?: number | null
  is_free?: boolean
}

export interface RegistrationTimeslots {
  time: string
  capacity: number
}

export interface RegistrationIntervals {
  date: string
  timeslots?: RegistrationTimeslots[]
}

/**
 * Event intervals.
 */
export interface RegistrationTimetable {
  calendar: RegistrationIntervals[]
  next_chunk_start?: string
}

export interface EventRegistrationForm {
  id: number
  title: string
  category: EventCategory
  start_day: string
  utc_start_time?: string
  end_day?: string | null
  end_time?: string | null
  intervals?: RegistrationTimetable
  /**
   * Ticket category for registration.
   */
  ticket_category?: number
}

export interface GrantConditions {
  url?: string
  id?: number
  grant: number
  upload?: string
}

export interface GrantDocuments {
  url?: string
  id?: number
  grant: number
  upload?: string
}

export interface CompactUser {
  url?: string
  id: number
  first_name?: string
  last_name?: string
}

export interface GrantsList {
  id?: number
  url?: string
  title: string
  requests_opening_date: string
  requests_closing_date: string
  amount: number
  currency?: 'rur' | 'usd' | 'eur'
  performer: CompactUser
  status?:
    | 'discussion'
    | 'preparation'
    | 'request_sending'
    | 'waiting'
    | 'approved'
    | 'denied'
}

export interface GrantPost {
  url?: string
  id?: number
  performer: CompactUser
  department: Department
  title: string
  organizer: string
  link?: string | null
  requests_opening_date: string
  requests_closing_date: string
  amount: number
  currency?: 'rur' | 'usd' | 'eur'
  conditions: string
  status?:
    | 'discussion'
    | 'preparation'
    | 'request_sending'
    | 'waiting'
    | 'approved'
    | 'denied'
}

export interface GrantConditionsNested {
  url?: string
  id?: number
  upload?: string
}

export interface GrantDocumentsNested {
  url?: string
  id?: number
  upload?: string
}

export interface GrantDetails {
  url?: string
  id: number
  performer: CompactUser
  department: Department
  conditions_files: GrantConditionsNested[]
  documents: GrantDocumentsNested[]
  title: string
  organizer: string
  link?: string | null
  requests_opening_date: string
  requests_closing_date: string
  amount: number
  currency?: 'rur' | 'usd' | 'eur'
  conditions: string
  status?:
    | 'discussion'
    | 'preparation'
    | 'request_sending'
    | 'waiting'
    | 'approved'
    | 'denied'
}

export interface Vote {
  id?: number
  track_access: number
  criterion: number
  lesson: number
  comment?: string
}

export interface VoteUpdate {
  comment?: string | null
}

export interface LocationSchedule {
  title: string
  opening_time?: string | null
  closing_time?: string | null
}

export interface ZoneCompact {
  id: number
  title: string
}

export interface LocationList {
  id: number
  title: string
  address: string
  email: string
  phone: string
  intervals: LocationSchedule[]
  zone: ZoneCompact
}

export interface LocationWritable {
  id?: number
  intervals: LocationSchedule[]
  zone: number
  title: string
  description?: string | null
  address: string
  email: string
  phone: string
}

export interface Location {
  id: number
  intervals: LocationSchedule[]
  zone: ZoneCompact
  title: string
  description?: string | null
  address: string
  email: string
  phone: string
}

export interface MetricValue {
  id?: number
  day: string
  created?: string
  amount: number
  counter: number
  metric: number
}

export interface MetroStation {
  title: string
  line: string
}

export interface Organizations {
  organization: string
}

export interface Answer {
  id?: number
  text: string
}

export interface QuizQuestion {
  id?: number
  text: string
  is_double?: boolean
  answers?: Answer[]
}

export interface Quiz {
  id?: number
  title: string
  questions?: QuizQuestion[]
}

export interface QuizResultNested {
  question_id: number
  answer_id: number
}

export interface QuizPrepareResult {
  quiz_id: number
  records: QuizResultNested[]
}

export interface QuizTranscript {
  title: string
  description: string
}

export interface Referrer {
  url?: string | null
  title: string
}

export interface SendSaySubscribersAmount {
  id?: number
  day: string
  created?: string
  amount: number
  diff: number
}

export interface SendSaySubscribers {
  today: SendSaySubscribersAmount
  week_diff: number
  week: SendSaySubscribersAmount[]
}

export interface SocialSubscribersAmount {
  id?: number
  day: string
  social: 'fb' | 'youtube' | 'vk' | 'ok' | 'instagram'
  created?: string
  amount: number
  diff: number
}

export interface Track {
  id?: number
  title: string
  /**
   * True if semester is active.
   */
  is_active?: boolean
}

export interface ScheduledLessonTimeslot {
  id?: number
  start_at: string
}

export interface LessonNested {
  id: number
  title: string
  state:
    | 'assigned'
    | 'not_assigned'
    | 'passed'
    | 'need_purchase'
    | 'not_available'
  address: string
  purchase_widget_url?: string | null
  timeslots?: ScheduledLessonTimeslot[]
}

export interface BlockNested {
  title: string
  logo?: string | null
  icon?: string | null
  notice?: string | null
  kind: 'basic' | 'exhibition' | 'bonus' | 'outside'
  lessons: LessonNested[]
}

export interface TrackAccess {
  id?: number
  track: Track
  /**
   * Track blocks
   */
  blocks?: BlockNested[]
}

export interface TutorPhotoNested {
  id?: number
  photo?: string
}

export interface TutorNested {
  id?: number
  first_name: string
  last_name: string
  description: string
  photo_img?: TutorPhotoNested
}

export interface LessonAttachment {
  id?: number
  title: string
  upload?: string
}

export interface LessonRecord {
  id?: number
  title: string
  upload?: string
}

export interface ScheduledLessonNested {
  conference_url?: string | null
  conference_id?: string | null
  conference_access_code?: string | null
  /**
   * Scheduled lesson timeslots.
   */
  ordered_timeslots?: ScheduledLessonTimeslot[]
}

/**
 * User assignment for current lesson.
 */
export interface LessonAssignmentNested {
  id: number
  scheduled_lesson: ScheduledLessonNested
}

export interface Criteria {
  id?: number
  title: string
  image?: string | null
  /**
   * Total sum of vote for criteria.
   */
  total?: number
}

export interface Lesson {
  id?: number
  kind?: 'basic' | 'exhibition' | 'bonus' | 'outside'
  /**
   * Block title.
   */
  block_title?: string
  /**
   * Block logo.
   */
  track_logo?: string | null
  title: string
  /**
   * Lesson state for current user.
   */
  state?:
    | 'assigned'
    | 'not_assigned'
    | 'passed'
    | 'need_purchase'
    | 'not_available'
  description: string
  image?: string | null
  /**
   * адрес для выездных занятий
   */
  address?: string | null
  tutors?: TutorNested[]
  attachments?: LessonAttachment[]
  records?: LessonRecord[]
  assignment?: LessonAssignmentNested
  /**
   * Vote criterias with amount of votes.
   */
  total_votes?: Criteria[]
  user_vote?: Vote
}

export interface ScheduledLesson {
  id?: number
  timeslots: ScheduledLessonTimeslot[]
  /**
   * Amount of available tickets.
   */
  available_amount?: number
}

export interface LessonAssignmentPost {
  scheduled_lesson: number
}

export interface ScheduledLessonCompact {
  timeslots: ScheduledLessonTimeslot[]
  /**
   * Lesson title.
   */
  lesson_title?: string
}

export interface LessonAssignment {
  scheduled_lesson: ScheduledLessonCompact
}

export interface TutorPhoto {
  id?: number
  photo?: string
}

export interface ScheduledLessonTimeslotNested {
  id: number
  start_at: string
  /**
   * Lesson title
   */
  title?: string
}

export interface TutorSchedule {
  day: string
  slots: ScheduledLessonTimeslotNested[]
}

/**
 * Lesson info
 */
export interface TutorLessonSerialzier {
  id?: number
  /**
   * Block title.
   */
  block_title?: string
  /**
   * Block logo.
   */
  track_logo?: string | null
  title: string
}

export interface AssignedUser {
  id?: number
  first_name?: string
  last_name?: string
  patronymic?: string
}

export interface TutorTimeslot {
  id?: number
  start_at: string
  lesson_data?: TutorLessonSerialzier
  /**
   * Assignments info
   */
  assignments?: AssignedUser[]
}

export interface TutorUpdate {
  status_in?: string | null
  status_out?: string | null
  photo_img?: number | null
  phone?: string | null
  facebook?: string | null
  telegram?: string | null
}

export interface TutorProfile {
  id?: number
  first_name: string
  last_name: string
  description: string
  photo_img?: TutorPhotoNested
  status_in?: string | null
  status_out?: string | null
  phone?: string | null
  facebook?: string | null
  telegram?: string | null
}

export interface UserAvatar {
  id?: number
  image?: {
    thumbnail: string
    full_size: string
  }
}

export interface UserSegment {
  id?: number
  title: string
  description?: string | null
  query: string
}

export interface SubscriptionNested {
  user?: number
  mail_category: 'news' | 'events'
}

export interface User {
  url?: string
  id?: number
  subscriptions?: SubscriptionNested[]
  avatar?: UserAvatar
  last_login?: string | null
  /**
   * Указывает, что пользователь имеет все права без явного их назначения.
   */
  is_superuser?: boolean
  first_name?: string
  last_name?: string
  /**
   * Отметьте, если пользователь может входить в административную часть сайта.
   */
  is_staff?: boolean
  /**
   * Отметьте, если пользователь должен считаться активным. Уберите эту отметку вместо удаления учётной записи.
   */
  is_active?: boolean
  date_joined?: string
  username?: string | null
  email?: string | null
  phone?: string | null
  email_confirmed?: boolean
  phone_confirmed?: boolean
  birthday?: string | null
  library_card?: string | null
  gender?: 'male' | 'female' | 'unknown'
  is_employee?: boolean
  is_edu_member?: boolean
  has_full_contacts_access?: boolean
  patronymic?: string
  notify_edu_announcement?: boolean
  nofity_edu_lesson?: boolean
  city?: string | null
  country?: string | null
  disability?: string | null
  referrer?: string | null
  content_interests?: string[]
}

export interface EduPasswordResetConfirm {
  uid: string
  token: string
  new_password1: string
  new_password2: string
}

export interface EduUser {
  id?: number
  first_name?: string
  last_name?: string
  patronymic?: string
  email?: string | null
  phone?: string | null
  notify_edu_announcement?: boolean
  nofity_edu_lesson?: boolean
  tutor_data?: TutorProfile
}

export interface PasswordResetConfirm {
  uid: string
  token: string
  new_password1: string
  new_password2: string
}

export interface UserUpdate {
  first_name?: string
  last_name?: string
  patronymic?: string
  gender?: 'male' | 'female' | 'unknown'
  birthday?: string | null
  email?: string | null
  phone?: string | null
  subscriptions?: SubscriptionNested[]
  notify_edu_announcement?: boolean
  nofity_edu_lesson?: boolean
  avatar?: number | null
}

export interface Visits {
  day: number | null
  week: number | null
}

export interface YandexCounter {
  id: number
  site: string
  visits: Visits
  auth_visits: Visits
  guest_visits: Visits
}

export interface YandexMetric {
  id: number
  title: string
  metric: string
  query_filter?: string | null
}

export interface ZoneSchedule {
  title: string
  opening_time?: string | null
  closing_time?: string | null
}

export interface ZoneList {
  id: number
  title: string
  address: string
  email: string
  phone: string
  intervals: ZoneSchedule[]
}

export interface ZoneWritable {
  id?: number
  intervals: ZoneSchedule[]
  title: string
  description?: string | null
  address: string
  email: string
  phone: string
}

export interface Zone {
  id: number
  intervals: ZoneSchedule[]
  title: string
  description?: string | null
  address: string
  email: string
  phone: string
}

export type UserUpdateRequestBody = UserUpdate

export type EventUpdateRequestBody = EventUpdate

export type GrantPostRequestBody = GrantPost

export type ContactsGroupUpdateRequestBody = ContactsGroupUpdate

export type DisabilityRequestBody = Disability

export type UserSegmentRequestBody = UserSegment

export type VoteUpdateRequestBody = VoteUpdate

export type EmailTemplateRequestBody = EmailTemplate

export type TutorUpdateRequestBody = TutorUpdate

export interface UsersEduPasswordResetDataRequestBody {
  email: string
}

export type CityRequestBody = City

export type ContactUpdateRequestBody = ContactUpdate

export type CountryRequestBody = Country

export type GrantConditionsCreateRequestBody = void

export type LocationWritableRequestBody = LocationWritable

export type ReferrerRequestBody = Referrer

export type ZoneWritableRequestBody = ZoneWritable

export type AnonymousSubscriptionsCreateProps = Omit<
  MutateProps<
    AnonymousFormSubscription,
    unknown,
    void,
    AnonymousFormSubscription,
    void
  >,
  'path' | 'verb'
>

/**
 * ViewSet for receiving Email subscriptions Form data from other site.
 */
export const AnonymousSubscriptionsCreate = (
  props: AnonymousSubscriptionsCreateProps,
) => (
  <Mutate<
    AnonymousFormSubscription,
    unknown,
    void,
    AnonymousFormSubscription,
    void
  >
    verb="POST"
    path={encode`/anonymous_subscriptions/`}
    {...props}
  />
)

export type UseAnonymousSubscriptionsCreateProps = Omit<
  UseMutateProps<
    AnonymousFormSubscription,
    unknown,
    void,
    AnonymousFormSubscription,
    void
  >,
  'path' | 'verb'
>

/**
 * ViewSet for receiving Email subscriptions Form data from other site.
 */
export const useAnonymousSubscriptionsCreate = (
  props: UseAnonymousSubscriptionsCreateProps,
) =>
  useMutate<
    AnonymousFormSubscription,
    unknown,
    void,
    AnonymousFormSubscription,
    void
  >('POST', encode`/anonymous_subscriptions/`, props)

export interface AuthEduTokenCreateResponse {
  access: string
  refresh: string
}

export type AuthEduTokenCreateProps = Omit<
  MutateProps<
    AuthEduTokenCreateResponse,
    {
      detail?: string
    },
    void,
    EduTokenObtain,
    void
  >,
  'path' | 'verb'
>

/**
 * Authenticate user and obtain JWT tokens
 */
export const AuthEduTokenCreate = (props: AuthEduTokenCreateProps) => (
  <Mutate<
    AuthEduTokenCreateResponse,
    {
      detail?: string
    },
    void,
    EduTokenObtain,
    void
  >
    verb="POST"
    path={encode`/auth/edu-token/`}
    {...props}
  />
)

export type UseAuthEduTokenCreateProps = Omit<
  UseMutateProps<
    AuthEduTokenCreateResponse,
    {
      detail?: string
    },
    void,
    EduTokenObtain,
    void
  >,
  'path' | 'verb'
>

/**
 * Authenticate user and obtain JWT tokens
 */
export const useAuthEduTokenCreate = (props: UseAuthEduTokenCreateProps) =>
  useMutate<
    AuthEduTokenCreateResponse,
    {
      detail?: string
    },
    void,
    EduTokenObtain,
    void
  >('POST', encode`/auth/edu-token/`, props)

export interface AuthLdapTokenCreateResponse {
  access: string
  refresh: string
}

export type AuthLdapTokenCreateProps = Omit<
  MutateProps<
    AuthLdapTokenCreateResponse,
    {
      detail?: string
    },
    void,
    LDAPTokenObtain,
    void
  >,
  'path' | 'verb'
>

/**
 * Authenticate LDAP user and obtain JWT tokens
 */
export const AuthLdapTokenCreate = (props: AuthLdapTokenCreateProps) => (
  <Mutate<
    AuthLdapTokenCreateResponse,
    {
      detail?: string
    },
    void,
    LDAPTokenObtain,
    void
  >
    verb="POST"
    path={encode`/auth/ldap-token/`}
    {...props}
  />
)

export type UseAuthLdapTokenCreateProps = Omit<
  UseMutateProps<
    AuthLdapTokenCreateResponse,
    {
      detail?: string
    },
    void,
    LDAPTokenObtain,
    void
  >,
  'path' | 'verb'
>

/**
 * Authenticate LDAP user and obtain JWT tokens
 */
export const useAuthLdapTokenCreate = (props: UseAuthLdapTokenCreateProps) =>
  useMutate<
    AuthLdapTokenCreateResponse,
    {
      detail?: string
    },
    void,
    LDAPTokenObtain,
    void
  >('POST', encode`/auth/ldap-token/`, props)

export interface AuthTokenCreateResponse {
  access: string
  refresh: string
}

export type AuthTokenCreateProps = Omit<
  MutateProps<
    AuthTokenCreateResponse,
    {
      detail?: string
    },
    void,
    CommonTokenObtain,
    void
  >,
  'path' | 'verb'
>

/**
 * Authenticate user and obtain JWT tokens
 */
export const AuthTokenCreate = (props: AuthTokenCreateProps) => (
  <Mutate<
    AuthTokenCreateResponse,
    {
      detail?: string
    },
    void,
    CommonTokenObtain,
    void
  >
    verb="POST"
    path={encode`/auth/token/`}
    {...props}
  />
)

export type UseAuthTokenCreateProps = Omit<
  UseMutateProps<
    AuthTokenCreateResponse,
    {
      detail?: string
    },
    void,
    CommonTokenObtain,
    void
  >,
  'path' | 'verb'
>

/**
 * Authenticate user and obtain JWT tokens
 */
export const useAuthTokenCreate = (props: UseAuthTokenCreateProps) =>
  useMutate<
    AuthTokenCreateResponse,
    {
      detail?: string
    },
    void,
    CommonTokenObtain,
    void
  >('POST', encode`/auth/token/`, props)

export interface AuthTokenRefreshCreateResponse {
  access: string
  refresh: string
}

export type AuthTokenRefreshCreateProps = Omit<
  MutateProps<
    AuthTokenRefreshCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenRefresh,
    void
  >,
  'path' | 'verb'
>

/**
 * TokenRefreshView with swagger schema
 */
export const AuthTokenRefreshCreate = (props: AuthTokenRefreshCreateProps) => (
  <Mutate<
    AuthTokenRefreshCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenRefresh,
    void
  >
    verb="POST"
    path={encode`/auth/token/refresh/`}
    {...props}
  />
)

export type UseAuthTokenRefreshCreateProps = Omit<
  UseMutateProps<
    AuthTokenRefreshCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenRefresh,
    void
  >,
  'path' | 'verb'
>

/**
 * TokenRefreshView with swagger schema
 */
export const useAuthTokenRefreshCreate = (
  props: UseAuthTokenRefreshCreateProps,
) =>
  useMutate<
    AuthTokenRefreshCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenRefresh,
    void
  >('POST', encode`/auth/token/refresh/`, props)

export interface AuthTokenVerifyCreateResponse {
  [key: string]: any
}

export type AuthTokenVerifyCreateProps = Omit<
  MutateProps<
    AuthTokenVerifyCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenVerify,
    void
  >,
  'path' | 'verb'
>

/**
 * Takes a token and indicates if it is valid.  This view provides no
 * information about a token's fitness for a particular use.
 */
export const AuthTokenVerifyCreate = (props: AuthTokenVerifyCreateProps) => (
  <Mutate<
    AuthTokenVerifyCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenVerify,
    void
  >
    verb="POST"
    path={encode`/auth/token/verify/`}
    {...props}
  />
)

export type UseAuthTokenVerifyCreateProps = Omit<
  UseMutateProps<
    AuthTokenVerifyCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenVerify,
    void
  >,
  'path' | 'verb'
>

/**
 * Takes a token and indicates if it is valid.  This view provides no
 * information about a token's fitness for a particular use.
 */
export const useAuthTokenVerifyCreate = (
  props: UseAuthTokenVerifyCreateProps,
) =>
  useMutate<
    AuthTokenVerifyCreateResponse,
    {
      detail: string
      code: string
    },
    void,
    TokenVerify,
    void
  >('POST', encode`/auth/token/verify/`, props)

export type CitiesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: City[]
}

export interface CitiesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type CitiesListProps = Omit<
  GetProps<CitiesListResponse, unknown, CitiesListQueryParams, void>,
  'path'
>

/**
 * DRF ViewSet for City model.
 */
export const CitiesList = (props: CitiesListProps) => (
  <Get<CitiesListResponse, unknown, CitiesListQueryParams, void>
    path={encode`/cities/`}
    {...props}
  />
)

export type UseCitiesListProps = Omit<
  UseGetProps<CitiesListResponse, unknown, CitiesListQueryParams, void>,
  'path'
>

/**
 * DRF ViewSet for City model.
 */
export const useCitiesList = (props: UseCitiesListProps) =>
  useGet<CitiesListResponse, unknown, CitiesListQueryParams, void>(
    encode`/cities/`,
    props,
  )

export type CitiesCreateProps = Omit<
  MutateProps<City, unknown, void, CityRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for City model.
 */
export const CitiesCreate = (props: CitiesCreateProps) => (
  <Mutate<City, unknown, void, CityRequestBody, void>
    verb="POST"
    path={encode`/cities/`}
    {...props}
  />
)

export type UseCitiesCreateProps = Omit<
  UseMutateProps<City, unknown, void, CityRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for City model.
 */
export const useCitiesCreate = (props: UseCitiesCreateProps) =>
  useMutate<City, unknown, void, CityRequestBody, void>(
    'POST',
    encode`/cities/`,
    props,
  )

export interface CitiesReadPathParams {
  /**
   * A unique integer value identifying this city.
   */
  id: number
}

export type CitiesReadProps = Omit<
  GetProps<City, unknown, void, CitiesReadPathParams>,
  'path'
> &
  CitiesReadPathParams

/**
 * DRF ViewSet for City model.
 */
export const CitiesRead = ({id, ...props}: CitiesReadProps) => (
  <Get<City, unknown, void, CitiesReadPathParams>
    path={encode`/cities/${id}/`}
    {...props}
  />
)

export type UseCitiesReadProps = Omit<
  UseGetProps<City, unknown, void, CitiesReadPathParams>,
  'path'
> &
  CitiesReadPathParams

/**
 * DRF ViewSet for City model.
 */
export const useCitiesRead = ({id, ...props}: UseCitiesReadProps) =>
  useGet<City, unknown, void, CitiesReadPathParams>(
    (paramsInPath: CitiesReadPathParams) => encode`/cities/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface CitiesUpdatePathParams {
  /**
   * A unique integer value identifying this city.
   */
  id: number
}

export type CitiesUpdateProps = Omit<
  MutateProps<City, unknown, void, CityRequestBody, CitiesUpdatePathParams>,
  'path' | 'verb'
> &
  CitiesUpdatePathParams

/**
 * DRF ViewSet for City model.
 */
export const CitiesUpdate = ({id, ...props}: CitiesUpdateProps) => (
  <Mutate<City, unknown, void, CityRequestBody, CitiesUpdatePathParams>
    verb="PUT"
    path={encode`/cities/${id}/`}
    {...props}
  />
)

export type UseCitiesUpdateProps = Omit<
  UseMutateProps<City, unknown, void, CityRequestBody, CitiesUpdatePathParams>,
  'path' | 'verb'
> &
  CitiesUpdatePathParams

/**
 * DRF ViewSet for City model.
 */
export const useCitiesUpdate = ({id, ...props}: UseCitiesUpdateProps) =>
  useMutate<City, unknown, void, CityRequestBody, CitiesUpdatePathParams>(
    'PUT',
    (paramsInPath: CitiesUpdatePathParams) =>
      encode`/cities/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface CitiesPartialUpdatePathParams {
  /**
   * A unique integer value identifying this city.
   */
  id: number
}

export type CitiesPartialUpdateProps = Omit<
  MutateProps<
    City,
    unknown,
    void,
    CityRequestBody,
    CitiesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  CitiesPartialUpdatePathParams

/**
 * DRF ViewSet for City model.
 */
export const CitiesPartialUpdate = ({
  id,
  ...props
}: CitiesPartialUpdateProps) => (
  <Mutate<City, unknown, void, CityRequestBody, CitiesPartialUpdatePathParams>
    verb="PATCH"
    path={encode`/cities/${id}/`}
    {...props}
  />
)

export type UseCitiesPartialUpdateProps = Omit<
  UseMutateProps<
    City,
    unknown,
    void,
    CityRequestBody,
    CitiesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  CitiesPartialUpdatePathParams

/**
 * DRF ViewSet for City model.
 */
export const useCitiesPartialUpdate = ({
  id,
  ...props
}: UseCitiesPartialUpdateProps) =>
  useMutate<
    City,
    unknown,
    void,
    CityRequestBody,
    CitiesPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: CitiesPartialUpdatePathParams) =>
      encode`/cities/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type CitiesDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for City model.
 */
export const CitiesDelete = (props: CitiesDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/cities`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseCitiesDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for City model.
 */
export const useCitiesDelete = (props: UseCitiesDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/cities`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })

export type ContactCategoriesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: ContactCategory[]
}

export interface ContactCategoriesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type ContactCategoriesListProps = Omit<
  GetProps<
    ContactCategoriesListResponse,
    unknown,
    ContactCategoriesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for work with museum Contacts Categories.
 */
export const ContactCategoriesList = (props: ContactCategoriesListProps) => (
  <Get<
    ContactCategoriesListResponse,
    unknown,
    ContactCategoriesListQueryParams,
    void
  >
    path={encode`/contact_categories/`}
    {...props}
  />
)

export type UseContactCategoriesListProps = Omit<
  UseGetProps<
    ContactCategoriesListResponse,
    unknown,
    ContactCategoriesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for work with museum Contacts Categories.
 */
export const useContactCategoriesList = (
  props: UseContactCategoriesListProps,
) =>
  useGet<
    ContactCategoriesListResponse,
    unknown,
    ContactCategoriesListQueryParams,
    void
  >(encode`/contact_categories/`, props)

export interface ContactCategoriesReadPathParams {
  /**
   * A unique integer value identifying this категория контакта.
   */
  id: number
}

export type ContactCategoriesReadProps = Omit<
  GetProps<ContactCategory, unknown, void, ContactCategoriesReadPathParams>,
  'path'
> &
  ContactCategoriesReadPathParams

/**
 * API for work with museum Contacts Categories.
 */
export const ContactCategoriesRead = ({
  id,
  ...props
}: ContactCategoriesReadProps) => (
  <Get<ContactCategory, unknown, void, ContactCategoriesReadPathParams>
    path={encode`/contact_categories/${id}/`}
    {...props}
  />
)

export type UseContactCategoriesReadProps = Omit<
  UseGetProps<ContactCategory, unknown, void, ContactCategoriesReadPathParams>,
  'path'
> &
  ContactCategoriesReadPathParams

/**
 * API for work with museum Contacts Categories.
 */
export const useContactCategoriesRead = ({
  id,
  ...props
}: UseContactCategoriesReadProps) =>
  useGet<ContactCategory, unknown, void, ContactCategoriesReadPathParams>(
    (paramsInPath: ContactCategoriesReadPathParams) =>
      encode`/contact_categories/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type ContactPhotoCreateProps = Omit<
  MutateProps<ContactPhoto, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for contacts photos.
 */
export const ContactPhotoCreate = (props: ContactPhotoCreateProps) => (
  <Mutate<ContactPhoto, unknown, void, void, void>
    verb="POST"
    path={encode`/contact_photo/`}
    {...props}
  />
)

export type UseContactPhotoCreateProps = Omit<
  UseMutateProps<ContactPhoto, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for contacts photos.
 */
export const useContactPhotoCreate = (props: UseContactPhotoCreateProps) =>
  useMutate<ContactPhoto, unknown, void, void, void>(
    'POST',
    encode`/contact_photo/`,
    props,
  )

export interface ContactPhotoReadPathParams {
  /**
   * A unique integer value identifying this фотография.
   */
  id: number
}

export type ContactPhotoReadProps = Omit<
  GetProps<ContactPhoto, unknown, void, ContactPhotoReadPathParams>,
  'path'
> &
  ContactPhotoReadPathParams

/**
 * API for contacts photos.
 */
export const ContactPhotoRead = ({id, ...props}: ContactPhotoReadProps) => (
  <Get<ContactPhoto, unknown, void, ContactPhotoReadPathParams>
    path={encode`/contact_photo/${id}/`}
    {...props}
  />
)

export type UseContactPhotoReadProps = Omit<
  UseGetProps<ContactPhoto, unknown, void, ContactPhotoReadPathParams>,
  'path'
> &
  ContactPhotoReadPathParams

/**
 * API for contacts photos.
 */
export const useContactPhotoRead = ({id, ...props}: UseContactPhotoReadProps) =>
  useGet<ContactPhoto, unknown, void, ContactPhotoReadPathParams>(
    (paramsInPath: ContactPhotoReadPathParams) =>
      encode`/contact_photo/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type ContactsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: ContactsList[]
}

export interface ContactsListQueryParams {
  last_name__istartswith?: string
  categories?: string
  onlyActive?: string
  /**
   * A search term.
   */
  search?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type ContactsListProps = Omit<
  GetProps<ContactsListResponse, unknown, ContactsListQueryParams, void>,
  'path'
>

/**
 * API for work with museum Contacts.
 */
export const ContactsList = (props: ContactsListProps) => (
  <Get<ContactsListResponse, unknown, ContactsListQueryParams, void>
    path={encode`/contacts/`}
    {...props}
  />
)

export type UseContactsListProps = Omit<
  UseGetProps<ContactsListResponse, unknown, ContactsListQueryParams, void>,
  'path'
>

/**
 * API for work with museum Contacts.
 */
export const useContactsList = (props: UseContactsListProps) =>
  useGet<ContactsListResponse, unknown, ContactsListQueryParams, void>(
    encode`/contacts/`,
    props,
  )

export type ContactsCreateProps = Omit<
  MutateProps<ContactPost, unknown, void, ContactPost, void>,
  'path' | 'verb'
>

/**
 * API for work with museum Contacts.
 */
export const ContactsCreate = (props: ContactsCreateProps) => (
  <Mutate<ContactPost, unknown, void, ContactPost, void>
    verb="POST"
    path={encode`/contacts/`}
    {...props}
  />
)

export type UseContactsCreateProps = Omit<
  UseMutateProps<ContactPost, unknown, void, ContactPost, void>,
  'path' | 'verb'
>

/**
 * API for work with museum Contacts.
 */
export const useContactsCreate = (props: UseContactsCreateProps) =>
  useMutate<ContactPost, unknown, void, ContactPost, void>(
    'POST',
    encode`/contacts/`,
    props,
  )

export interface ContactsReadPathParams {
  /**
   * A unique integer value identifying this контакт музея.
   */
  id: number
}

export type ContactsReadProps = Omit<
  GetProps<Contact, unknown, void, ContactsReadPathParams>,
  'path'
> &
  ContactsReadPathParams

/**
 * API for work with museum Contacts.
 */
export const ContactsRead = ({id, ...props}: ContactsReadProps) => (
  <Get<Contact, unknown, void, ContactsReadPathParams>
    path={encode`/contacts/${id}/`}
    {...props}
  />
)

export type UseContactsReadProps = Omit<
  UseGetProps<Contact, unknown, void, ContactsReadPathParams>,
  'path'
> &
  ContactsReadPathParams

/**
 * API for work with museum Contacts.
 */
export const useContactsRead = ({id, ...props}: UseContactsReadProps) =>
  useGet<Contact, unknown, void, ContactsReadPathParams>(
    (paramsInPath: ContactsReadPathParams) =>
      encode`/contacts/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ContactsUpdateResponse {
  success: boolean
}

export interface ContactsUpdatePathParams {
  /**
   * A unique integer value identifying this контакт музея.
   */
  id: number
}

export type ContactsUpdateProps = Omit<
  MutateProps<
    ContactsUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsUpdatePathParams

/**
 * API for work with museum Contacts.
 */
export const ContactsUpdate = ({id, ...props}: ContactsUpdateProps) => (
  <Mutate<
    ContactsUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsUpdatePathParams
  >
    verb="PUT"
    path={encode`/contacts/${id}/`}
    {...props}
  />
)

export type UseContactsUpdateProps = Omit<
  UseMutateProps<
    ContactsUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsUpdatePathParams

/**
 * API for work with museum Contacts.
 */
export const useContactsUpdate = ({id, ...props}: UseContactsUpdateProps) =>
  useMutate<
    ContactsUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsUpdatePathParams
  >(
    'PUT',
    (paramsInPath: ContactsUpdatePathParams) =>
      encode`/contacts/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ContactsPartialUpdateResponse {
  success: boolean
}

export interface ContactsPartialUpdatePathParams {
  /**
   * A unique integer value identifying this контакт музея.
   */
  id: number
}

export type ContactsPartialUpdateProps = Omit<
  MutateProps<
    ContactsPartialUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsPartialUpdatePathParams

/**
 * API for work with museum Contacts.
 */
export const ContactsPartialUpdate = ({
  id,
  ...props
}: ContactsPartialUpdateProps) => (
  <Mutate<
    ContactsPartialUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/contacts/${id}/`}
    {...props}
  />
)

export type UseContactsPartialUpdateProps = Omit<
  UseMutateProps<
    ContactsPartialUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsPartialUpdatePathParams

/**
 * API for work with museum Contacts.
 */
export const useContactsPartialUpdate = ({
  id,
  ...props
}: UseContactsPartialUpdateProps) =>
  useMutate<
    ContactsPartialUpdateResponse,
    unknown,
    void,
    ContactUpdateRequestBody,
    ContactsPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: ContactsPartialUpdatePathParams) =>
      encode`/contacts/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ContactsChangelogResponse {
  results: {
    /**
     * Keys are Contact instance attributes, values are dicts in {"old": <>, "new": <>} format
     */
    diff: {[key: string]: any}
    date_created: string
    user: {
      id?: number
      first_name?: string
      last_name?: string
      email?: string
      phone?: string
    }
  }[]
}

export interface ContactsChangelogPathParams {
  /**
   * A unique integer value identifying this контакт музея.
   */
  id: number
}

export type ContactsChangelogProps = Omit<
  GetProps<
    ContactsChangelogResponse,
    unknown,
    void,
    ContactsChangelogPathParams
  >,
  'path'
> &
  ContactsChangelogPathParams

/**
 * Changes in contact instance
 */
export const ContactsChangelog = ({id, ...props}: ContactsChangelogProps) => (
  <Get<ContactsChangelogResponse, unknown, void, ContactsChangelogPathParams>
    path={encode`/contacts/${id}/changelog/`}
    {...props}
  />
)

export type UseContactsChangelogProps = Omit<
  UseGetProps<
    ContactsChangelogResponse,
    unknown,
    void,
    ContactsChangelogPathParams
  >,
  'path'
> &
  ContactsChangelogPathParams

/**
 * Changes in contact instance
 */
export const useContactsChangelog = ({
  id,
  ...props
}: UseContactsChangelogProps) =>
  useGet<ContactsChangelogResponse, unknown, void, ContactsChangelogPathParams>(
    (paramsInPath: ContactsChangelogPathParams) =>
      encode`/contacts/${paramsInPath.id}/changelog/`,
    {pathParams: {id}, ...props},
  )

export type ContactsGroupsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: ContactsGroupList[]
}

export interface ContactsGroupsListQueryParams {
  /**
   * A search term.
   */
  search?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type ContactsGroupsListProps = Omit<
  GetProps<
    ContactsGroupsListResponse,
    unknown,
    ContactsGroupsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Contacts groups (segments).
 */
export const ContactsGroupsList = (props: ContactsGroupsListProps) => (
  <Get<ContactsGroupsListResponse, unknown, ContactsGroupsListQueryParams, void>
    path={encode`/contacts_groups/`}
    {...props}
  />
)

export type UseContactsGroupsListProps = Omit<
  UseGetProps<
    ContactsGroupsListResponse,
    unknown,
    ContactsGroupsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Contacts groups (segments).
 */
export const useContactsGroupsList = (props: UseContactsGroupsListProps) =>
  useGet<
    ContactsGroupsListResponse,
    unknown,
    ContactsGroupsListQueryParams,
    void
  >(encode`/contacts_groups/`, props)

export type ContactsGroupsCreateProps = Omit<
  MutateProps<ContactsGroup, unknown, void, ContactsGroupCreate, void>,
  'path' | 'verb'
>

/**
 * API for Contacts groups (segments).
 */
export const ContactsGroupsCreate = (props: ContactsGroupsCreateProps) => (
  <Mutate<ContactsGroup, unknown, void, ContactsGroupCreate, void>
    verb="POST"
    path={encode`/contacts_groups/`}
    {...props}
  />
)

export type UseContactsGroupsCreateProps = Omit<
  UseMutateProps<ContactsGroup, unknown, void, ContactsGroupCreate, void>,
  'path' | 'verb'
>

/**
 * API for Contacts groups (segments).
 */
export const useContactsGroupsCreate = (props: UseContactsGroupsCreateProps) =>
  useMutate<ContactsGroup, unknown, void, ContactsGroupCreate, void>(
    'POST',
    encode`/contacts_groups/`,
    props,
  )

export type ContactsGroupsTestQueryProps = Omit<
  MutateProps<ContactCompact[], unknown, void, SegmentQueryAttrs, void>,
  'path' | 'verb'
>

/**
 * Applies provided DjangoQL query to model without saving
 *
 * Can be used for testing query before saving it to DB.
 */
export const ContactsGroupsTestQuery = (
  props: ContactsGroupsTestQueryProps,
) => (
  <Mutate<ContactCompact[], unknown, void, SegmentQueryAttrs, void>
    verb="POST"
    path={encode`/contacts_groups/test_query/`}
    {...props}
  />
)

export type UseContactsGroupsTestQueryProps = Omit<
  UseMutateProps<ContactCompact[], unknown, void, SegmentQueryAttrs, void>,
  'path' | 'verb'
>

/**
 * Applies provided DjangoQL query to model without saving
 *
 * Can be used for testing query before saving it to DB.
 */
export const useContactsGroupsTestQuery = (
  props: UseContactsGroupsTestQueryProps,
) =>
  useMutate<ContactCompact[], unknown, void, SegmentQueryAttrs, void>(
    'POST',
    encode`/contacts_groups/test_query/`,
    props,
  )

export interface ContactsGroupsReadPathParams {
  /**
   * A unique integer value identifying this группа контактов.
   */
  id: number
}

export type ContactsGroupsReadProps = Omit<
  GetProps<ContactsGroup, unknown, void, ContactsGroupsReadPathParams>,
  'path'
> &
  ContactsGroupsReadPathParams

/**
 * API for Contacts groups (segments).
 */
export const ContactsGroupsRead = ({id, ...props}: ContactsGroupsReadProps) => (
  <Get<ContactsGroup, unknown, void, ContactsGroupsReadPathParams>
    path={encode`/contacts_groups/${id}/`}
    {...props}
  />
)

export type UseContactsGroupsReadProps = Omit<
  UseGetProps<ContactsGroup, unknown, void, ContactsGroupsReadPathParams>,
  'path'
> &
  ContactsGroupsReadPathParams

/**
 * API for Contacts groups (segments).
 */
export const useContactsGroupsRead = ({
  id,
  ...props
}: UseContactsGroupsReadProps) =>
  useGet<ContactsGroup, unknown, void, ContactsGroupsReadPathParams>(
    (paramsInPath: ContactsGroupsReadPathParams) =>
      encode`/contacts_groups/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ContactsGroupsUpdatePathParams {
  /**
   * A unique integer value identifying this группа контактов.
   */
  id: number
}

export type ContactsGroupsUpdateProps = Omit<
  MutateProps<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsGroupsUpdatePathParams

/**
 * API for Contacts groups (segments).
 */
export const ContactsGroupsUpdate = ({
  id,
  ...props
}: ContactsGroupsUpdateProps) => (
  <Mutate<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsUpdatePathParams
  >
    verb="PUT"
    path={encode`/contacts_groups/${id}/`}
    {...props}
  />
)

export type UseContactsGroupsUpdateProps = Omit<
  UseMutateProps<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsGroupsUpdatePathParams

/**
 * API for Contacts groups (segments).
 */
export const useContactsGroupsUpdate = ({
  id,
  ...props
}: UseContactsGroupsUpdateProps) =>
  useMutate<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsUpdatePathParams
  >(
    'PUT',
    (paramsInPath: ContactsGroupsUpdatePathParams) =>
      encode`/contacts_groups/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ContactsGroupsPartialUpdatePathParams {
  /**
   * A unique integer value identifying this группа контактов.
   */
  id: number
}

export type ContactsGroupsPartialUpdateProps = Omit<
  MutateProps<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsGroupsPartialUpdatePathParams

/**
 * API for Contacts groups (segments).
 */
export const ContactsGroupsPartialUpdate = ({
  id,
  ...props
}: ContactsGroupsPartialUpdateProps) => (
  <Mutate<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/contacts_groups/${id}/`}
    {...props}
  />
)

export type UseContactsGroupsPartialUpdateProps = Omit<
  UseMutateProps<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ContactsGroupsPartialUpdatePathParams

/**
 * API for Contacts groups (segments).
 */
export const useContactsGroupsPartialUpdate = ({
  id,
  ...props
}: UseContactsGroupsPartialUpdateProps) =>
  useMutate<
    ContactsGroup,
    unknown,
    void,
    ContactsGroupUpdateRequestBody,
    ContactsGroupsPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: ContactsGroupsPartialUpdatePathParams) =>
      encode`/contacts_groups/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type ContactsGroupsDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for Contacts groups (segments).
 */
export const ContactsGroupsDelete = (props: ContactsGroupsDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/contacts_groups`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseContactsGroupsDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for Contacts groups (segments).
 */
export const useContactsGroupsDelete = (props: UseContactsGroupsDeleteProps) =>
  useMutate<void, unknown, void, string, void>(
    'DELETE',
    encode`/contacts_groups`,
    {pathInlineBodyEncode: encodingFn, ...props},
  )

export interface ContactsGroupsMembersPathParams {
  /**
   * A unique integer value identifying this группа контактов.
   */
  id: number
}

export type ContactsGroupsMembersProps = Omit<
  GetProps<ContactCompact[], unknown, void, ContactsGroupsMembersPathParams>,
  'path'
> &
  ContactsGroupsMembersPathParams

/**
 * Receive list of contacts in segment
 *
 * Applies segment filter to Contact model and returns results list.
 */
export const ContactsGroupsMembers = ({
  id,
  ...props
}: ContactsGroupsMembersProps) => (
  <Get<ContactCompact[], unknown, void, ContactsGroupsMembersPathParams>
    path={encode`/contacts_groups/${id}/members/`}
    {...props}
  />
)

export type UseContactsGroupsMembersProps = Omit<
  UseGetProps<ContactCompact[], unknown, void, ContactsGroupsMembersPathParams>,
  'path'
> &
  ContactsGroupsMembersPathParams

/**
 * Receive list of contacts in segment
 *
 * Applies segment filter to Contact model and returns results list.
 */
export const useContactsGroupsMembers = ({
  id,
  ...props
}: UseContactsGroupsMembersProps) =>
  useGet<ContactCompact[], unknown, void, ContactsGroupsMembersPathParams>(
    (paramsInPath: ContactsGroupsMembersPathParams) =>
      encode`/contacts_groups/${paramsInPath.id}/members/`,
    {pathParams: {id}, ...props},
  )

export interface ContactsGroupsSizeResponse {
  size?: number
}

export interface ContactsGroupsSizePathParams {
  /**
   * A unique integer value identifying this группа контактов.
   */
  id: number
}

export type ContactsGroupsSizeProps = Omit<
  GetProps<
    ContactsGroupsSizeResponse,
    unknown,
    void,
    ContactsGroupsSizePathParams
  >,
  'path'
> &
  ContactsGroupsSizePathParams

/**
 * Receive amount of contacts in segment
 *
 * Applies segment filter to Contact model and counts contacts amount.
 */
export const ContactsGroupsSize = ({id, ...props}: ContactsGroupsSizeProps) => (
  <Get<ContactsGroupsSizeResponse, unknown, void, ContactsGroupsSizePathParams>
    path={encode`/contacts_groups/${id}/size/`}
    {...props}
  />
)

export type UseContactsGroupsSizeProps = Omit<
  UseGetProps<
    ContactsGroupsSizeResponse,
    unknown,
    void,
    ContactsGroupsSizePathParams
  >,
  'path'
> &
  ContactsGroupsSizePathParams

/**
 * Receive amount of contacts in segment
 *
 * Applies segment filter to Contact model and counts contacts amount.
 */
export const useContactsGroupsSize = ({
  id,
  ...props
}: UseContactsGroupsSizeProps) =>
  useGet<
    ContactsGroupsSizeResponse,
    unknown,
    void,
    ContactsGroupsSizePathParams
  >(
    (paramsInPath: ContactsGroupsSizePathParams) =>
      encode`/contacts_groups/${paramsInPath.id}/size/`,
    {pathParams: {id}, ...props},
  )

export type CountriesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Country[]
}

export interface CountriesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type CountriesListProps = Omit<
  GetProps<CountriesListResponse, unknown, CountriesListQueryParams, void>,
  'path'
>

/**
 * DRF ViewSet for Country model.
 */
export const CountriesList = (props: CountriesListProps) => (
  <Get<CountriesListResponse, unknown, CountriesListQueryParams, void>
    path={encode`/countries/`}
    {...props}
  />
)

export type UseCountriesListProps = Omit<
  UseGetProps<CountriesListResponse, unknown, CountriesListQueryParams, void>,
  'path'
>

/**
 * DRF ViewSet for Country model.
 */
export const useCountriesList = (props: UseCountriesListProps) =>
  useGet<CountriesListResponse, unknown, CountriesListQueryParams, void>(
    encode`/countries/`,
    props,
  )

export type CountriesCreateProps = Omit<
  MutateProps<Country, unknown, void, CountryRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Country model.
 */
export const CountriesCreate = (props: CountriesCreateProps) => (
  <Mutate<Country, unknown, void, CountryRequestBody, void>
    verb="POST"
    path={encode`/countries/`}
    {...props}
  />
)

export type UseCountriesCreateProps = Omit<
  UseMutateProps<Country, unknown, void, CountryRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Country model.
 */
export const useCountriesCreate = (props: UseCountriesCreateProps) =>
  useMutate<Country, unknown, void, CountryRequestBody, void>(
    'POST',
    encode`/countries/`,
    props,
  )

export interface CountriesReadPathParams {
  /**
   * A unique integer value identifying this country.
   */
  id: number
}

export type CountriesReadProps = Omit<
  GetProps<Country, unknown, void, CountriesReadPathParams>,
  'path'
> &
  CountriesReadPathParams

/**
 * DRF ViewSet for Country model.
 */
export const CountriesRead = ({id, ...props}: CountriesReadProps) => (
  <Get<Country, unknown, void, CountriesReadPathParams>
    path={encode`/countries/${id}/`}
    {...props}
  />
)

export type UseCountriesReadProps = Omit<
  UseGetProps<Country, unknown, void, CountriesReadPathParams>,
  'path'
> &
  CountriesReadPathParams

/**
 * DRF ViewSet for Country model.
 */
export const useCountriesRead = ({id, ...props}: UseCountriesReadProps) =>
  useGet<Country, unknown, void, CountriesReadPathParams>(
    (paramsInPath: CountriesReadPathParams) =>
      encode`/countries/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface CountriesUpdatePathParams {
  /**
   * A unique integer value identifying this country.
   */
  id: number
}

export type CountriesUpdateProps = Omit<
  MutateProps<
    Country,
    unknown,
    void,
    CountryRequestBody,
    CountriesUpdatePathParams
  >,
  'path' | 'verb'
> &
  CountriesUpdatePathParams

/**
 * DRF ViewSet for Country model.
 */
export const CountriesUpdate = ({id, ...props}: CountriesUpdateProps) => (
  <Mutate<Country, unknown, void, CountryRequestBody, CountriesUpdatePathParams>
    verb="PUT"
    path={encode`/countries/${id}/`}
    {...props}
  />
)

export type UseCountriesUpdateProps = Omit<
  UseMutateProps<
    Country,
    unknown,
    void,
    CountryRequestBody,
    CountriesUpdatePathParams
  >,
  'path' | 'verb'
> &
  CountriesUpdatePathParams

/**
 * DRF ViewSet for Country model.
 */
export const useCountriesUpdate = ({id, ...props}: UseCountriesUpdateProps) =>
  useMutate<
    Country,
    unknown,
    void,
    CountryRequestBody,
    CountriesUpdatePathParams
  >(
    'PUT',
    (paramsInPath: CountriesUpdatePathParams) =>
      encode`/countries/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface CountriesPartialUpdatePathParams {
  /**
   * A unique integer value identifying this country.
   */
  id: number
}

export type CountriesPartialUpdateProps = Omit<
  MutateProps<
    Country,
    unknown,
    void,
    CountryRequestBody,
    CountriesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  CountriesPartialUpdatePathParams

/**
 * DRF ViewSet for Country model.
 */
export const CountriesPartialUpdate = ({
  id,
  ...props
}: CountriesPartialUpdateProps) => (
  <Mutate<
    Country,
    unknown,
    void,
    CountryRequestBody,
    CountriesPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/countries/${id}/`}
    {...props}
  />
)

export type UseCountriesPartialUpdateProps = Omit<
  UseMutateProps<
    Country,
    unknown,
    void,
    CountryRequestBody,
    CountriesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  CountriesPartialUpdatePathParams

/**
 * DRF ViewSet for Country model.
 */
export const useCountriesPartialUpdate = ({
  id,
  ...props
}: UseCountriesPartialUpdateProps) =>
  useMutate<
    Country,
    unknown,
    void,
    CountryRequestBody,
    CountriesPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: CountriesPartialUpdatePathParams) =>
      encode`/countries/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type CountriesDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Country model.
 */
export const CountriesDelete = (props: CountriesDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/countries`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseCountriesDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Country model.
 */
export const useCountriesDelete = (props: UseCountriesDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/countries`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })

export type DepartmentsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Department[]
}

export interface DepartmentsListQueryParams {
  /**
   * A search term.
   */
  search?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type DepartmentsListProps = Omit<
  GetProps<DepartmentsListResponse, unknown, DepartmentsListQueryParams, void>,
  'path'
>

/**
 * API for museum departments.
 */
export const DepartmentsList = (props: DepartmentsListProps) => (
  <Get<DepartmentsListResponse, unknown, DepartmentsListQueryParams, void>
    path={encode`/departments/`}
    {...props}
  />
)

export type UseDepartmentsListProps = Omit<
  UseGetProps<
    DepartmentsListResponse,
    unknown,
    DepartmentsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for museum departments.
 */
export const useDepartmentsList = (props: UseDepartmentsListProps) =>
  useGet<DepartmentsListResponse, unknown, DepartmentsListQueryParams, void>(
    encode`/departments/`,
    props,
  )

export type DisabilitiesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Disability[]
}

export interface DisabilitiesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type DisabilitiesListProps = Omit<
  GetProps<
    DisabilitiesListResponse,
    unknown,
    DisabilitiesListQueryParams,
    void
  >,
  'path'
>

/**
 * DRF ViewSet for Disability model.
 */
export const DisabilitiesList = (props: DisabilitiesListProps) => (
  <Get<DisabilitiesListResponse, unknown, DisabilitiesListQueryParams, void>
    path={encode`/disabilities/`}
    {...props}
  />
)

export type UseDisabilitiesListProps = Omit<
  UseGetProps<
    DisabilitiesListResponse,
    unknown,
    DisabilitiesListQueryParams,
    void
  >,
  'path'
>

/**
 * DRF ViewSet for Disability model.
 */
export const useDisabilitiesList = (props: UseDisabilitiesListProps) =>
  useGet<DisabilitiesListResponse, unknown, DisabilitiesListQueryParams, void>(
    encode`/disabilities/`,
    props,
  )

export type DisabilitiesCreateProps = Omit<
  MutateProps<Disability, unknown, void, DisabilityRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Disability model.
 */
export const DisabilitiesCreate = (props: DisabilitiesCreateProps) => (
  <Mutate<Disability, unknown, void, DisabilityRequestBody, void>
    verb="POST"
    path={encode`/disabilities/`}
    {...props}
  />
)

export type UseDisabilitiesCreateProps = Omit<
  UseMutateProps<Disability, unknown, void, DisabilityRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Disability model.
 */
export const useDisabilitiesCreate = (props: UseDisabilitiesCreateProps) =>
  useMutate<Disability, unknown, void, DisabilityRequestBody, void>(
    'POST',
    encode`/disabilities/`,
    props,
  )

export interface DisabilitiesReadPathParams {
  /**
   * A unique integer value identifying this тип инвалидности.
   */
  id: number
}

export type DisabilitiesReadProps = Omit<
  GetProps<Disability, unknown, void, DisabilitiesReadPathParams>,
  'path'
> &
  DisabilitiesReadPathParams

/**
 * DRF ViewSet for Disability model.
 */
export const DisabilitiesRead = ({id, ...props}: DisabilitiesReadProps) => (
  <Get<Disability, unknown, void, DisabilitiesReadPathParams>
    path={encode`/disabilities/${id}/`}
    {...props}
  />
)

export type UseDisabilitiesReadProps = Omit<
  UseGetProps<Disability, unknown, void, DisabilitiesReadPathParams>,
  'path'
> &
  DisabilitiesReadPathParams

/**
 * DRF ViewSet for Disability model.
 */
export const useDisabilitiesRead = ({id, ...props}: UseDisabilitiesReadProps) =>
  useGet<Disability, unknown, void, DisabilitiesReadPathParams>(
    (paramsInPath: DisabilitiesReadPathParams) =>
      encode`/disabilities/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface DisabilitiesUpdatePathParams {
  /**
   * A unique integer value identifying this тип инвалидности.
   */
  id: number
}

export type DisabilitiesUpdateProps = Omit<
  MutateProps<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesUpdatePathParams
  >,
  'path' | 'verb'
> &
  DisabilitiesUpdatePathParams

/**
 * DRF ViewSet for Disability model.
 */
export const DisabilitiesUpdate = ({id, ...props}: DisabilitiesUpdateProps) => (
  <Mutate<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesUpdatePathParams
  >
    verb="PUT"
    path={encode`/disabilities/${id}/`}
    {...props}
  />
)

export type UseDisabilitiesUpdateProps = Omit<
  UseMutateProps<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesUpdatePathParams
  >,
  'path' | 'verb'
> &
  DisabilitiesUpdatePathParams

/**
 * DRF ViewSet for Disability model.
 */
export const useDisabilitiesUpdate = ({
  id,
  ...props
}: UseDisabilitiesUpdateProps) =>
  useMutate<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesUpdatePathParams
  >(
    'PUT',
    (paramsInPath: DisabilitiesUpdatePathParams) =>
      encode`/disabilities/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface DisabilitiesPartialUpdatePathParams {
  /**
   * A unique integer value identifying this тип инвалидности.
   */
  id: number
}

export type DisabilitiesPartialUpdateProps = Omit<
  MutateProps<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  DisabilitiesPartialUpdatePathParams

/**
 * DRF ViewSet for Disability model.
 */
export const DisabilitiesPartialUpdate = ({
  id,
  ...props
}: DisabilitiesPartialUpdateProps) => (
  <Mutate<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/disabilities/${id}/`}
    {...props}
  />
)

export type UseDisabilitiesPartialUpdateProps = Omit<
  UseMutateProps<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  DisabilitiesPartialUpdatePathParams

/**
 * DRF ViewSet for Disability model.
 */
export const useDisabilitiesPartialUpdate = ({
  id,
  ...props
}: UseDisabilitiesPartialUpdateProps) =>
  useMutate<
    Disability,
    unknown,
    void,
    DisabilityRequestBody,
    DisabilitiesPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: DisabilitiesPartialUpdatePathParams) =>
      encode`/disabilities/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type DisabilitiesDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Disability model.
 */
export const DisabilitiesDelete = (props: DisabilitiesDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/disabilities`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseDisabilitiesDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Disability model.
 */
export const useDisabilitiesDelete = (props: UseDisabilitiesDeleteProps) =>
  useMutate<void, unknown, void, string, void>(
    'DELETE',
    encode`/disabilities`,
    {pathInlineBodyEncode: encodingFn, ...props},
  )

export type EduAnnouncementsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Announcement[]
}

export interface EduAnnouncementsListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type EduAnnouncementsListProps = Omit<
  GetProps<
    EduAnnouncementsListResponse,
    unknown,
    EduAnnouncementsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Edu announcements.
 */
export const EduAnnouncementsList = (props: EduAnnouncementsListProps) => (
  <Get<
    EduAnnouncementsListResponse,
    unknown,
    EduAnnouncementsListQueryParams,
    void
  >
    path={encode`/edu_announcements/`}
    {...props}
  />
)

export type UseEduAnnouncementsListProps = Omit<
  UseGetProps<
    EduAnnouncementsListResponse,
    unknown,
    EduAnnouncementsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Edu announcements.
 */
export const useEduAnnouncementsList = (props: UseEduAnnouncementsListProps) =>
  useGet<
    EduAnnouncementsListResponse,
    unknown,
    EduAnnouncementsListQueryParams,
    void
  >(encode`/edu_announcements/`, props)

export interface EduQuestionsCreateResponse {
  message: string
}

export type EduQuestionsCreateProps = Omit<
  MutateProps<EduQuestionsCreateResponse, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for students questions.
 */
export const EduQuestionsCreate = (props: EduQuestionsCreateProps) => (
  <Mutate<EduQuestionsCreateResponse, unknown, void, void, void>
    verb="POST"
    path={encode`/edu_questions/`}
    {...props}
  />
)

export type UseEduQuestionsCreateProps = Omit<
  UseMutateProps<EduQuestionsCreateResponse, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for students questions.
 */
export const useEduQuestionsCreate = (props: UseEduQuestionsCreateProps) =>
  useMutate<EduQuestionsCreateResponse, unknown, void, void, void>(
    'POST',
    encode`/edu_questions/`,
    props,
  )

export type EmailTemplatesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: EmailTemplateList[]
}

export interface EmailTemplatesListQueryParams {
  /**
   * A search term.
   */
  search?: string
  protected?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type EmailTemplatesListProps = Omit<
  GetProps<
    EmailTemplatesListResponse,
    unknown,
    EmailTemplatesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Email Templates.
 */
export const EmailTemplatesList = (props: EmailTemplatesListProps) => (
  <Get<EmailTemplatesListResponse, unknown, EmailTemplatesListQueryParams, void>
    path={encode`/email_templates/`}
    {...props}
  />
)

export type UseEmailTemplatesListProps = Omit<
  UseGetProps<
    EmailTemplatesListResponse,
    unknown,
    EmailTemplatesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Email Templates.
 */
export const useEmailTemplatesList = (props: UseEmailTemplatesListProps) =>
  useGet<
    EmailTemplatesListResponse,
    unknown,
    EmailTemplatesListQueryParams,
    void
  >(encode`/email_templates/`, props)

export type EmailTemplatesCreateProps = Omit<
  MutateProps<EmailTemplate, unknown, void, EmailTemplateRequestBody, void>,
  'path' | 'verb'
>

/**
 * API for Email Templates.
 */
export const EmailTemplatesCreate = (props: EmailTemplatesCreateProps) => (
  <Mutate<EmailTemplate, unknown, void, EmailTemplateRequestBody, void>
    verb="POST"
    path={encode`/email_templates/`}
    {...props}
  />
)

export type UseEmailTemplatesCreateProps = Omit<
  UseMutateProps<EmailTemplate, unknown, void, EmailTemplateRequestBody, void>,
  'path' | 'verb'
>

/**
 * API for Email Templates.
 */
export const useEmailTemplatesCreate = (props: UseEmailTemplatesCreateProps) =>
  useMutate<EmailTemplate, unknown, void, EmailTemplateRequestBody, void>(
    'POST',
    encode`/email_templates/`,
    props,
  )

export interface EmailTemplatesReadPathParams {
  /**
   * A unique integer value identifying this шаблон.
   */
  id: number
}

export type EmailTemplatesReadProps = Omit<
  GetProps<EmailTemplate, unknown, void, EmailTemplatesReadPathParams>,
  'path'
> &
  EmailTemplatesReadPathParams

/**
 * API for Email Templates.
 */
export const EmailTemplatesRead = ({id, ...props}: EmailTemplatesReadProps) => (
  <Get<EmailTemplate, unknown, void, EmailTemplatesReadPathParams>
    path={encode`/email_templates/${id}/`}
    {...props}
  />
)

export type UseEmailTemplatesReadProps = Omit<
  UseGetProps<EmailTemplate, unknown, void, EmailTemplatesReadPathParams>,
  'path'
> &
  EmailTemplatesReadPathParams

/**
 * API for Email Templates.
 */
export const useEmailTemplatesRead = ({
  id,
  ...props
}: UseEmailTemplatesReadProps) =>
  useGet<EmailTemplate, unknown, void, EmailTemplatesReadPathParams>(
    (paramsInPath: EmailTemplatesReadPathParams) =>
      encode`/email_templates/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface EmailTemplatesUpdatePathParams {
  /**
   * A unique integer value identifying this шаблон.
   */
  id: number
}

export type EmailTemplatesUpdateProps = Omit<
  MutateProps<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesUpdatePathParams
  >,
  'path' | 'verb'
> &
  EmailTemplatesUpdatePathParams

/**
 * API for Email Templates.
 */
export const EmailTemplatesUpdate = ({
  id,
  ...props
}: EmailTemplatesUpdateProps) => (
  <Mutate<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesUpdatePathParams
  >
    verb="PUT"
    path={encode`/email_templates/${id}/`}
    {...props}
  />
)

export type UseEmailTemplatesUpdateProps = Omit<
  UseMutateProps<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesUpdatePathParams
  >,
  'path' | 'verb'
> &
  EmailTemplatesUpdatePathParams

/**
 * API for Email Templates.
 */
export const useEmailTemplatesUpdate = ({
  id,
  ...props
}: UseEmailTemplatesUpdateProps) =>
  useMutate<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesUpdatePathParams
  >(
    'PUT',
    (paramsInPath: EmailTemplatesUpdatePathParams) =>
      encode`/email_templates/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface EmailTemplatesPartialUpdatePathParams {
  /**
   * A unique integer value identifying this шаблон.
   */
  id: number
}

export type EmailTemplatesPartialUpdateProps = Omit<
  MutateProps<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  EmailTemplatesPartialUpdatePathParams

/**
 * API for Email Templates.
 */
export const EmailTemplatesPartialUpdate = ({
  id,
  ...props
}: EmailTemplatesPartialUpdateProps) => (
  <Mutate<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/email_templates/${id}/`}
    {...props}
  />
)

export type UseEmailTemplatesPartialUpdateProps = Omit<
  UseMutateProps<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  EmailTemplatesPartialUpdatePathParams

/**
 * API for Email Templates.
 */
export const useEmailTemplatesPartialUpdate = ({
  id,
  ...props
}: UseEmailTemplatesPartialUpdateProps) =>
  useMutate<
    EmailTemplate,
    unknown,
    void,
    EmailTemplateRequestBody,
    EmailTemplatesPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: EmailTemplatesPartialUpdatePathParams) =>
      encode`/email_templates/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type EmailTemplatesDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * Delete existing email template.
 *
 * Allows to delete protected templates only for superusers.
 */
export const EmailTemplatesDelete = (props: EmailTemplatesDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/email_templates`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseEmailTemplatesDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * Delete existing email template.
 *
 * Allows to delete protected templates only for superusers.
 */
export const useEmailTemplatesDelete = (props: UseEmailTemplatesDeleteProps) =>
  useMutate<void, unknown, void, string, void>(
    'DELETE',
    encode`/email_templates`,
    {pathInlineBodyEncode: encodingFn, ...props},
  )

export interface EmailingResultsReadPathParams {
  /**
   * A unique integer value identifying this результат рассылки.
   */
  id: number
}

export type EmailingResultsReadProps = Omit<
  GetProps<EmailingResult, unknown, void, EmailingResultsReadPathParams>,
  'path'
> &
  EmailingResultsReadPathParams

/**
 * API for emailings results.
 */
export const EmailingResultsRead = ({
  id,
  ...props
}: EmailingResultsReadProps) => (
  <Get<EmailingResult, unknown, void, EmailingResultsReadPathParams>
    path={encode`/emailing_results/${id}/`}
    {...props}
  />
)

export type UseEmailingResultsReadProps = Omit<
  UseGetProps<EmailingResult, unknown, void, EmailingResultsReadPathParams>,
  'path'
> &
  EmailingResultsReadPathParams

/**
 * API for emailings results.
 */
export const useEmailingResultsRead = ({
  id,
  ...props
}: UseEmailingResultsReadProps) =>
  useGet<EmailingResult, unknown, void, EmailingResultsReadPathParams>(
    (paramsInPath: EmailingResultsReadPathParams) =>
      encode`/emailing_results/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type EmailingSubscriptionCreateProps = Omit<
  MutateProps<EmailingSubscriber, unknown, void, EmailingSubscriber, void>,
  'path' | 'verb'
>

/**
 * API for receiving new email subscriptions.
 */
export const EmailingSubscriptionCreate = (
  props: EmailingSubscriptionCreateProps,
) => (
  <Mutate<EmailingSubscriber, unknown, void, EmailingSubscriber, void>
    verb="POST"
    path={encode`/emailing_subscription/`}
    {...props}
  />
)

export type UseEmailingSubscriptionCreateProps = Omit<
  UseMutateProps<EmailingSubscriber, unknown, void, EmailingSubscriber, void>,
  'path' | 'verb'
>

/**
 * API for receiving new email subscriptions.
 */
export const useEmailingSubscriptionCreate = (
  props: UseEmailingSubscriptionCreateProps,
) =>
  useMutate<EmailingSubscriber, unknown, void, EmailingSubscriber, void>(
    'POST',
    encode`/emailing_subscription/`,
    props,
  )

export type EmailingsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Emailing[]
}

export interface EmailingsListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type EmailingsListProps = Omit<
  GetProps<EmailingsListResponse, unknown, EmailingsListQueryParams, void>,
  'path'
>

/**
 * API for contacts group emailing.
 */
export const EmailingsList = (props: EmailingsListProps) => (
  <Get<EmailingsListResponse, unknown, EmailingsListQueryParams, void>
    path={encode`/emailings/`}
    {...props}
  />
)

export type UseEmailingsListProps = Omit<
  UseGetProps<EmailingsListResponse, unknown, EmailingsListQueryParams, void>,
  'path'
>

/**
 * API for contacts group emailing.
 */
export const useEmailingsList = (props: UseEmailingsListProps) =>
  useGet<EmailingsListResponse, unknown, EmailingsListQueryParams, void>(
    encode`/emailings/`,
    props,
  )

export type EmailingsCreateProps = Omit<
  MutateProps<Emailing, unknown, void, Emailing, void>,
  'path' | 'verb'
>

/**
 * API for contacts group emailing.
 */
export const EmailingsCreate = (props: EmailingsCreateProps) => (
  <Mutate<Emailing, unknown, void, Emailing, void>
    verb="POST"
    path={encode`/emailings/`}
    {...props}
  />
)

export type UseEmailingsCreateProps = Omit<
  UseMutateProps<Emailing, unknown, void, Emailing, void>,
  'path' | 'verb'
>

/**
 * API for contacts group emailing.
 */
export const useEmailingsCreate = (props: UseEmailingsCreateProps) =>
  useMutate<Emailing, unknown, void, Emailing, void>(
    'POST',
    encode`/emailings/`,
    props,
  )

export interface EmailingsReadPathParams {
  /**
   * A unique integer value identifying this рассылка.
   */
  id: number
}

export type EmailingsReadProps = Omit<
  GetProps<EmailingRetrieve, unknown, void, EmailingsReadPathParams>,
  'path'
> &
  EmailingsReadPathParams

/**
 * API for contacts group emailing.
 */
export const EmailingsRead = ({id, ...props}: EmailingsReadProps) => (
  <Get<EmailingRetrieve, unknown, void, EmailingsReadPathParams>
    path={encode`/emailings/${id}/`}
    {...props}
  />
)

export type UseEmailingsReadProps = Omit<
  UseGetProps<EmailingRetrieve, unknown, void, EmailingsReadPathParams>,
  'path'
> &
  EmailingsReadPathParams

/**
 * API for contacts group emailing.
 */
export const useEmailingsRead = ({id, ...props}: UseEmailingsReadProps) =>
  useGet<EmailingRetrieve, unknown, void, EmailingsReadPathParams>(
    (paramsInPath: EmailingsReadPathParams) =>
      encode`/emailings/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface EmailingsSendEmailsPathParams {
  /**
   * A unique integer value identifying this рассылка.
   */
  id: number
}

export type EmailingsSendEmailsProps = Omit<
  MutateProps<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendEmailsPathParams
  >,
  'path' | 'verb'
> &
  EmailingsSendEmailsPathParams

/**
 * Send emails to contacts group members
 */
export const EmailingsSendEmails = ({
  id,
  ...props
}: EmailingsSendEmailsProps) => (
  <Mutate<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendEmailsPathParams
  >
    verb="POST"
    path={encode`/emailings/${id}/send_emails/`}
    {...props}
  />
)

export type UseEmailingsSendEmailsProps = Omit<
  UseMutateProps<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendEmailsPathParams
  >,
  'path' | 'verb'
> &
  EmailingsSendEmailsPathParams

/**
 * Send emails to contacts group members
 */
export const useEmailingsSendEmails = ({
  id,
  ...props
}: UseEmailingsSendEmailsProps) =>
  useMutate<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendEmailsPathParams
  >(
    'POST',
    (paramsInPath: EmailingsSendEmailsPathParams) =>
      encode`/emailings/${paramsInPath.id}/send_emails/`,
    {pathParams: {id}, ...props},
  )

export interface EmailingsSendTestEmailsPathParams {
  /**
   * A unique integer value identifying this рассылка.
   */
  id: number
}

export type EmailingsSendTestEmailsProps = Omit<
  MutateProps<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendTestEmailsPathParams
  >,
  'path' | 'verb'
> &
  EmailingsSendTestEmailsPathParams

/**
 * Send emails to test addresses
 */
export const EmailingsSendTestEmails = ({
  id,
  ...props
}: EmailingsSendTestEmailsProps) => (
  <Mutate<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendTestEmailsPathParams
  >
    verb="POST"
    path={encode`/emailings/${id}/send_test_emails/`}
    {...props}
  />
)

export type UseEmailingsSendTestEmailsProps = Omit<
  UseMutateProps<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendTestEmailsPathParams
  >,
  'path' | 'verb'
> &
  EmailingsSendTestEmailsPathParams

/**
 * Send emails to test addresses
 */
export const useEmailingsSendTestEmails = ({
  id,
  ...props
}: UseEmailingsSendTestEmailsProps) =>
  useMutate<
    EmailingResultCompact,
    unknown,
    void,
    void,
    EmailingsSendTestEmailsPathParams
  >(
    'POST',
    (paramsInPath: EmailingsSendTestEmailsPathParams) =>
      encode`/emailings/${paramsInPath.id}/send_test_emails/`,
    {pathParams: {id}, ...props},
  )

export type EventsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: EventList[]
}

export interface EventsListQueryParams {
  /**
   * A search term.
   */
  search?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type EventsListProps = Omit<
  GetProps<EventsListResponse, unknown, EventsListQueryParams, void>,
  'path'
>

/**
 * API for events.
 */
export const EventsList = (props: EventsListProps) => (
  <Get<EventsListResponse, unknown, EventsListQueryParams, void>
    path={encode`/events/`}
    {...props}
  />
)

export type UseEventsListProps = Omit<
  UseGetProps<EventsListResponse, unknown, EventsListQueryParams, void>,
  'path'
>

/**
 * API for events.
 */
export const useEventsList = (props: UseEventsListProps) =>
  useGet<EventsListResponse, unknown, EventsListQueryParams, void>(
    encode`/events/`,
    props,
  )

export type EventsCreateProps = Omit<
  MutateProps<EventPost, unknown, void, EventPost, void>,
  'path' | 'verb'
>

/**
 * API for events.
 */
export const EventsCreate = (props: EventsCreateProps) => (
  <Mutate<EventPost, unknown, void, EventPost, void>
    verb="POST"
    path={encode`/events/`}
    {...props}
  />
)

export type UseEventsCreateProps = Omit<
  UseMutateProps<EventPost, unknown, void, EventPost, void>,
  'path' | 'verb'
>

/**
 * API for events.
 */
export const useEventsCreate = (props: UseEventsCreateProps) =>
  useMutate<EventPost, unknown, void, EventPost, void>(
    'POST',
    encode`/events/`,
    props,
  )

export interface EventsRegisterResponse {
  success: boolean
}

export type EventsRegisterProps = Omit<
  MutateProps<EventsRegisterResponse, unknown, void, EventRegister, void>,
  'path' | 'verb'
>

/**
 * Registration for event.
 */
export const EventsRegister = (props: EventsRegisterProps) => (
  <Mutate<EventsRegisterResponse, unknown, void, EventRegister, void>
    verb="POST"
    path={encode`/events/register/`}
    {...props}
  />
)

export type UseEventsRegisterProps = Omit<
  UseMutateProps<EventsRegisterResponse, unknown, void, EventRegister, void>,
  'path' | 'verb'
>

/**
 * Registration for event.
 */
export const useEventsRegister = (props: UseEventsRegisterProps) =>
  useMutate<EventsRegisterResponse, unknown, void, EventRegister, void>(
    'POST',
    encode`/events/register/`,
    props,
  )

export interface EventsReadPathParams {
  id: string
}

export type EventsReadProps = Omit<
  GetProps<EventDetails, unknown, void, EventsReadPathParams>,
  'path'
> &
  EventsReadPathParams

/**
 * API for events.
 */
export const EventsRead = ({id, ...props}: EventsReadProps) => (
  <Get<EventDetails, unknown, void, EventsReadPathParams>
    path={encode`/events/${id}/`}
    {...props}
  />
)

export type UseEventsReadProps = Omit<
  UseGetProps<EventDetails, unknown, void, EventsReadPathParams>,
  'path'
> &
  EventsReadPathParams

/**
 * API for events.
 */
export const useEventsRead = ({id, ...props}: UseEventsReadProps) =>
  useGet<EventDetails, unknown, void, EventsReadPathParams>(
    (paramsInPath: EventsReadPathParams) => encode`/events/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface EventsUpdatePathParams {
  id: string
}

export type EventsUpdateProps = Omit<
  MutateProps<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsUpdatePathParams
  >,
  'path' | 'verb'
> &
  EventsUpdatePathParams

/**
 * API for events.
 */
export const EventsUpdate = ({id, ...props}: EventsUpdateProps) => (
  <Mutate<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsUpdatePathParams
  >
    verb="PUT"
    path={encode`/events/${id}/`}
    {...props}
  />
)

export type UseEventsUpdateProps = Omit<
  UseMutateProps<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsUpdatePathParams
  >,
  'path' | 'verb'
> &
  EventsUpdatePathParams

/**
 * API for events.
 */
export const useEventsUpdate = ({id, ...props}: UseEventsUpdateProps) =>
  useMutate<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsUpdatePathParams
  >(
    'PUT',
    (paramsInPath: EventsUpdatePathParams) =>
      encode`/events/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface EventsPartialUpdatePathParams {
  id: string
}

export type EventsPartialUpdateProps = Omit<
  MutateProps<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  EventsPartialUpdatePathParams

/**
 * API for events.
 */
export const EventsPartialUpdate = ({
  id,
  ...props
}: EventsPartialUpdateProps) => (
  <Mutate<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/events/${id}/`}
    {...props}
  />
)

export type UseEventsPartialUpdateProps = Omit<
  UseMutateProps<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  EventsPartialUpdatePathParams

/**
 * API for events.
 */
export const useEventsPartialUpdate = ({
  id,
  ...props
}: UseEventsPartialUpdateProps) =>
  useMutate<
    EventUpdate,
    unknown,
    void,
    EventUpdateRequestBody,
    EventsPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: EventsPartialUpdatePathParams) =>
      encode`/events/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type EventsDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * Event safe delete.
 */
export const EventsDelete = (props: EventsDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/events`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseEventsDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * Event safe delete.
 */
export const useEventsDelete = (props: UseEventsDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/events`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })

export interface EventsRegistrationFormQueryParams {
  chunk_start?: string
}

export interface EventsRegistrationFormPathParams {
  id: string
}

export type EventsRegistrationFormProps = Omit<
  GetProps<
    EventRegistrationForm,
    unknown,
    EventsRegistrationFormQueryParams,
    EventsRegistrationFormPathParams
  >,
  'path'
> &
  EventsRegistrationFormPathParams

/**
 * Return data for registration form.
 */
export const EventsRegistrationForm = ({
  id,
  ...props
}: EventsRegistrationFormProps) => (
  <Get<
    EventRegistrationForm,
    unknown,
    EventsRegistrationFormQueryParams,
    EventsRegistrationFormPathParams
  >
    path={encode`/events/${id}/registration_form/`}
    {...props}
  />
)

export type UseEventsRegistrationFormProps = Omit<
  UseGetProps<
    EventRegistrationForm,
    unknown,
    EventsRegistrationFormQueryParams,
    EventsRegistrationFormPathParams
  >,
  'path'
> &
  EventsRegistrationFormPathParams

/**
 * Return data for registration form.
 */
export const useEventsRegistrationForm = ({
  id,
  ...props
}: UseEventsRegistrationFormProps) =>
  useGet<
    EventRegistrationForm,
    unknown,
    EventsRegistrationFormQueryParams,
    EventsRegistrationFormPathParams
  >(
    (paramsInPath: EventsRegistrationFormPathParams) =>
      encode`/events/${paramsInPath.id}/registration_form/`,
    {pathParams: {id}, ...props},
  )

export type EventsCategoriesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: EventCategory[]
}

export interface EventsCategoriesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type EventsCategoriesListProps = Omit<
  GetProps<
    EventsCategoriesListResponse,
    unknown,
    EventsCategoriesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for events categories.
 */
export const EventsCategoriesList = (props: EventsCategoriesListProps) => (
  <Get<
    EventsCategoriesListResponse,
    unknown,
    EventsCategoriesListQueryParams,
    void
  >
    path={encode`/events_categories/`}
    {...props}
  />
)

export type UseEventsCategoriesListProps = Omit<
  UseGetProps<
    EventsCategoriesListResponse,
    unknown,
    EventsCategoriesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for events categories.
 */
export const useEventsCategoriesList = (props: UseEventsCategoriesListProps) =>
  useGet<
    EventsCategoriesListResponse,
    unknown,
    EventsCategoriesListQueryParams,
    void
  >(encode`/events_categories/`, props)

export type GrantConditionsCreateProps = Omit<
  MutateProps<
    GrantConditions,
    unknown,
    void,
    GrantConditionsCreateRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * API for files with grants conditions.
 */
export const GrantConditionsCreate = (props: GrantConditionsCreateProps) => (
  <Mutate<
    GrantConditions,
    unknown,
    void,
    GrantConditionsCreateRequestBody,
    void
  >
    verb="POST"
    path={encode`/grant-conditions/`}
    {...props}
  />
)

export type UseGrantConditionsCreateProps = Omit<
  UseMutateProps<
    GrantConditions,
    unknown,
    void,
    GrantConditionsCreateRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * API for files with grants conditions.
 */
export const useGrantConditionsCreate = (
  props: UseGrantConditionsCreateProps,
) =>
  useMutate<
    GrantConditions,
    unknown,
    void,
    GrantConditionsCreateRequestBody,
    void
  >('POST', encode`/grant-conditions/`, props)

export interface GrantConditionsReadPathParams {
  /**
   * A unique integer value identifying this файл с условиями гранта.
   */
  id: number
}

export type GrantConditionsReadProps = Omit<
  GetProps<GrantConditions, unknown, void, GrantConditionsReadPathParams>,
  'path'
> &
  GrantConditionsReadPathParams

/**
 * API for files with grants conditions.
 */
export const GrantConditionsRead = ({
  id,
  ...props
}: GrantConditionsReadProps) => (
  <Get<GrantConditions, unknown, void, GrantConditionsReadPathParams>
    path={encode`/grant-conditions/${id}/`}
    {...props}
  />
)

export type UseGrantConditionsReadProps = Omit<
  UseGetProps<GrantConditions, unknown, void, GrantConditionsReadPathParams>,
  'path'
> &
  GrantConditionsReadPathParams

/**
 * API for files with grants conditions.
 */
export const useGrantConditionsRead = ({
  id,
  ...props
}: UseGrantConditionsReadProps) =>
  useGet<GrantConditions, unknown, void, GrantConditionsReadPathParams>(
    (paramsInPath: GrantConditionsReadPathParams) =>
      encode`/grant-conditions/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type GrantConditionsDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for files with grants conditions.
 */
export const GrantConditionsDelete = (props: GrantConditionsDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/grant-conditions`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseGrantConditionsDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for files with grants conditions.
 */
export const useGrantConditionsDelete = (
  props: UseGrantConditionsDeleteProps,
) =>
  useMutate<void, unknown, void, string, void>(
    'DELETE',
    encode`/grant-conditions`,
    {pathInlineBodyEncode: encodingFn, ...props},
  )

export type GrantDocumentsCreateProps = Omit<
  MutateProps<
    GrantDocuments,
    unknown,
    void,
    GrantConditionsCreateRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * API for grants documents.
 */
export const GrantDocumentsCreate = (props: GrantDocumentsCreateProps) => (
  <Mutate<GrantDocuments, unknown, void, GrantConditionsCreateRequestBody, void>
    verb="POST"
    path={encode`/grant-documents/`}
    {...props}
  />
)

export type UseGrantDocumentsCreateProps = Omit<
  UseMutateProps<
    GrantDocuments,
    unknown,
    void,
    GrantConditionsCreateRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * API for grants documents.
 */
export const useGrantDocumentsCreate = (props: UseGrantDocumentsCreateProps) =>
  useMutate<
    GrantDocuments,
    unknown,
    void,
    GrantConditionsCreateRequestBody,
    void
  >('POST', encode`/grant-documents/`, props)

export interface GrantDocumentsReadPathParams {
  /**
   * A unique integer value identifying this документ гранта.
   */
  id: number
}

export type GrantDocumentsReadProps = Omit<
  GetProps<GrantDocuments, unknown, void, GrantDocumentsReadPathParams>,
  'path'
> &
  GrantDocumentsReadPathParams

/**
 * API for grants documents.
 */
export const GrantDocumentsRead = ({id, ...props}: GrantDocumentsReadProps) => (
  <Get<GrantDocuments, unknown, void, GrantDocumentsReadPathParams>
    path={encode`/grant-documents/${id}/`}
    {...props}
  />
)

export type UseGrantDocumentsReadProps = Omit<
  UseGetProps<GrantDocuments, unknown, void, GrantDocumentsReadPathParams>,
  'path'
> &
  GrantDocumentsReadPathParams

/**
 * API for grants documents.
 */
export const useGrantDocumentsRead = ({
  id,
  ...props
}: UseGrantDocumentsReadProps) =>
  useGet<GrantDocuments, unknown, void, GrantDocumentsReadPathParams>(
    (paramsInPath: GrantDocumentsReadPathParams) =>
      encode`/grant-documents/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type GrantDocumentsDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for grants documents.
 */
export const GrantDocumentsDelete = (props: GrantDocumentsDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/grant-documents`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseGrantDocumentsDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for grants documents.
 */
export const useGrantDocumentsDelete = (props: UseGrantDocumentsDeleteProps) =>
  useMutate<void, unknown, void, string, void>(
    'DELETE',
    encode`/grant-documents`,
    {pathInlineBodyEncode: encodingFn, ...props},
  )

export type GrantsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: GrantsList[]
}

export interface GrantsListQueryParams {
  status?: string
  performer?: string
  requests_opening_date__gte?: string
  requests_opening_date__lte?: string
  requests_closing_date__gte?: string
  requests_closing_date__lte?: string
  /**
   * A search term.
   */
  search?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type GrantsListProps = Omit<
  GetProps<GrantsListResponse, unknown, GrantsListQueryParams, void>,
  'path'
>

/**
 * API for grants.
 */
export const GrantsList = (props: GrantsListProps) => (
  <Get<GrantsListResponse, unknown, GrantsListQueryParams, void>
    path={encode`/grants/`}
    {...props}
  />
)

export type UseGrantsListProps = Omit<
  UseGetProps<GrantsListResponse, unknown, GrantsListQueryParams, void>,
  'path'
>

/**
 * API for grants.
 */
export const useGrantsList = (props: UseGrantsListProps) =>
  useGet<GrantsListResponse, unknown, GrantsListQueryParams, void>(
    encode`/grants/`,
    props,
  )

export type GrantsCreateProps = Omit<
  MutateProps<GrantDetails, unknown, void, GrantPostRequestBody, void>,
  'path' | 'verb'
>

/**
 * API for grants.
 */
export const GrantsCreate = (props: GrantsCreateProps) => (
  <Mutate<GrantDetails, unknown, void, GrantPostRequestBody, void>
    verb="POST"
    path={encode`/grants/`}
    {...props}
  />
)

export type UseGrantsCreateProps = Omit<
  UseMutateProps<GrantDetails, unknown, void, GrantPostRequestBody, void>,
  'path' | 'verb'
>

/**
 * API for grants.
 */
export const useGrantsCreate = (props: UseGrantsCreateProps) =>
  useMutate<GrantDetails, unknown, void, GrantPostRequestBody, void>(
    'POST',
    encode`/grants/`,
    props,
  )

export interface GrantsReadPathParams {
  /**
   * A unique integer value identifying this грант.
   */
  id: number
}

export type GrantsReadProps = Omit<
  GetProps<GrantDetails, unknown, void, GrantsReadPathParams>,
  'path'
> &
  GrantsReadPathParams

/**
 * API for grants.
 */
export const GrantsRead = ({id, ...props}: GrantsReadProps) => (
  <Get<GrantDetails, unknown, void, GrantsReadPathParams>
    path={encode`/grants/${id}/`}
    {...props}
  />
)

export type UseGrantsReadProps = Omit<
  UseGetProps<GrantDetails, unknown, void, GrantsReadPathParams>,
  'path'
> &
  GrantsReadPathParams

/**
 * API for grants.
 */
export const useGrantsRead = ({id, ...props}: UseGrantsReadProps) =>
  useGet<GrantDetails, unknown, void, GrantsReadPathParams>(
    (paramsInPath: GrantsReadPathParams) => encode`/grants/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface GrantsUpdatePathParams {
  /**
   * A unique integer value identifying this грант.
   */
  id: number
}

export type GrantsUpdateProps = Omit<
  MutateProps<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsUpdatePathParams
  >,
  'path' | 'verb'
> &
  GrantsUpdatePathParams

/**
 * API for grants.
 */
export const GrantsUpdate = ({id, ...props}: GrantsUpdateProps) => (
  <Mutate<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsUpdatePathParams
  >
    verb="PUT"
    path={encode`/grants/${id}/`}
    {...props}
  />
)

export type UseGrantsUpdateProps = Omit<
  UseMutateProps<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsUpdatePathParams
  >,
  'path' | 'verb'
> &
  GrantsUpdatePathParams

/**
 * API for grants.
 */
export const useGrantsUpdate = ({id, ...props}: UseGrantsUpdateProps) =>
  useMutate<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsUpdatePathParams
  >(
    'PUT',
    (paramsInPath: GrantsUpdatePathParams) =>
      encode`/grants/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface GrantsPartialUpdatePathParams {
  /**
   * A unique integer value identifying this грант.
   */
  id: number
}

export type GrantsPartialUpdateProps = Omit<
  MutateProps<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  GrantsPartialUpdatePathParams

/**
 * API for grants.
 */
export const GrantsPartialUpdate = ({
  id,
  ...props
}: GrantsPartialUpdateProps) => (
  <Mutate<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/grants/${id}/`}
    {...props}
  />
)

export type UseGrantsPartialUpdateProps = Omit<
  UseMutateProps<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  GrantsPartialUpdatePathParams

/**
 * API for grants.
 */
export const useGrantsPartialUpdate = ({
  id,
  ...props
}: UseGrantsPartialUpdateProps) =>
  useMutate<
    GrantDetails,
    unknown,
    void,
    GrantPostRequestBody,
    GrantsPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: GrantsPartialUpdatePathParams) =>
      encode`/grants/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type GrantsDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for grants.
 */
export const GrantsDelete = (props: GrantsDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/grants`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseGrantsDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for grants.
 */
export const useGrantsDelete = (props: UseGrantsDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/grants`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })

export type LessonVotesCreateProps = Omit<
  MutateProps<Vote, unknown, void, Vote, void>,
  'path' | 'verb'
>

/**
 * API for lesson votes.
 */
export const LessonVotesCreate = (props: LessonVotesCreateProps) => (
  <Mutate<Vote, unknown, void, Vote, void>
    verb="POST"
    path={encode`/lesson_votes/`}
    {...props}
  />
)

export type UseLessonVotesCreateProps = Omit<
  UseMutateProps<Vote, unknown, void, Vote, void>,
  'path' | 'verb'
>

/**
 * API for lesson votes.
 */
export const useLessonVotesCreate = (props: UseLessonVotesCreateProps) =>
  useMutate<Vote, unknown, void, Vote, void>(
    'POST',
    encode`/lesson_votes/`,
    props,
  )

export interface LessonVotesUpdatePathParams {
  id: string
}

export type LessonVotesUpdateProps = Omit<
  MutateProps<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesUpdatePathParams
  >,
  'path' | 'verb'
> &
  LessonVotesUpdatePathParams

/**
 * API for lesson votes.
 */
export const LessonVotesUpdate = ({id, ...props}: LessonVotesUpdateProps) => (
  <Mutate<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesUpdatePathParams
  >
    verb="PUT"
    path={encode`/lesson_votes/${id}/`}
    {...props}
  />
)

export type UseLessonVotesUpdateProps = Omit<
  UseMutateProps<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesUpdatePathParams
  >,
  'path' | 'verb'
> &
  LessonVotesUpdatePathParams

/**
 * API for lesson votes.
 */
export const useLessonVotesUpdate = ({
  id,
  ...props
}: UseLessonVotesUpdateProps) =>
  useMutate<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesUpdatePathParams
  >(
    'PUT',
    (paramsInPath: LessonVotesUpdatePathParams) =>
      encode`/lesson_votes/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface LessonVotesPartialUpdatePathParams {
  id: string
}

export type LessonVotesPartialUpdateProps = Omit<
  MutateProps<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  LessonVotesPartialUpdatePathParams

/**
 * API for lesson votes.
 */
export const LessonVotesPartialUpdate = ({
  id,
  ...props
}: LessonVotesPartialUpdateProps) => (
  <Mutate<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/lesson_votes/${id}/`}
    {...props}
  />
)

export type UseLessonVotesPartialUpdateProps = Omit<
  UseMutateProps<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  LessonVotesPartialUpdatePathParams

/**
 * API for lesson votes.
 */
export const useLessonVotesPartialUpdate = ({
  id,
  ...props
}: UseLessonVotesPartialUpdateProps) =>
  useMutate<
    VoteUpdate,
    unknown,
    void,
    VoteUpdateRequestBody,
    LessonVotesPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: LessonVotesPartialUpdatePathParams) =>
      encode`/lesson_votes/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type LessonVotesDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for lesson votes.
 */
export const LessonVotesDelete = (props: LessonVotesDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/lesson_votes`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseLessonVotesDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for lesson votes.
 */
export const useLessonVotesDelete = (props: UseLessonVotesDeleteProps) =>
  useMutate<void, unknown, void, string, void>(
    'DELETE',
    encode`/lesson_votes`,
    {pathInlineBodyEncode: encodingFn, ...props},
  )

export type LocationsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: LocationList[]
}

export interface LocationsListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type LocationsListProps = Omit<
  GetProps<LocationsListResponse, unknown, LocationsListQueryParams, void>,
  'path'
>

/**
 * API for work with museum locations.
 */
export const LocationsList = (props: LocationsListProps) => (
  <Get<LocationsListResponse, unknown, LocationsListQueryParams, void>
    path={encode`/locations/`}
    {...props}
  />
)

export type UseLocationsListProps = Omit<
  UseGetProps<LocationsListResponse, unknown, LocationsListQueryParams, void>,
  'path'
>

/**
 * API for work with museum locations.
 */
export const useLocationsList = (props: UseLocationsListProps) =>
  useGet<LocationsListResponse, unknown, LocationsListQueryParams, void>(
    encode`/locations/`,
    props,
  )

export type LocationsCreateProps = Omit<
  MutateProps<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * API for work with museum locations.
 */
export const LocationsCreate = (props: LocationsCreateProps) => (
  <Mutate<LocationWritable, unknown, void, LocationWritableRequestBody, void>
    verb="POST"
    path={encode`/locations/`}
    {...props}
  />
)

export type UseLocationsCreateProps = Omit<
  UseMutateProps<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * API for work with museum locations.
 */
export const useLocationsCreate = (props: UseLocationsCreateProps) =>
  useMutate<LocationWritable, unknown, void, LocationWritableRequestBody, void>(
    'POST',
    encode`/locations/`,
    props,
  )

export type LocationsCompactListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: LocationCompact[]
}

export interface LocationsCompactListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type LocationsCompactListProps = Omit<
  GetProps<
    LocationsCompactListResponse,
    unknown,
    LocationsCompactListQueryParams,
    void
  >,
  'path'
>

/**
 * Returns list with compact locations data
 */
export const LocationsCompactList = (props: LocationsCompactListProps) => (
  <Get<
    LocationsCompactListResponse,
    unknown,
    LocationsCompactListQueryParams,
    void
  >
    path={encode`/locations/compact_list/`}
    {...props}
  />
)

export type UseLocationsCompactListProps = Omit<
  UseGetProps<
    LocationsCompactListResponse,
    unknown,
    LocationsCompactListQueryParams,
    void
  >,
  'path'
>

/**
 * Returns list with compact locations data
 */
export const useLocationsCompactList = (props: UseLocationsCompactListProps) =>
  useGet<
    LocationsCompactListResponse,
    unknown,
    LocationsCompactListQueryParams,
    void
  >(encode`/locations/compact_list/`, props)

export interface LocationsReadPathParams {
  /**
   * A unique integer value identifying this площадка.
   */
  id: number
}

export type LocationsReadProps = Omit<
  GetProps<Location, unknown, void, LocationsReadPathParams>,
  'path'
> &
  LocationsReadPathParams

/**
 * API for work with museum locations.
 */
export const LocationsRead = ({id, ...props}: LocationsReadProps) => (
  <Get<Location, unknown, void, LocationsReadPathParams>
    path={encode`/locations/${id}/`}
    {...props}
  />
)

export type UseLocationsReadProps = Omit<
  UseGetProps<Location, unknown, void, LocationsReadPathParams>,
  'path'
> &
  LocationsReadPathParams

/**
 * API for work with museum locations.
 */
export const useLocationsRead = ({id, ...props}: UseLocationsReadProps) =>
  useGet<Location, unknown, void, LocationsReadPathParams>(
    (paramsInPath: LocationsReadPathParams) =>
      encode`/locations/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface LocationsUpdatePathParams {
  /**
   * A unique integer value identifying this площадка.
   */
  id: number
}

export type LocationsUpdateProps = Omit<
  MutateProps<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsUpdatePathParams
  >,
  'path' | 'verb'
> &
  LocationsUpdatePathParams

/**
 * API for work with museum locations.
 */
export const LocationsUpdate = ({id, ...props}: LocationsUpdateProps) => (
  <Mutate<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsUpdatePathParams
  >
    verb="PUT"
    path={encode`/locations/${id}/`}
    {...props}
  />
)

export type UseLocationsUpdateProps = Omit<
  UseMutateProps<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsUpdatePathParams
  >,
  'path' | 'verb'
> &
  LocationsUpdatePathParams

/**
 * API for work with museum locations.
 */
export const useLocationsUpdate = ({id, ...props}: UseLocationsUpdateProps) =>
  useMutate<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsUpdatePathParams
  >(
    'PUT',
    (paramsInPath: LocationsUpdatePathParams) =>
      encode`/locations/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface LocationsPartialUpdatePathParams {
  /**
   * A unique integer value identifying this площадка.
   */
  id: number
}

export type LocationsPartialUpdateProps = Omit<
  MutateProps<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  LocationsPartialUpdatePathParams

/**
 * API for work with museum locations.
 */
export const LocationsPartialUpdate = ({
  id,
  ...props
}: LocationsPartialUpdateProps) => (
  <Mutate<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/locations/${id}/`}
    {...props}
  />
)

export type UseLocationsPartialUpdateProps = Omit<
  UseMutateProps<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  LocationsPartialUpdatePathParams

/**
 * API for work with museum locations.
 */
export const useLocationsPartialUpdate = ({
  id,
  ...props
}: UseLocationsPartialUpdateProps) =>
  useMutate<
    LocationWritable,
    unknown,
    void,
    LocationWritableRequestBody,
    LocationsPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: LocationsPartialUpdatePathParams) =>
      encode`/locations/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type LocationsDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for work with museum locations.
 */
export const LocationsDelete = (props: LocationsDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/locations`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseLocationsDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for work with museum locations.
 */
export const useLocationsDelete = (props: UseLocationsDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/locations`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })

export type MetricsValuesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: MetricValue[]
}

export interface MetricsValuesListQueryParams {
  counter?: string
  metric?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type MetricsValuesListProps = Omit<
  GetProps<
    MetricsValuesListResponse,
    unknown,
    MetricsValuesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying metrics values at CRM frontend.
 */
export const MetricsValuesList = (props: MetricsValuesListProps) => (
  <Get<MetricsValuesListResponse, unknown, MetricsValuesListQueryParams, void>
    path={encode`/metrics_values/`}
    {...props}
  />
)

export type UseMetricsValuesListProps = Omit<
  UseGetProps<
    MetricsValuesListResponse,
    unknown,
    MetricsValuesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying metrics values at CRM frontend.
 */
export const useMetricsValuesList = (props: UseMetricsValuesListProps) =>
  useGet<
    MetricsValuesListResponse,
    unknown,
    MetricsValuesListQueryParams,
    void
  >(encode`/metrics_values/`, props)

export type MetroStationsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: MetroStation[]
}

export interface MetroStationsListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type MetroStationsListProps = Omit<
  GetProps<
    MetroStationsListResponse,
    unknown,
    MetroStationsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for receiving metro stations list.
 */
export const MetroStationsList = (props: MetroStationsListProps) => (
  <Get<MetroStationsListResponse, unknown, MetroStationsListQueryParams, void>
    path={encode`/metro_stations/`}
    {...props}
  />
)

export type UseMetroStationsListProps = Omit<
  UseGetProps<
    MetroStationsListResponse,
    unknown,
    MetroStationsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for receiving metro stations list.
 */
export const useMetroStationsList = (props: UseMetroStationsListProps) =>
  useGet<
    MetroStationsListResponse,
    unknown,
    MetroStationsListQueryParams,
    void
  >(encode`/metro_stations/`, props)

export interface MonitoringHealthResponse {
  success: boolean
}

export interface MonitoringHealthQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type MonitoringHealthProps = Omit<
  GetProps<MonitoringHealthResponse, void, MonitoringHealthQueryParams, void>,
  'path'
>

/**
 * Check app health
 *
 * Checks DB and Cache instances and returns 200 if everythig is OK.
 * In case of problems Exception will be rised and monitoring system
 * will receive 500 response code.
 */
export const MonitoringHealth = (props: MonitoringHealthProps) => (
  <Get<MonitoringHealthResponse, void, MonitoringHealthQueryParams, void>
    path={encode`/monitoring/health/`}
    {...props}
  />
)

export type UseMonitoringHealthProps = Omit<
  UseGetProps<
    MonitoringHealthResponse,
    void,
    MonitoringHealthQueryParams,
    void
  >,
  'path'
>

/**
 * Check app health
 *
 * Checks DB and Cache instances and returns 200 if everythig is OK.
 * In case of problems Exception will be rised and monitoring system
 * will receive 500 response code.
 */
export const useMonitoringHealth = (props: UseMonitoringHealthProps) =>
  useGet<MonitoringHealthResponse, void, MonitoringHealthQueryParams, void>(
    encode`/monitoring/health/`,
    props,
  )

export type OrganizationsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Organizations[]
}

export interface OrganizationsListQueryParams {
  /**
   * A search term.
   */
  search?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type OrganizationsListProps = Omit<
  GetProps<
    OrganizationsListResponse,
    unknown,
    OrganizationsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Contacts organizations.
 */
export const OrganizationsList = (props: OrganizationsListProps) => (
  <Get<OrganizationsListResponse, unknown, OrganizationsListQueryParams, void>
    path={encode`/organizations/`}
    {...props}
  />
)

export type UseOrganizationsListProps = Omit<
  UseGetProps<
    OrganizationsListResponse,
    unknown,
    OrganizationsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for Contacts organizations.
 */
export const useOrganizationsList = (props: UseOrganizationsListProps) =>
  useGet<
    OrganizationsListResponse,
    unknown,
    OrganizationsListQueryParams,
    void
  >(encode`/organizations/`, props)

export type PreferentialCategoriesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: PreferentialCategory[]
}

export interface PreferentialCategoriesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type PreferentialCategoriesListProps = Omit<
  GetProps<
    PreferentialCategoriesListResponse,
    unknown,
    PreferentialCategoriesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for special visitors categories.
 */
export const PreferentialCategoriesList = (
  props: PreferentialCategoriesListProps,
) => (
  <Get<
    PreferentialCategoriesListResponse,
    unknown,
    PreferentialCategoriesListQueryParams,
    void
  >
    path={encode`/preferential_categories/`}
    {...props}
  />
)

export type UsePreferentialCategoriesListProps = Omit<
  UseGetProps<
    PreferentialCategoriesListResponse,
    unknown,
    PreferentialCategoriesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for special visitors categories.
 */
export const usePreferentialCategoriesList = (
  props: UsePreferentialCategoriesListProps,
) =>
  useGet<
    PreferentialCategoriesListResponse,
    unknown,
    PreferentialCategoriesListQueryParams,
    void
  >(encode`/preferential_categories/`, props)

export type QuizListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Quiz[]
}

export interface QuizListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type QuizListProps = Omit<
  GetProps<QuizListResponse, unknown, QuizListQueryParams, void>,
  'path'
>

/**
 * Quiz API: return test information.
 */
export const QuizList = (props: QuizListProps) => (
  <Get<QuizListResponse, unknown, QuizListQueryParams, void>
    path={encode`/quiz/`}
    {...props}
  />
)

export type UseQuizListProps = Omit<
  UseGetProps<QuizListResponse, unknown, QuizListQueryParams, void>,
  'path'
>

/**
 * Quiz API: return test information.
 */
export const useQuizList = (props: UseQuizListProps) =>
  useGet<QuizListResponse, unknown, QuizListQueryParams, void>(
    encode`/quiz/`,
    props,
  )

export interface QuizReadPathParams {
  /**
   * A unique integer value identifying this тест на выбор программы.
   */
  id: number
}

export type QuizReadProps = Omit<
  GetProps<Quiz, unknown, void, QuizReadPathParams>,
  'path'
> &
  QuizReadPathParams

/**
 * Quiz API: return test information.
 */
export const QuizRead = ({id, ...props}: QuizReadProps) => (
  <Get<Quiz, unknown, void, QuizReadPathParams>
    path={encode`/quiz/${id}/`}
    {...props}
  />
)

export type UseQuizReadProps = Omit<
  UseGetProps<Quiz, unknown, void, QuizReadPathParams>,
  'path'
> &
  QuizReadPathParams

/**
 * Quiz API: return test information.
 */
export const useQuizRead = ({id, ...props}: UseQuizReadProps) =>
  useGet<Quiz, unknown, void, QuizReadPathParams>(
    (paramsInPath: QuizReadPathParams) => encode`/quiz/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type QuizResultResultProps = Omit<
  MutateProps<
    QuizTranscript,
    {
      errors?: {
        result?: string[]
      }
    },
    void,
    QuizPrepareResult,
    void
  >,
  'path' | 'verb'
>

/**
 * Returns quiz result.
 */
export const QuizResultResult = (props: QuizResultResultProps) => (
  <Mutate<
    QuizTranscript,
    {
      errors?: {
        result?: string[]
      }
    },
    void,
    QuizPrepareResult,
    void
  >
    verb="POST"
    path={encode`/quiz_result/result/`}
    {...props}
  />
)

export type UseQuizResultResultProps = Omit<
  UseMutateProps<
    QuizTranscript,
    {
      errors?: {
        result?: string[]
      }
    },
    void,
    QuizPrepareResult,
    void
  >,
  'path' | 'verb'
>

/**
 * Returns quiz result.
 */
export const useQuizResultResult = (props: UseQuizResultResultProps) =>
  useMutate<
    QuizTranscript,
    {
      errors?: {
        result?: string[]
      }
    },
    void,
    QuizPrepareResult,
    void
  >('POST', encode`/quiz_result/result/`, props)

export type ReferrersListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: Referrer[]
}

export interface ReferrersListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type ReferrersListProps = Omit<
  GetProps<ReferrersListResponse, unknown, ReferrersListQueryParams, void>,
  'path'
>

/**
 * DRF ViewSet for Referrer model.
 */
export const ReferrersList = (props: ReferrersListProps) => (
  <Get<ReferrersListResponse, unknown, ReferrersListQueryParams, void>
    path={encode`/referrers/`}
    {...props}
  />
)

export type UseReferrersListProps = Omit<
  UseGetProps<ReferrersListResponse, unknown, ReferrersListQueryParams, void>,
  'path'
>

/**
 * DRF ViewSet for Referrer model.
 */
export const useReferrersList = (props: UseReferrersListProps) =>
  useGet<ReferrersListResponse, unknown, ReferrersListQueryParams, void>(
    encode`/referrers/`,
    props,
  )

export type ReferrersCreateProps = Omit<
  MutateProps<Referrer, unknown, void, ReferrerRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Referrer model.
 */
export const ReferrersCreate = (props: ReferrersCreateProps) => (
  <Mutate<Referrer, unknown, void, ReferrerRequestBody, void>
    verb="POST"
    path={encode`/referrers/`}
    {...props}
  />
)

export type UseReferrersCreateProps = Omit<
  UseMutateProps<Referrer, unknown, void, ReferrerRequestBody, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Referrer model.
 */
export const useReferrersCreate = (props: UseReferrersCreateProps) =>
  useMutate<Referrer, unknown, void, ReferrerRequestBody, void>(
    'POST',
    encode`/referrers/`,
    props,
  )

export interface ReferrersReadPathParams {
  /**
   * A unique integer value identifying this источник посещения.
   */
  id: number
}

export type ReferrersReadProps = Omit<
  GetProps<Referrer, unknown, void, ReferrersReadPathParams>,
  'path'
> &
  ReferrersReadPathParams

/**
 * DRF ViewSet for Referrer model.
 */
export const ReferrersRead = ({id, ...props}: ReferrersReadProps) => (
  <Get<Referrer, unknown, void, ReferrersReadPathParams>
    path={encode`/referrers/${id}/`}
    {...props}
  />
)

export type UseReferrersReadProps = Omit<
  UseGetProps<Referrer, unknown, void, ReferrersReadPathParams>,
  'path'
> &
  ReferrersReadPathParams

/**
 * DRF ViewSet for Referrer model.
 */
export const useReferrersRead = ({id, ...props}: UseReferrersReadProps) =>
  useGet<Referrer, unknown, void, ReferrersReadPathParams>(
    (paramsInPath: ReferrersReadPathParams) =>
      encode`/referrers/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ReferrersUpdatePathParams {
  /**
   * A unique integer value identifying this источник посещения.
   */
  id: number
}

export type ReferrersUpdateProps = Omit<
  MutateProps<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersUpdatePathParams
  >,
  'path' | 'verb'
> &
  ReferrersUpdatePathParams

/**
 * DRF ViewSet for Referrer model.
 */
export const ReferrersUpdate = ({id, ...props}: ReferrersUpdateProps) => (
  <Mutate<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersUpdatePathParams
  >
    verb="PUT"
    path={encode`/referrers/${id}/`}
    {...props}
  />
)

export type UseReferrersUpdateProps = Omit<
  UseMutateProps<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersUpdatePathParams
  >,
  'path' | 'verb'
> &
  ReferrersUpdatePathParams

/**
 * DRF ViewSet for Referrer model.
 */
export const useReferrersUpdate = ({id, ...props}: UseReferrersUpdateProps) =>
  useMutate<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersUpdatePathParams
  >(
    'PUT',
    (paramsInPath: ReferrersUpdatePathParams) =>
      encode`/referrers/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ReferrersPartialUpdatePathParams {
  /**
   * A unique integer value identifying this источник посещения.
   */
  id: number
}

export type ReferrersPartialUpdateProps = Omit<
  MutateProps<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ReferrersPartialUpdatePathParams

/**
 * DRF ViewSet for Referrer model.
 */
export const ReferrersPartialUpdate = ({
  id,
  ...props
}: ReferrersPartialUpdateProps) => (
  <Mutate<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/referrers/${id}/`}
    {...props}
  />
)

export type UseReferrersPartialUpdateProps = Omit<
  UseMutateProps<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ReferrersPartialUpdatePathParams

/**
 * DRF ViewSet for Referrer model.
 */
export const useReferrersPartialUpdate = ({
  id,
  ...props
}: UseReferrersPartialUpdateProps) =>
  useMutate<
    Referrer,
    unknown,
    void,
    ReferrerRequestBody,
    ReferrersPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: ReferrersPartialUpdatePathParams) =>
      encode`/referrers/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type ReferrersDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Referrer model.
 */
export const ReferrersDelete = (props: ReferrersDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/referrers`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseReferrersDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * DRF ViewSet for Referrer model.
 */
export const useReferrersDelete = (props: UseReferrersDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/referrers`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })

export interface SendsaySubscribersWeekStatsQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type SendsaySubscribersWeekStatsProps = Omit<
  GetProps<
    SendSaySubscribers,
    unknown,
    SendsaySubscribersWeekStatsQueryParams,
    void
  >,
  'path'
>

/**
 * Receive amount of SendSay subscribers for week.
 */
export const SendsaySubscribersWeekStats = (
  props: SendsaySubscribersWeekStatsProps,
) => (
  <Get<
    SendSaySubscribers,
    unknown,
    SendsaySubscribersWeekStatsQueryParams,
    void
  >
    path={encode`/sendsay_subscribers/week_stats/`}
    {...props}
  />
)

export type UseSendsaySubscribersWeekStatsProps = Omit<
  UseGetProps<
    SendSaySubscribers,
    unknown,
    SendsaySubscribersWeekStatsQueryParams,
    void
  >,
  'path'
>

/**
 * Receive amount of SendSay subscribers for week.
 */
export const useSendsaySubscribersWeekStats = (
  props: UseSendsaySubscribersWeekStatsProps,
) =>
  useGet<
    SendSaySubscribers,
    unknown,
    SendsaySubscribersWeekStatsQueryParams,
    void
  >(encode`/sendsay_subscribers/week_stats/`, props)

export type SocialSubscribersListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: SocialSubscribersAmount[]
}

export interface SocialSubscribersListQueryParams {
  social?: string
  day?: string
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type SocialSubscribersListProps = Omit<
  GetProps<
    SocialSubscribersListResponse,
    unknown,
    SocialSubscribersListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying social subscribers at CRM frontend.
 */
export const SocialSubscribersList = (props: SocialSubscribersListProps) => (
  <Get<
    SocialSubscribersListResponse,
    unknown,
    SocialSubscribersListQueryParams,
    void
  >
    path={encode`/social_subscribers/`}
    {...props}
  />
)

export type UseSocialSubscribersListProps = Omit<
  UseGetProps<
    SocialSubscribersListResponse,
    unknown,
    SocialSubscribersListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying social subscribers at CRM frontend.
 */
export const useSocialSubscribersList = (
  props: UseSocialSubscribersListProps,
) =>
  useGet<
    SocialSubscribersListResponse,
    unknown,
    SocialSubscribersListQueryParams,
    void
  >(encode`/social_subscribers/`, props)

export interface SupportRequestsCreateResponse {
  message: string
}

export type SupportRequestsCreateProps = Omit<
  MutateProps<SupportRequestsCreateResponse, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for receiving support requests.
 */
export const SupportRequestsCreate = (props: SupportRequestsCreateProps) => (
  <Mutate<SupportRequestsCreateResponse, unknown, void, void, void>
    verb="POST"
    path={encode`/support_requests/`}
    {...props}
  />
)

export type UseSupportRequestsCreateProps = Omit<
  UseMutateProps<SupportRequestsCreateResponse, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for receiving support requests.
 */
export const useSupportRequestsCreate = (
  props: UseSupportRequestsCreateProps,
) =>
  useMutate<SupportRequestsCreateResponse, unknown, void, void, void>(
    'POST',
    encode`/support_requests/`,
    props,
  )

export type TicketTypesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: TicketType[]
}

export interface TicketTypesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type TicketTypesListProps = Omit<
  GetProps<TicketTypesListResponse, unknown, TicketTypesListQueryParams, void>,
  'path'
>

/**
 * API for events ticket types.
 */
export const TicketTypesList = (props: TicketTypesListProps) => (
  <Get<TicketTypesListResponse, unknown, TicketTypesListQueryParams, void>
    path={encode`/ticket_types/`}
    {...props}
  />
)

export type UseTicketTypesListProps = Omit<
  UseGetProps<
    TicketTypesListResponse,
    unknown,
    TicketTypesListQueryParams,
    void
  >,
  'path'
>

/**
 * API for events ticket types.
 */
export const useTicketTypesList = (props: UseTicketTypesListProps) =>
  useGet<TicketTypesListResponse, unknown, TicketTypesListQueryParams, void>(
    encode`/ticket_types/`,
    props,
  )

export type TrackAccessListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: TrackAccess[]
}

export interface TrackAccessListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type TrackAccessListProps = Omit<
  GetProps<TrackAccessListResponse, unknown, TrackAccessListQueryParams, void>,
  'path'
>

/**
 * API for TrackAccess.
 */
export const TrackAccessList = (props: TrackAccessListProps) => (
  <Get<TrackAccessListResponse, unknown, TrackAccessListQueryParams, void>
    path={encode`/track_access/`}
    {...props}
  />
)

export type UseTrackAccessListProps = Omit<
  UseGetProps<
    TrackAccessListResponse,
    unknown,
    TrackAccessListQueryParams,
    void
  >,
  'path'
>

/**
 * API for TrackAccess.
 */
export const useTrackAccessList = (props: UseTrackAccessListProps) =>
  useGet<TrackAccessListResponse, unknown, TrackAccessListQueryParams, void>(
    encode`/track_access/`,
    props,
  )

export interface TrackAccessReadPathParams {
  id: string
}

export type TrackAccessReadProps = Omit<
  GetProps<TrackAccess, unknown, void, TrackAccessReadPathParams>,
  'path'
> &
  TrackAccessReadPathParams

/**
 * API for TrackAccess.
 */
export const TrackAccessRead = ({id, ...props}: TrackAccessReadProps) => (
  <Get<TrackAccess, unknown, void, TrackAccessReadPathParams>
    path={encode`/track_access/${id}/`}
    {...props}
  />
)

export type UseTrackAccessReadProps = Omit<
  UseGetProps<TrackAccess, unknown, void, TrackAccessReadPathParams>,
  'path'
> &
  TrackAccessReadPathParams

/**
 * API for TrackAccess.
 */
export const useTrackAccessRead = ({id, ...props}: UseTrackAccessReadProps) =>
  useGet<TrackAccess, unknown, void, TrackAccessReadPathParams>(
    (paramsInPath: TrackAccessReadPathParams) =>
      encode`/track_access/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface TrackAccessCancelPathParams {
  id: string
}

export type TrackAccessCancelProps = Omit<
  MutateProps<void, unknown, void, string, TrackAccessCancelPathParams>,
  'path' | 'verb'
> &
  TrackAccessCancelPathParams

/**
 * Cancel lesson registration.
 */
export const TrackAccessCancel = ({id, ...props}: TrackAccessCancelProps) => (
  <Mutate<void, unknown, void, string, TrackAccessCancelPathParams>
    verb="DELETE"
    path={encode`/track_access/${id}/cancel`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseTrackAccessCancelProps = Omit<
  UseMutateProps<void, unknown, void, string, TrackAccessCancelPathParams>,
  'path' | 'verb'
> &
  TrackAccessCancelPathParams

/**
 * Cancel lesson registration.
 */
export const useTrackAccessCancel = ({
  id,
  ...props
}: UseTrackAccessCancelProps) =>
  useMutate<void, unknown, void, string, TrackAccessCancelPathParams>(
    'DELETE',
    (paramsInPath: TrackAccessCancelPathParams) =>
      encode`/track_access/${paramsInPath.id}/cancel`,
    {pathInlineBodyEncode: encodingFn, pathParams: {id}, ...props},
  )

export interface TrackAccessGetLessonPathParams {
  id: string
  lesson_pk: string
}

export type TrackAccessGetLessonProps = Omit<
  GetProps<Lesson, unknown, void, TrackAccessGetLessonPathParams>,
  'path'
> &
  TrackAccessGetLessonPathParams

/**
 * Returns lesson data.
 */
export const TrackAccessGetLesson = ({
  id,
  lesson_pk,
  ...props
}: TrackAccessGetLessonProps) => (
  <Get<Lesson, unknown, void, TrackAccessGetLessonPathParams>
    path={encode`/track_access/${id}/lessons/${lesson_pk}/`}
    {...props}
  />
)

export type UseTrackAccessGetLessonProps = Omit<
  UseGetProps<Lesson, unknown, void, TrackAccessGetLessonPathParams>,
  'path'
> &
  TrackAccessGetLessonPathParams

/**
 * Returns lesson data.
 */
export const useTrackAccessGetLesson = ({
  id,
  lesson_pk,
  ...props
}: UseTrackAccessGetLessonProps) =>
  useGet<Lesson, unknown, void, TrackAccessGetLessonPathParams>(
    (paramsInPath: TrackAccessGetLessonPathParams) =>
      encode`/track_access/${paramsInPath.id}/lessons/${paramsInPath.lesson_pk}/`,
    {pathParams: {id, lesson_pk}, ...props},
  )

export interface TrackAccessRegisterReadPathParams {
  id: string
  lesson_pk: string
}

export type TrackAccessRegisterReadProps = Omit<
  GetProps<ScheduledLesson[], unknown, void, TrackAccessRegisterReadPathParams>,
  'path'
> &
  TrackAccessRegisterReadPathParams

/**
 * Lesson registration.
 */
export const TrackAccessRegisterRead = ({
  id,
  lesson_pk,
  ...props
}: TrackAccessRegisterReadProps) => (
  <Get<ScheduledLesson[], unknown, void, TrackAccessRegisterReadPathParams>
    path={encode`/track_access/${id}/register/${lesson_pk}/`}
    {...props}
  />
)

export type UseTrackAccessRegisterReadProps = Omit<
  UseGetProps<
    ScheduledLesson[],
    unknown,
    void,
    TrackAccessRegisterReadPathParams
  >,
  'path'
> &
  TrackAccessRegisterReadPathParams

/**
 * Lesson registration.
 */
export const useTrackAccessRegisterRead = ({
  id,
  lesson_pk,
  ...props
}: UseTrackAccessRegisterReadProps) =>
  useGet<ScheduledLesson[], unknown, void, TrackAccessRegisterReadPathParams>(
    (paramsInPath: TrackAccessRegisterReadPathParams) =>
      encode`/track_access/${paramsInPath.id}/register/${paramsInPath.lesson_pk}/`,
    {pathParams: {id, lesson_pk}, ...props},
  )

export interface TrackAccessRegisterCreatePathParams {
  id: string
  lesson_pk: string
}

export type TrackAccessRegisterCreateProps = Omit<
  MutateProps<
    LessonAssignment,
    unknown,
    void,
    LessonAssignmentPost,
    TrackAccessRegisterCreatePathParams
  >,
  'path' | 'verb'
> &
  TrackAccessRegisterCreatePathParams

/**
 * Lesson registration.
 */
export const TrackAccessRegisterCreate = ({
  id,
  lesson_pk,
  ...props
}: TrackAccessRegisterCreateProps) => (
  <Mutate<
    LessonAssignment,
    unknown,
    void,
    LessonAssignmentPost,
    TrackAccessRegisterCreatePathParams
  >
    verb="POST"
    path={encode`/track_access/${id}/register/${lesson_pk}/`}
    {...props}
  />
)

export type UseTrackAccessRegisterCreateProps = Omit<
  UseMutateProps<
    LessonAssignment,
    unknown,
    void,
    LessonAssignmentPost,
    TrackAccessRegisterCreatePathParams
  >,
  'path' | 'verb'
> &
  TrackAccessRegisterCreatePathParams

/**
 * Lesson registration.
 */
export const useTrackAccessRegisterCreate = ({
  id,
  lesson_pk,
  ...props
}: UseTrackAccessRegisterCreateProps) =>
  useMutate<
    LessonAssignment,
    unknown,
    void,
    LessonAssignmentPost,
    TrackAccessRegisterCreatePathParams
  >(
    'POST',
    (paramsInPath: TrackAccessRegisterCreatePathParams) =>
      encode`/track_access/${paramsInPath.id}/register/${paramsInPath.lesson_pk}/`,
    {pathParams: {id, lesson_pk}, ...props},
  )

export type TutorPhotoCreateProps = Omit<
  MutateProps<TutorPhoto, unknown, void, TutorPhoto, void>,
  'path' | 'verb'
>

/**
 * API for contacts photos.
 */
export const TutorPhotoCreate = (props: TutorPhotoCreateProps) => (
  <Mutate<TutorPhoto, unknown, void, TutorPhoto, void>
    verb="POST"
    path={encode`/tutor_photo/`}
    {...props}
  />
)

export type UseTutorPhotoCreateProps = Omit<
  UseMutateProps<TutorPhoto, unknown, void, TutorPhoto, void>,
  'path' | 'verb'
>

/**
 * API for contacts photos.
 */
export const useTutorPhotoCreate = (props: UseTutorPhotoCreateProps) =>
  useMutate<TutorPhoto, unknown, void, TutorPhoto, void>(
    'POST',
    encode`/tutor_photo/`,
    props,
  )

export interface TutorPhotoReadPathParams {
  /**
   * A unique integer value identifying this фотография преподавателя.
   */
  id: number
}

export type TutorPhotoReadProps = Omit<
  GetProps<TutorPhoto, unknown, void, TutorPhotoReadPathParams>,
  'path'
> &
  TutorPhotoReadPathParams

/**
 * API for contacts photos.
 */
export const TutorPhotoRead = ({id, ...props}: TutorPhotoReadProps) => (
  <Get<TutorPhoto, unknown, void, TutorPhotoReadPathParams>
    path={encode`/tutor_photo/${id}/`}
    {...props}
  />
)

export type UseTutorPhotoReadProps = Omit<
  UseGetProps<TutorPhoto, unknown, void, TutorPhotoReadPathParams>,
  'path'
> &
  TutorPhotoReadPathParams

/**
 * API for contacts photos.
 */
export const useTutorPhotoRead = ({id, ...props}: UseTutorPhotoReadProps) =>
  useGet<TutorPhoto, unknown, void, TutorPhotoReadPathParams>(
    (paramsInPath: TutorPhotoReadPathParams) =>
      encode`/tutor_photo/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface TutorsScheduleQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type TutorsScheduleProps = Omit<
  GetProps<TutorSchedule[], unknown, TutorsScheduleQueryParams, void>,
  'path'
>

/**
 * Returns tutor schedule.
 */
export const TutorsSchedule = (props: TutorsScheduleProps) => (
  <Get<TutorSchedule[], unknown, TutorsScheduleQueryParams, void>
    path={encode`/tutors/schedule/`}
    {...props}
  />
)

export type UseTutorsScheduleProps = Omit<
  UseGetProps<TutorSchedule[], unknown, TutorsScheduleQueryParams, void>,
  'path'
>

/**
 * Returns tutor schedule.
 */
export const useTutorsSchedule = (props: UseTutorsScheduleProps) =>
  useGet<TutorSchedule[], unknown, TutorsScheduleQueryParams, void>(
    encode`/tutors/schedule/`,
    props,
  )

export interface TutorsSlotQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export interface TutorsSlotPathParams {
  slot_pk: string
}

export type TutorsSlotProps = Omit<
  GetProps<TutorTimeslot, unknown, TutorsSlotQueryParams, TutorsSlotPathParams>,
  'path'
> &
  TutorsSlotPathParams

/**
 * Returns info about lesson timeslot for tutor.
 */
export const TutorsSlot = ({slot_pk, ...props}: TutorsSlotProps) => (
  <Get<TutorTimeslot, unknown, TutorsSlotQueryParams, TutorsSlotPathParams>
    path={encode`/tutors/slot/${slot_pk}/`}
    {...props}
  />
)

export type UseTutorsSlotProps = Omit<
  UseGetProps<
    TutorTimeslot,
    unknown,
    TutorsSlotQueryParams,
    TutorsSlotPathParams
  >,
  'path'
> &
  TutorsSlotPathParams

/**
 * Returns info about lesson timeslot for tutor.
 */
export const useTutorsSlot = ({slot_pk, ...props}: UseTutorsSlotProps) =>
  useGet<TutorTimeslot, unknown, TutorsSlotQueryParams, TutorsSlotPathParams>(
    (paramsInPath: TutorsSlotPathParams) =>
      encode`/tutors/slot/${paramsInPath.slot_pk}/`,
    {pathParams: {slot_pk}, ...props},
  )

export interface TutorsReadPathParams {
  id: string
}

export type TutorsReadProps = Omit<
  GetProps<TutorTimeslot, unknown, void, TutorsReadPathParams>,
  'path'
> &
  TutorsReadPathParams

/**
 * API for tutors data - schedule and etc.
 */
export const TutorsRead = ({id, ...props}: TutorsReadProps) => (
  <Get<TutorTimeslot, unknown, void, TutorsReadPathParams>
    path={encode`/tutors/${id}/`}
    {...props}
  />
)

export type UseTutorsReadProps = Omit<
  UseGetProps<TutorTimeslot, unknown, void, TutorsReadPathParams>,
  'path'
> &
  TutorsReadPathParams

/**
 * API for tutors data - schedule and etc.
 */
export const useTutorsRead = ({id, ...props}: UseTutorsReadProps) =>
  useGet<TutorTimeslot, unknown, void, TutorsReadPathParams>(
    (paramsInPath: TutorsReadPathParams) => encode`/tutors/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface TutorsUpdatePathParams {
  id: string
}

export type TutorsUpdateProps = Omit<
  MutateProps<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsUpdatePathParams
  >,
  'path' | 'verb'
> &
  TutorsUpdatePathParams

/**
 * API for tutors data - schedule and etc.
 */
export const TutorsUpdate = ({id, ...props}: TutorsUpdateProps) => (
  <Mutate<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsUpdatePathParams
  >
    verb="PUT"
    path={encode`/tutors/${id}/`}
    {...props}
  />
)

export type UseTutorsUpdateProps = Omit<
  UseMutateProps<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsUpdatePathParams
  >,
  'path' | 'verb'
> &
  TutorsUpdatePathParams

/**
 * API for tutors data - schedule and etc.
 */
export const useTutorsUpdate = ({id, ...props}: UseTutorsUpdateProps) =>
  useMutate<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsUpdatePathParams
  >(
    'PUT',
    (paramsInPath: TutorsUpdatePathParams) =>
      encode`/tutors/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface TutorsPartialUpdatePathParams {
  id: string
}

export type TutorsPartialUpdateProps = Omit<
  MutateProps<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  TutorsPartialUpdatePathParams

/**
 * API for tutors data - schedule and etc.
 */
export const TutorsPartialUpdate = ({
  id,
  ...props
}: TutorsPartialUpdateProps) => (
  <Mutate<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/tutors/${id}/`}
    {...props}
  />
)

export type UseTutorsPartialUpdateProps = Omit<
  UseMutateProps<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  TutorsPartialUpdatePathParams

/**
 * API for tutors data - schedule and etc.
 */
export const useTutorsPartialUpdate = ({
  id,
  ...props
}: UseTutorsPartialUpdateProps) =>
  useMutate<
    TutorProfile,
    unknown,
    void,
    TutorUpdateRequestBody,
    TutorsPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: TutorsPartialUpdatePathParams) =>
      encode`/tutors/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type UserAvatarCreateProps = Omit<
  MutateProps<UserAvatar, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for user avatars.
 */
export const UserAvatarCreate = (props: UserAvatarCreateProps) => (
  <Mutate<UserAvatar, unknown, void, void, void>
    verb="POST"
    path={encode`/user_avatar/`}
    {...props}
  />
)

export type UseUserAvatarCreateProps = Omit<
  UseMutateProps<UserAvatar, unknown, void, void, void>,
  'path' | 'verb'
>

/**
 * API for user avatars.
 */
export const useUserAvatarCreate = (props: UseUserAvatarCreateProps) =>
  useMutate<UserAvatar, unknown, void, void, void>(
    'POST',
    encode`/user_avatar/`,
    props,
  )

export interface UserAvatarReadPathParams {
  /**
   * A unique integer value identifying this аватар пользователя.
   */
  id: number
}

export type UserAvatarReadProps = Omit<
  GetProps<UserAvatar, unknown, void, UserAvatarReadPathParams>,
  'path'
> &
  UserAvatarReadPathParams

/**
 * API for user avatars.
 */
export const UserAvatarRead = ({id, ...props}: UserAvatarReadProps) => (
  <Get<UserAvatar, unknown, void, UserAvatarReadPathParams>
    path={encode`/user_avatar/${id}/`}
    {...props}
  />
)

export type UseUserAvatarReadProps = Omit<
  UseGetProps<UserAvatar, unknown, void, UserAvatarReadPathParams>,
  'path'
> &
  UserAvatarReadPathParams

/**
 * API for user avatars.
 */
export const useUserAvatarRead = ({id, ...props}: UseUserAvatarReadProps) =>
  useGet<UserAvatar, unknown, void, UserAvatarReadPathParams>(
    (paramsInPath: UserAvatarReadPathParams) =>
      encode`/user_avatar/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type UserSegmentsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: UserSegment[]
}

export interface UserSegmentsListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type UserSegmentsListProps = Omit<
  GetProps<
    UserSegmentsListResponse,
    unknown,
    UserSegmentsListQueryParams,
    void
  >,
  'path'
>

/**
 * CRUD API for Users/Contacts segments.
 */
export const UserSegmentsList = (props: UserSegmentsListProps) => (
  <Get<UserSegmentsListResponse, unknown, UserSegmentsListQueryParams, void>
    path={encode`/user_segments/`}
    {...props}
  />
)

export type UseUserSegmentsListProps = Omit<
  UseGetProps<
    UserSegmentsListResponse,
    unknown,
    UserSegmentsListQueryParams,
    void
  >,
  'path'
>

/**
 * CRUD API for Users/Contacts segments.
 */
export const useUserSegmentsList = (props: UseUserSegmentsListProps) =>
  useGet<UserSegmentsListResponse, unknown, UserSegmentsListQueryParams, void>(
    encode`/user_segments/`,
    props,
  )

export type UserSegmentsCreateProps = Omit<
  MutateProps<UserSegment, unknown, void, UserSegmentRequestBody, void>,
  'path' | 'verb'
>

/**
 * CRUD API for Users/Contacts segments.
 */
export const UserSegmentsCreate = (props: UserSegmentsCreateProps) => (
  <Mutate<UserSegment, unknown, void, UserSegmentRequestBody, void>
    verb="POST"
    path={encode`/user_segments/`}
    {...props}
  />
)

export type UseUserSegmentsCreateProps = Omit<
  UseMutateProps<UserSegment, unknown, void, UserSegmentRequestBody, void>,
  'path' | 'verb'
>

/**
 * CRUD API for Users/Contacts segments.
 */
export const useUserSegmentsCreate = (props: UseUserSegmentsCreateProps) =>
  useMutate<UserSegment, unknown, void, UserSegmentRequestBody, void>(
    'POST',
    encode`/user_segments/`,
    props,
  )

export interface UserSegmentsReadPathParams {
  /**
   * A unique integer value identifying this сегмент.
   */
  id: number
}

export type UserSegmentsReadProps = Omit<
  GetProps<UserSegment, unknown, void, UserSegmentsReadPathParams>,
  'path'
> &
  UserSegmentsReadPathParams

/**
 * CRUD API for Users/Contacts segments.
 */
export const UserSegmentsRead = ({id, ...props}: UserSegmentsReadProps) => (
  <Get<UserSegment, unknown, void, UserSegmentsReadPathParams>
    path={encode`/user_segments/${id}/`}
    {...props}
  />
)

export type UseUserSegmentsReadProps = Omit<
  UseGetProps<UserSegment, unknown, void, UserSegmentsReadPathParams>,
  'path'
> &
  UserSegmentsReadPathParams

/**
 * CRUD API for Users/Contacts segments.
 */
export const useUserSegmentsRead = ({id, ...props}: UseUserSegmentsReadProps) =>
  useGet<UserSegment, unknown, void, UserSegmentsReadPathParams>(
    (paramsInPath: UserSegmentsReadPathParams) =>
      encode`/user_segments/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface UserSegmentsUpdatePathParams {
  /**
   * A unique integer value identifying this сегмент.
   */
  id: number
}

export type UserSegmentsUpdateProps = Omit<
  MutateProps<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsUpdatePathParams
  >,
  'path' | 'verb'
> &
  UserSegmentsUpdatePathParams

/**
 * CRUD API for Users/Contacts segments.
 */
export const UserSegmentsUpdate = ({id, ...props}: UserSegmentsUpdateProps) => (
  <Mutate<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsUpdatePathParams
  >
    verb="PUT"
    path={encode`/user_segments/${id}/`}
    {...props}
  />
)

export type UseUserSegmentsUpdateProps = Omit<
  UseMutateProps<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsUpdatePathParams
  >,
  'path' | 'verb'
> &
  UserSegmentsUpdatePathParams

/**
 * CRUD API for Users/Contacts segments.
 */
export const useUserSegmentsUpdate = ({
  id,
  ...props
}: UseUserSegmentsUpdateProps) =>
  useMutate<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsUpdatePathParams
  >(
    'PUT',
    (paramsInPath: UserSegmentsUpdatePathParams) =>
      encode`/user_segments/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface UserSegmentsPartialUpdatePathParams {
  /**
   * A unique integer value identifying this сегмент.
   */
  id: number
}

export type UserSegmentsPartialUpdateProps = Omit<
  MutateProps<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  UserSegmentsPartialUpdatePathParams

/**
 * CRUD API for Users/Contacts segments.
 */
export const UserSegmentsPartialUpdate = ({
  id,
  ...props
}: UserSegmentsPartialUpdateProps) => (
  <Mutate<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/user_segments/${id}/`}
    {...props}
  />
)

export type UseUserSegmentsPartialUpdateProps = Omit<
  UseMutateProps<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  UserSegmentsPartialUpdatePathParams

/**
 * CRUD API for Users/Contacts segments.
 */
export const useUserSegmentsPartialUpdate = ({
  id,
  ...props
}: UseUserSegmentsPartialUpdateProps) =>
  useMutate<
    UserSegment,
    unknown,
    void,
    UserSegmentRequestBody,
    UserSegmentsPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: UserSegmentsPartialUpdatePathParams) =>
      encode`/user_segments/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type UserSegmentsDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * CRUD API for Users/Contacts segments.
 */
export const UserSegmentsDelete = (props: UserSegmentsDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/user_segments`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseUserSegmentsDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * CRUD API for Users/Contacts segments.
 */
export const useUserSegmentsDelete = (props: UseUserSegmentsDeleteProps) =>
  useMutate<void, unknown, void, string, void>(
    'DELETE',
    encode`/user_segments`,
    {pathInlineBodyEncode: encodingFn, ...props},
  )

export type UsersListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: User[]
}

export interface UsersListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type UsersListProps = Omit<
  GetProps<UsersListResponse, unknown, UsersListQueryParams, void>,
  'path'
>

/**
 * User profile API.
 */
export const UsersList = (props: UsersListProps) => (
  <Get<UsersListResponse, unknown, UsersListQueryParams, void>
    path={encode`/users/`}
    {...props}
  />
)

export type UseUsersListProps = Omit<
  UseGetProps<UsersListResponse, unknown, UsersListQueryParams, void>,
  'path'
>

/**
 * User profile API.
 */
export const useUsersList = (props: UseUsersListProps) =>
  useGet<UsersListResponse, unknown, UsersListQueryParams, void>(
    encode`/users/`,
    props,
  )

export interface UsersCheckUserResponse {
  allow_auth: boolean
  allow_reg: boolean
}

export interface UsersCheckUserRequestBody {
  email: string
}

export type UsersCheckUserProps = Omit<
  MutateProps<
    UsersCheckUserResponse,
    unknown,
    void,
    UsersCheckUserRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Check user exists by email and allowed to auth and/or register.
 */
export const UsersCheckUser = (props: UsersCheckUserProps) => (
  <Mutate<
    UsersCheckUserResponse,
    unknown,
    void,
    UsersCheckUserRequestBody,
    void
  >
    verb="POST"
    path={encode`/users/check_user/`}
    {...props}
  />
)

export type UseUsersCheckUserProps = Omit<
  UseMutateProps<
    UsersCheckUserResponse,
    unknown,
    void,
    UsersCheckUserRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Check user exists by email and allowed to auth and/or register.
 */
export const useUsersCheckUser = (props: UseUsersCheckUserProps) =>
  useMutate<
    UsersCheckUserResponse,
    unknown,
    void,
    UsersCheckUserRequestBody,
    void
  >('POST', encode`/users/check_user/`, props)

export type UsersCompactListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: CompactUser[]
}

export interface UsersCompactListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type UsersCompactListProps = Omit<
  GetProps<
    UsersCompactListResponse,
    unknown,
    UsersCompactListQueryParams,
    void
  >,
  'path'
>

/**
 * Returns list with compact Users data
 */
export const UsersCompactList = (props: UsersCompactListProps) => (
  <Get<UsersCompactListResponse, unknown, UsersCompactListQueryParams, void>
    path={encode`/users/compact_list/`}
    {...props}
  />
)

export type UseUsersCompactListProps = Omit<
  UseGetProps<
    UsersCompactListResponse,
    unknown,
    UsersCompactListQueryParams,
    void
  >,
  'path'
>

/**
 * Returns list with compact Users data
 */
export const useUsersCompactList = (props: UseUsersCompactListProps) =>
  useGet<UsersCompactListResponse, unknown, UsersCompactListQueryParams, void>(
    encode`/users/compact_list/`,
    props,
  )

export interface UsersEduPasswordResetResponse {
  success: boolean
}

export type UsersEduPasswordResetProps = Omit<
  MutateProps<
    UsersEduPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Reset Edu user password.
 */
export const UsersEduPasswordReset = (props: UsersEduPasswordResetProps) => (
  <Mutate<
    UsersEduPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >
    verb="POST"
    path={encode`/users/edu_password_reset/`}
    {...props}
  />
)

export type UseUsersEduPasswordResetProps = Omit<
  UseMutateProps<
    UsersEduPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Reset Edu user password.
 */
export const useUsersEduPasswordReset = (
  props: UseUsersEduPasswordResetProps,
) =>
  useMutate<
    UsersEduPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >('POST', encode`/users/edu_password_reset/`, props)

export interface UsersEduPasswordResetConfirmResponse {
  success: boolean
}

export type UsersEduPasswordResetConfirmProps = Omit<
  MutateProps<
    UsersEduPasswordResetConfirmResponse,
    unknown,
    void,
    EduPasswordResetConfirm,
    void
  >,
  'path' | 'verb'
>

/**
 * Confirm Edu user's password reset.
 */
export const UsersEduPasswordResetConfirm = (
  props: UsersEduPasswordResetConfirmProps,
) => (
  <Mutate<
    UsersEduPasswordResetConfirmResponse,
    unknown,
    void,
    EduPasswordResetConfirm,
    void
  >
    verb="POST"
    path={encode`/users/edu_password_reset_confirm/`}
    {...props}
  />
)

export type UseUsersEduPasswordResetConfirmProps = Omit<
  UseMutateProps<
    UsersEduPasswordResetConfirmResponse,
    unknown,
    void,
    EduPasswordResetConfirm,
    void
  >,
  'path' | 'verb'
>

/**
 * Confirm Edu user's password reset.
 */
export const useUsersEduPasswordResetConfirm = (
  props: UseUsersEduPasswordResetConfirmProps,
) =>
  useMutate<
    UsersEduPasswordResetConfirmResponse,
    unknown,
    void,
    EduPasswordResetConfirm,
    void
  >('POST', encode`/users/edu_password_reset_confirm/`, props)

export interface UsersEduProfileQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type UsersEduProfileProps = Omit<
  GetProps<EduUser, unknown, UsersEduProfileQueryParams, void>,
  'path'
>

/**
 * Return Edu User his own Edu profile data.
 */
export const UsersEduProfile = (props: UsersEduProfileProps) => (
  <Get<EduUser, unknown, UsersEduProfileQueryParams, void>
    path={encode`/users/edu_profile/`}
    {...props}
  />
)

export type UseUsersEduProfileProps = Omit<
  UseGetProps<EduUser, unknown, UsersEduProfileQueryParams, void>,
  'path'
>

/**
 * Return Edu User his own Edu profile data.
 */
export const useUsersEduProfile = (props: UseUsersEduProfileProps) =>
  useGet<EduUser, unknown, UsersEduProfileQueryParams, void>(
    encode`/users/edu_profile/`,
    props,
  )

export interface UsersPasswordChangeResponse {
  success: boolean
}

export interface UsersPasswordChangeRequestBody {
  old_password: string
  new_password1: string
  new_password2: string
}

export type UsersPasswordChangeProps = Omit<
  MutateProps<
    UsersPasswordChangeResponse,
    unknown,
    void,
    UsersPasswordChangeRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Change user password.
 */
export const UsersPasswordChange = (props: UsersPasswordChangeProps) => (
  <Mutate<
    UsersPasswordChangeResponse,
    unknown,
    void,
    UsersPasswordChangeRequestBody,
    void
  >
    verb="POST"
    path={encode`/users/password_change/`}
    {...props}
  />
)

export type UseUsersPasswordChangeProps = Omit<
  UseMutateProps<
    UsersPasswordChangeResponse,
    unknown,
    void,
    UsersPasswordChangeRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Change user password.
 */
export const useUsersPasswordChange = (props: UseUsersPasswordChangeProps) =>
  useMutate<
    UsersPasswordChangeResponse,
    unknown,
    void,
    UsersPasswordChangeRequestBody,
    void
  >('POST', encode`/users/password_change/`, props)

export interface UsersPasswordResetResponse {
  success: boolean
}

export type UsersPasswordResetProps = Omit<
  MutateProps<
    UsersPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Reset user password.
 */
export const UsersPasswordReset = (props: UsersPasswordResetProps) => (
  <Mutate<
    UsersPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >
    verb="POST"
    path={encode`/users/password_reset/`}
    {...props}
  />
)

export type UseUsersPasswordResetProps = Omit<
  UseMutateProps<
    UsersPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Reset user password.
 */
export const useUsersPasswordReset = (props: UseUsersPasswordResetProps) =>
  useMutate<
    UsersPasswordResetResponse,
    unknown,
    void,
    UsersEduPasswordResetDataRequestBody,
    void
  >('POST', encode`/users/password_reset/`, props)

export interface UsersPasswordResetConfirmResponse {
  success: boolean
}

export type UsersPasswordResetConfirmProps = Omit<
  MutateProps<
    UsersPasswordResetConfirmResponse,
    unknown,
    void,
    PasswordResetConfirm,
    void
  >,
  'path' | 'verb'
>

/**
 * Confirm user's password reset.
 */
export const UsersPasswordResetConfirm = (
  props: UsersPasswordResetConfirmProps,
) => (
  <Mutate<
    UsersPasswordResetConfirmResponse,
    unknown,
    void,
    PasswordResetConfirm,
    void
  >
    verb="POST"
    path={encode`/users/password_reset_confirm/`}
    {...props}
  />
)

export type UseUsersPasswordResetConfirmProps = Omit<
  UseMutateProps<
    UsersPasswordResetConfirmResponse,
    unknown,
    void,
    PasswordResetConfirm,
    void
  >,
  'path' | 'verb'
>

/**
 * Confirm user's password reset.
 */
export const useUsersPasswordResetConfirm = (
  props: UseUsersPasswordResetConfirmProps,
) =>
  useMutate<
    UsersPasswordResetConfirmResponse,
    unknown,
    void,
    PasswordResetConfirm,
    void
  >('POST', encode`/users/password_reset_confirm/`, props)

export interface UsersProfileQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type UsersProfileProps = Omit<
  GetProps<User, unknown, UsersProfileQueryParams, void>,
  'path'
>

/**
 * Return User his own profile data.
 */
export const UsersProfile = (props: UsersProfileProps) => (
  <Get<User, unknown, UsersProfileQueryParams, void>
    path={encode`/users/profile/`}
    {...props}
  />
)

export type UseUsersProfileProps = Omit<
  UseGetProps<User, unknown, UsersProfileQueryParams, void>,
  'path'
>

/**
 * Return User his own profile data.
 */
export const useUsersProfile = (props: UseUsersProfileProps) =>
  useGet<User, unknown, UsersProfileQueryParams, void>(
    encode`/users/profile/`,
    props,
  )

export interface UsersRegistrationResponse {
  success: boolean
}

export interface UsersRegistrationRequestBody {
  email: string
  password1: string
  password2: string
}

export type UsersRegistrationProps = Omit<
  MutateProps<
    UsersRegistrationResponse,
    unknown,
    void,
    UsersRegistrationRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Account registration.
 */
export const UsersRegistration = (props: UsersRegistrationProps) => (
  <Mutate<
    UsersRegistrationResponse,
    unknown,
    void,
    UsersRegistrationRequestBody,
    void
  >
    verb="POST"
    path={encode`/users/registration/`}
    {...props}
  />
)

export type UseUsersRegistrationProps = Omit<
  UseMutateProps<
    UsersRegistrationResponse,
    unknown,
    void,
    UsersRegistrationRequestBody,
    void
  >,
  'path' | 'verb'
>

/**
 * Account registration.
 */
export const useUsersRegistration = (props: UseUsersRegistrationProps) =>
  useMutate<
    UsersRegistrationResponse,
    unknown,
    void,
    UsersRegistrationRequestBody,
    void
  >('POST', encode`/users/registration/`, props)

export interface UsersRegistrationConfirmResponse {
  success: boolean
  error?: string
}

export interface UsersRegistrationConfirmQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
  uid: string
  token: string
}

export type UsersRegistrationConfirmProps = Omit<
  GetProps<
    UsersRegistrationConfirmResponse,
    unknown,
    UsersRegistrationConfirmQueryParams,
    void
  >,
  'path'
>

/**
 * User registration confirm.
 */
export const UsersRegistrationConfirm = (
  props: UsersRegistrationConfirmProps,
) => (
  <Get<
    UsersRegistrationConfirmResponse,
    unknown,
    UsersRegistrationConfirmQueryParams,
    void
  >
    path={encode`/users/registration_confirm/`}
    {...props}
  />
)

export type UseUsersRegistrationConfirmProps = Omit<
  UseGetProps<
    UsersRegistrationConfirmResponse,
    unknown,
    UsersRegistrationConfirmQueryParams,
    void
  >,
  'path'
>

/**
 * User registration confirm.
 */
export const useUsersRegistrationConfirm = (
  props: UseUsersRegistrationConfirmProps,
) =>
  useGet<
    UsersRegistrationConfirmResponse,
    unknown,
    UsersRegistrationConfirmQueryParams,
    void
  >(encode`/users/registration_confirm/`, props)

export interface UsersSegmentMembersQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
  segment_id: number
}

export type UsersSegmentMembersProps = Omit<
  GetProps<
    User[],
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentMembersQueryParams,
    void
  >,
  'path'
>

/**
 * Receive list of users in segment
 *
 * Applies segment filter to User model and returns results list.
 */
export const UsersSegmentMembers = (props: UsersSegmentMembersProps) => (
  <Get<
    User[],
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentMembersQueryParams,
    void
  >
    path={encode`/users/segment_members/`}
    {...props}
  />
)

export type UseUsersSegmentMembersProps = Omit<
  UseGetProps<
    User[],
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentMembersQueryParams,
    void
  >,
  'path'
>

/**
 * Receive list of users in segment
 *
 * Applies segment filter to User model and returns results list.
 */
export const useUsersSegmentMembers = (props: UseUsersSegmentMembersProps) =>
  useGet<
    User[],
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentMembersQueryParams,
    void
  >(encode`/users/segment_members/`, props)

export interface UsersSegmentSizeResponse {
  size?: number
}

export interface UsersSegmentSizeQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
  segment_id: number
}

export type UsersSegmentSizeProps = Omit<
  GetProps<
    UsersSegmentSizeResponse,
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentSizeQueryParams,
    void
  >,
  'path'
>

/**
 * Receive amount of users in segment
 *
 * Applies segment filter to User model and counts amount of users.
 */
export const UsersSegmentSize = (props: UsersSegmentSizeProps) => (
  <Get<
    UsersSegmentSizeResponse,
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentSizeQueryParams,
    void
  >
    path={encode`/users/segment_size/`}
    {...props}
  />
)

export type UseUsersSegmentSizeProps = Omit<
  UseGetProps<
    UsersSegmentSizeResponse,
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentSizeQueryParams,
    void
  >,
  'path'
>

/**
 * Receive amount of users in segment
 *
 * Applies segment filter to User model and counts amount of users.
 */
export const useUsersSegmentSize = (props: UseUsersSegmentSizeProps) =>
  useGet<
    UsersSegmentSizeResponse,
    {
      errors?: {
        segment_id?: string[]
      }
    },
    UsersSegmentSizeQueryParams,
    void
  >(encode`/users/segment_size/`, props)

export interface UsersReadPathParams {
  /**
   * A unique integer value identifying this пользователь.
   */
  id: number
}

export type UsersReadProps = Omit<
  GetProps<User, unknown, void, UsersReadPathParams>,
  'path'
> &
  UsersReadPathParams

/**
 * User profile API.
 */
export const UsersRead = ({id, ...props}: UsersReadProps) => (
  <Get<User, unknown, void, UsersReadPathParams>
    path={encode`/users/${id}/`}
    {...props}
  />
)

export type UseUsersReadProps = Omit<
  UseGetProps<User, unknown, void, UsersReadPathParams>,
  'path'
> &
  UsersReadPathParams

/**
 * User profile API.
 */
export const useUsersRead = ({id, ...props}: UseUsersReadProps) =>
  useGet<User, unknown, void, UsersReadPathParams>(
    (paramsInPath: UsersReadPathParams) => encode`/users/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface UsersUpdatePathParams {
  /**
   * A unique integer value identifying this пользователь.
   */
  id: number
}

export type UsersUpdateProps = Omit<
  MutateProps<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersUpdatePathParams
  >,
  'path' | 'verb'
> &
  UsersUpdatePathParams

/**
 * User profile API.
 */
export const UsersUpdate = ({id, ...props}: UsersUpdateProps) => (
  <Mutate<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersUpdatePathParams
  >
    verb="PUT"
    path={encode`/users/${id}/`}
    {...props}
  />
)

export type UseUsersUpdateProps = Omit<
  UseMutateProps<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersUpdatePathParams
  >,
  'path' | 'verb'
> &
  UsersUpdatePathParams

/**
 * User profile API.
 */
export const useUsersUpdate = ({id, ...props}: UseUsersUpdateProps) =>
  useMutate<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersUpdatePathParams
  >(
    'PUT',
    (paramsInPath: UsersUpdatePathParams) => encode`/users/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface UsersPartialUpdatePathParams {
  /**
   * A unique integer value identifying this пользователь.
   */
  id: number
}

export type UsersPartialUpdateProps = Omit<
  MutateProps<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  UsersPartialUpdatePathParams

/**
 * User profile API.
 */
export const UsersPartialUpdate = ({id, ...props}: UsersPartialUpdateProps) => (
  <Mutate<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/users/${id}/`}
    {...props}
  />
)

export type UseUsersPartialUpdateProps = Omit<
  UseMutateProps<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  UsersPartialUpdatePathParams

/**
 * User profile API.
 */
export const useUsersPartialUpdate = ({
  id,
  ...props
}: UseUsersPartialUpdateProps) =>
  useMutate<
    UserUpdate,
    unknown,
    void,
    UserUpdateRequestBody,
    UsersPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: UsersPartialUpdatePathParams) =>
      encode`/users/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type UsersDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * User safe delete.
 */
export const UsersDelete = (props: UsersDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/users`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseUsersDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * User safe delete.
 */
export const useUsersDelete = (props: UseUsersDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/users`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })

export type YandexCountersListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: YandexCounter[]
}

export interface YandexCountersListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type YandexCountersListProps = Omit<
  GetProps<
    YandexCountersListResponse,
    unknown,
    YandexCountersListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying Yandex.Metrika counters, added to CRM.
 */
export const YandexCountersList = (props: YandexCountersListProps) => (
  <Get<YandexCountersListResponse, unknown, YandexCountersListQueryParams, void>
    path={encode`/yandex_counters/`}
    {...props}
  />
)

export type UseYandexCountersListProps = Omit<
  UseGetProps<
    YandexCountersListResponse,
    unknown,
    YandexCountersListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying Yandex.Metrika counters, added to CRM.
 */
export const useYandexCountersList = (props: UseYandexCountersListProps) =>
  useGet<
    YandexCountersListResponse,
    unknown,
    YandexCountersListQueryParams,
    void
  >(encode`/yandex_counters/`, props)

export type YandexMetricsListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: YandexMetric[]
}

export interface YandexMetricsListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type YandexMetricsListProps = Omit<
  GetProps<
    YandexMetricsListResponse,
    unknown,
    YandexMetricsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying Yandex.Metrika metrics, added to CRM.
 */
export const YandexMetricsList = (props: YandexMetricsListProps) => (
  <Get<YandexMetricsListResponse, unknown, YandexMetricsListQueryParams, void>
    path={encode`/yandex_metrics/`}
    {...props}
  />
)

export type UseYandexMetricsListProps = Omit<
  UseGetProps<
    YandexMetricsListResponse,
    unknown,
    YandexMetricsListQueryParams,
    void
  >,
  'path'
>

/**
 * API for displaying Yandex.Metrika metrics, added to CRM.
 */
export const useYandexMetricsList = (props: UseYandexMetricsListProps) =>
  useGet<
    YandexMetricsListResponse,
    unknown,
    YandexMetricsListQueryParams,
    void
  >(encode`/yandex_metrics/`, props)

export type ZonesListResponse = {
  count: number
  next?: string | null
  previous?: string | null
  results: ZoneList[]
}

export interface ZonesListQueryParams {
  /**
   * Number of results to return per page.
   */
  limit?: number
  /**
   * The initial index from which to return the results.
   */
  offset?: number
}

export type ZonesListProps = Omit<
  GetProps<ZonesListResponse, unknown, ZonesListQueryParams, void>,
  'path'
>

/**
 * API for work with museum functional zones.
 */
export const ZonesList = (props: ZonesListProps) => (
  <Get<ZonesListResponse, unknown, ZonesListQueryParams, void>
    path={encode`/zones/`}
    {...props}
  />
)

export type UseZonesListProps = Omit<
  UseGetProps<ZonesListResponse, unknown, ZonesListQueryParams, void>,
  'path'
>

/**
 * API for work with museum functional zones.
 */
export const useZonesList = (props: UseZonesListProps) =>
  useGet<ZonesListResponse, unknown, ZonesListQueryParams, void>(
    encode`/zones/`,
    props,
  )

export type ZonesCreateProps = Omit<
  MutateProps<ZoneWritable, unknown, void, ZoneWritableRequestBody, void>,
  'path' | 'verb'
>

/**
 * API for work with museum functional zones.
 */
export const ZonesCreate = (props: ZonesCreateProps) => (
  <Mutate<ZoneWritable, unknown, void, ZoneWritableRequestBody, void>
    verb="POST"
    path={encode`/zones/`}
    {...props}
  />
)

export type UseZonesCreateProps = Omit<
  UseMutateProps<ZoneWritable, unknown, void, ZoneWritableRequestBody, void>,
  'path' | 'verb'
>

/**
 * API for work with museum functional zones.
 */
export const useZonesCreate = (props: UseZonesCreateProps) =>
  useMutate<ZoneWritable, unknown, void, ZoneWritableRequestBody, void>(
    'POST',
    encode`/zones/`,
    props,
  )

export interface ZonesReadPathParams {
  /**
   * A unique integer value identifying this функциональная зона.
   */
  id: number
}

export type ZonesReadProps = Omit<
  GetProps<Zone, unknown, void, ZonesReadPathParams>,
  'path'
> &
  ZonesReadPathParams

/**
 * API for work with museum functional zones.
 */
export const ZonesRead = ({id, ...props}: ZonesReadProps) => (
  <Get<Zone, unknown, void, ZonesReadPathParams>
    path={encode`/zones/${id}/`}
    {...props}
  />
)

export type UseZonesReadProps = Omit<
  UseGetProps<Zone, unknown, void, ZonesReadPathParams>,
  'path'
> &
  ZonesReadPathParams

/**
 * API for work with museum functional zones.
 */
export const useZonesRead = ({id, ...props}: UseZonesReadProps) =>
  useGet<Zone, unknown, void, ZonesReadPathParams>(
    (paramsInPath: ZonesReadPathParams) => encode`/zones/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ZonesUpdatePathParams {
  /**
   * A unique integer value identifying this функциональная зона.
   */
  id: number
}

export type ZonesUpdateProps = Omit<
  MutateProps<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesUpdatePathParams
  >,
  'path' | 'verb'
> &
  ZonesUpdatePathParams

/**
 * API for work with museum functional zones.
 */
export const ZonesUpdate = ({id, ...props}: ZonesUpdateProps) => (
  <Mutate<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesUpdatePathParams
  >
    verb="PUT"
    path={encode`/zones/${id}/`}
    {...props}
  />
)

export type UseZonesUpdateProps = Omit<
  UseMutateProps<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesUpdatePathParams
  >,
  'path' | 'verb'
> &
  ZonesUpdatePathParams

/**
 * API for work with museum functional zones.
 */
export const useZonesUpdate = ({id, ...props}: UseZonesUpdateProps) =>
  useMutate<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesUpdatePathParams
  >(
    'PUT',
    (paramsInPath: ZonesUpdatePathParams) => encode`/zones/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export interface ZonesPartialUpdatePathParams {
  /**
   * A unique integer value identifying this функциональная зона.
   */
  id: number
}

export type ZonesPartialUpdateProps = Omit<
  MutateProps<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ZonesPartialUpdatePathParams

/**
 * API for work with museum functional zones.
 */
export const ZonesPartialUpdate = ({id, ...props}: ZonesPartialUpdateProps) => (
  <Mutate<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesPartialUpdatePathParams
  >
    verb="PATCH"
    path={encode`/zones/${id}/`}
    {...props}
  />
)

export type UseZonesPartialUpdateProps = Omit<
  UseMutateProps<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesPartialUpdatePathParams
  >,
  'path' | 'verb'
> &
  ZonesPartialUpdatePathParams

/**
 * API for work with museum functional zones.
 */
export const useZonesPartialUpdate = ({
  id,
  ...props
}: UseZonesPartialUpdateProps) =>
  useMutate<
    ZoneWritable,
    unknown,
    void,
    ZoneWritableRequestBody,
    ZonesPartialUpdatePathParams
  >(
    'PATCH',
    (paramsInPath: ZonesPartialUpdatePathParams) =>
      encode`/zones/${paramsInPath.id}/`,
    {pathParams: {id}, ...props},
  )

export type ZonesDeleteProps = Omit<
  MutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for work with museum functional zones.
 */
export const ZonesDelete = (props: ZonesDeleteProps) => (
  <Mutate<void, unknown, void, string, void>
    verb="DELETE"
    path={encode`/zones`}
    pathInlineBodyEncode={encodingFn}
    {...props}
  />
)

export type UseZonesDeleteProps = Omit<
  UseMutateProps<void, unknown, void, string, void>,
  'path' | 'verb'
>

/**
 * API for work with museum functional zones.
 */
export const useZonesDelete = (props: UseZonesDeleteProps) =>
  useMutate<void, unknown, void, string, void>('DELETE', encode`/zones`, {
    pathInlineBodyEncode: encodingFn,
    ...props,
  })
