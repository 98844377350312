import React, {useState} from 'react'
import ReactPaginate from 'react-paginate'
import {useEduAnnouncementsList} from '../../api/fetchers'
import ProfileTabs from '../../components/profileTabs/ProfileTabs'
import ProfileWrapper from '../../components/profileWrapper/ProfileWrapper'
import {ReactComponent as NavArrow} from '../../images/screen-five/nav-arrow.svg'

const MAX_ANNOUNCEMENTS_PER_PAGE = 6

const Announcements = () => {
  const [pageOffset, setPageOffset] = useState(0)
  const {data} = useEduAnnouncementsList({
    queryParams: {
      limit: MAX_ANNOUNCEMENTS_PER_PAGE,
      offset: pageOffset,
    },
  })

  const onPageChange = (page: number) => {
    setPageOffset(page * MAX_ANNOUNCEMENTS_PER_PAGE)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  if (data) {
    return (
      <ProfileWrapper>
        <ProfileTabs />
        <div className="announcements">
          {data.results.length > 0 ? (
            data.results.map((announcement) => (
              <div key={announcement.id} className="announcements__item">
                <p className="announcements__item-title">
                  {announcement.topic}
                </p>
                <p className="announcements__item-date">
                  {announcement.publish_date}
                </p>
                <div
                  className="announcements__item-content"
                  dangerouslySetInnerHTML={{
                    __html: announcement.message,
                  }}></div>
              </div>
            ))
          ) : (
            <h4 className="announcements__placeholder">Объявлений нет</h4>
          )}
          {data.count > MAX_ANNOUNCEMENTS_PER_PAGE && (
            <ReactPaginate
              pageCount={Math.ceil(data.count / MAX_ANNOUNCEMENTS_PER_PAGE)}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              onPageChange={({selected}) => onPageChange(selected)}
              previousLabel={<NavArrow />}
              nextLabel={<NavArrow />}
              containerClassName="pagination"
              pageClassName="pagination__page"
            />
          )}
        </div>
      </ProfileWrapper>
    )
  }

  return null
}

export default Announcements
