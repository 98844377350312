import React, {ReactNode, SyntheticEvent, useEffect} from 'react'
import closeIcon from '../../images/close.svg'

interface Props {
  onClose: () => void
  customClass?: string
  children: ReactNode
}

const Modal = ({onClose, customClass, children}: Props) => {
  useEffect(() => {
    document.body.className = 'modal-opened'
    return () => {
      document.body.className = ''
    }
  })

  const handleClickOutside = (e: SyntheticEvent) => {
    if (e.target === document.querySelector('.modal')) {
      onClose()
    } else return
  }

  return (
    <div
      onClick={handleClickOutside}
      className={`modal ${customClass ? customClass : ''}`}>
      <div className="modal__content">
        <button onClick={onClose} className="modal__close" type="button">
          <img src={closeIcon} alt="close" />
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal
