import {DateTime} from 'luxon'

const formatDate = (
  date: string,
  type: 'full' | 'day' | 'time',
  isLocalZone?: boolean,
) => {
  let format
  switch (type) {
    case 'full':
      format = 'dd MMMM, HH:mm'
      break
    case 'day':
      format = 'd.MM'
      break
    case 'time':
      format = 'HH:mm'
  }

  return DateTime.fromISO(date, {
    locale: 'ru',
    ...(!isLocalZone && {zone: 'Europe/Moscow'}),
  }).toFormat(format)
}

export default formatDate
